define('tedian/controllers/procurement/decline-quote', ['exports', 'ember', 'tedian/commands/procurement/decline-quote-command'], function (exports, _ember, _tedianCommandsProcurementDeclineQuoteCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    actions: {
      submit: function submit() {
        var _this = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementDeclineQuoteCommand['default']({
          quoteId: this.get('model.quote.id'),
          declinedOn: this.get('declinedOn'),
          reason: this.get('reason')
        });

        store.submit(command).then(function () {
          _this.transitionToRoute('procurement.quotes');
        });
      }
    }
  });
});