define('tedian/controllers/project-management/project/ticket/index', ['exports', 'ember', 'tedian/commands/project-management/discard-ticket-command'], function (exports, _ember, _tedianCommandsProjectManagementDiscardTicketCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    ticket: _ember['default'].computed.alias('model'),

    assignmentsRoute: _ember['default'].computed('ticketRoute', function () {
      return this.get('ticketRoute') + '.assignments';
    }),

    refineRoute: _ember['default'].computed('ticketRoute', function () {
      return this.get('ticketRoute') + '.refine';
    }),

    actions: {
      discard: function discard() {
        var _this = this;

        if (window.confirm('Ticket wirklich verwerfen?')) {
          var command = new _tedianCommandsProjectManagementDiscardTicketCommand['default']({
            ticketId: this.get('ticket.id'),
            expectedAggregateVersion: this.get('ticket.aggregateVersion')
          });

          this.get('commandStore').submit(command).then(function () {
            _this.transitionToRoute(_this.get('parentRoute'));
          });
        }
      }
    }
  });
});