define('tedian/routes/project-management/project/ticket/assignments', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    parentRoute: 'project-management.project',
    ticketRoute: 'project-management.project.ticket',

    model: function model() {
      var ticket = this.modelFor(this.get('ticketRoute')).ticket;

      return new _ember['default'].RSVP.hash({
        ticket: ticket,
        members: ticket.get('project').then(function (project) {
          return project.get('members');
        })
      });
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('ticketRoute', this.get('ticketRoute'));
      controller.set('parentRoute', this.get('parentRoute'));
    }
  });
});