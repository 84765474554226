define('tedian/models/human-resources-work-schedule', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    user: (0, _emberDataRelationships.belongsTo)('user'),
    takesEffectOn: (0, _emberDataAttr['default'])(),
    endsOn: (0, _emberDataAttr['default'])(),
    hoursByDay: (0, _emberDataAttr['default'])(),
    numberOfHolidaysPerYear: (0, _emberDataAttr['default'])(),

    hoursOverall: _ember['default'].computed('hoursByDay', function () {
      return this.get('hoursByDay').reduce(function (sum, day) {
        return sum + day;
      }, 0);
    })
  });
});