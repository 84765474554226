define('tedian/models/time-tracking-template-timer', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    name: (0, _emberDataAttr['default'])(),
    timeTrackingTags: (0, _emberDataRelationships.hasMany)('time-tracking-tag'),

    orderedTags: _ember['default'].computed('timeTrackingTags.[]', function () {
      return this.get('timeTrackingTags').toArray().sort(function (a, b) {
        var typeA = a.get('type');
        var typeB = b.get('type');
        var typesOrderedByAscendingPriority = ['attendance', 'contract-item', 'contract-handling', 'contract', 'client', 'user'];

        return typesOrderedByAscendingPriority.indexOf(typeB) - typesOrderedByAscendingPriority.indexOf(typeA);
      });
    })
  });
});