define('tedian/routes/time-tracking/interval-edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return new _ember['default'].RSVP.hash({
        interval: this.store.findRecord('time-tracking-interval', parameters.id),
        tags: this.store.query('time-tracking-tag', { filter: { 'is-locked': false } })
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('formModel', _ember['default'].Object.create({
        intervalId: model.interval.get('id'),
        comment: model.interval.get('comment'),
        selectedTags: model.interval.get('timeTrackingTags').toArray(),
        startedAt: model.interval.get('startedAt'),
        stoppedAt: model.interval.get('stoppedAt')
      }));
    }
  });
});