define('tedian/routes/invoicing/drafts/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return _ember['default'].RSVP.hash({
        draft: this.store.findRecord('invoice-draft', parameters.id, { include: 'invoice-item-drafts' }),
        organisations: this.store.query('organisation', { sort: 'name', include: 'contact-person' })
      });
    },

    actions: {
      saveInvoiceDraft: function saveInvoiceDraft() {
        var _this = this;

        var draft = this.modelFor('invoicing.drafts.edit').draft;

        draft.save().then(function () {
          return draft.get('invoiceItemDrafts');
        }).then(function (items) {
          return items.save();
        }).then(function () {
          _this.transitionTo('invoicing.drafts.index');
        });
      },

      willTransition: function willTransition() {
        var draft = this.modelFor('invoicing.drafts.edit').draft;

        draft.rollbackAttributes();
        draft.get('invoiceItemDrafts').then(function (items) {
          items.forEach(function (item) {
            if (item.get('isNew')) {
              item.deleteRecord();
            } else {
              item.rollbackAttributes();
            }
          });
        });

        return true;
      },

      issueInvoice: function issueInvoice() {
        var _this2 = this;

        if (window.confirm('Soll die Rechnung wirklich ausgestellt werden?')) {
          (function () {
            var draft = _this2.modelFor('invoicing.drafts.edit').draft;

            draft.issue().then(function (response) {
              var store = _this2.store;
              store.push(store.normalize('invoice', response.data));
              store.unloadRecord(draft);

              _this2.transitionTo('invoicing.drafts.index');
            });
          })();
        }
      }
    }
  });
});