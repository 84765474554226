define('tedian/models/invoice', ['exports', 'ember', 'ember-data', 'moment'], function (exports, _ember, _emberData, _moment) {
  exports['default'] = _emberData['default'].Model.extend({
    organisation: _emberData['default'].belongsTo('organisation'),
    invoiceItems: _emberData['default'].hasMany('invoice-item'),
    payments: _emberData['default'].hasMany('payments'),
    address: _emberData['default'].attr('string'),
    salutation: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    periodOfPerformanceStartedOn: _emberData['default'].attr('string'),
    periodOfPerformanceEndedOn: _emberData['default'].attr('string'),
    recipientVatNumber: _emberData['default'].attr('string'),
    reverseCharge: _emberData['default'].attr('boolean', { 'default': false }),
    number: _emberData['default'].attr('string'),
    billedOn: _emberData['default'].attr('string'),
    settledOn: _emberData['default'].attr('string'),
    issuer: _emberData['default'].attr('string'),
    subtotal: _emberData['default'].attr('number'),
    valueAddedTax: _emberData['default'].attr('number'),
    total: _emberData['default'].attr('number'),
    outstandingBalance: _emberData['default'].attr('number'),
    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),
    paymentSchedule: _emberData['default'].attr('payment-schedule'),

    download: function download() {
      var modelName = this.constructor.modelName;
      var adapter = this.store.adapterFor(modelName);
      return adapter.download(this.get('id'));
    },

    hasRecipientVatNumber: _ember['default'].computed('recipientVatNumber', function () {
      return !_ember['default'].isBlank(this.get('recipientVatNumber'));
    }),

    hasPeriodOfPerformance: _ember['default'].computed('periodOfPerformanceStartedOn', 'periodOfPerformanceEndedOn', function () {
      return !_ember['default'].isBlank(this.get('periodOfPerformanceStartedOn')) && !_ember['default'].isBlank(this.get('periodOfPerformanceEndedOn'));
    }),

    isOverdue: _ember['default'].computed('dueOn', function () {
      return (0, _moment['default'])(this.get('dueOn')) < (0, _moment['default'])() && _ember['default'].isBlank(this.get('settledOn'));
    }),

    hasOutstandingBalance: _ember['default'].computed('outstandingBalance', 'total', function () {
      return Math.round(this.get('outstandingBalance') * 100) / 100 !== 0.00;
    }),

    dueOn: _ember['default'].computed('outstandingBalance', 'total', 'paymentSchedule', function () {
      var paidAmount = this.get('total') - this.get('outstandingBalance');
      return this.get('paymentSchedule').nextDueDate(paidAmount);
    })
  });
});