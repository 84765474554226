define('tedian/models/procurement-item', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    quote: (0, _emberDataRelationships.belongsTo)('procurement-quote'),
    number: (0, _emberDataAttr['default'])(),
    requirements: (0, _emberDataAttr['default'])(),
    estimatedHours: (0, _emberDataAttr['default'])(),
    displayIndex: (0, _emberDataAttr['default'])(),
    isWithinScope: (0, _emberDataAttr['default'])(),
    amount: (0, _emberDataAttr['default'])(),
    state: (0, _emberDataAttr['default'])(),

    title: _ember['default'].computed('lines.[]', function () {
      return this.get('lines.firstObject');
    }),

    description: _ember['default'].computed('lines.[]', function () {
      return this.get('lines').slice(1).join('\n');
    }),

    lines: _ember['default'].computed('requirements', function () {
      return (this.get('requirements') || '').split(/\r\n|\n|\r/);
    }),

    calculateAmount: function calculateAmount(hourlyCosts, costFactor) {
      var hours = this.get('estimatedHours') || 0;
      var amount = hours * hourlyCosts * costFactor;

      this.set('amount', Math.round(amount * 100) / 100);
    },

    canBeIssued: _ember['default'].computed('state', function () {
      return this.get('state') === 'item';
    }),

    canBeAwarded: _ember['default'].computed('state', 'isWithinScope', function () {
      return this.get('isWithinScope') && this.get('state') === 'item-issued';
    }),

    canBeEntered: _ember['default'].computed('state', function () {
      return this.get('state') === 'item-awarded';
    }),

    canBeCanceled: _ember['default'].computed('state', function () {
      return this.get('state') === 'item-entered';
    }),

    isCanceled: _ember['default'].computed('state', function () {
      return this.get('state') === 'item-canceled';
    })
  });
});