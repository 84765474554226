define('tedian/routes/invoicing/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return this.store.findRecord('invoice', parameters.id, { include: 'invoice-items,payments' });
    },

    actions: {
      downloadPDF: function downloadPDF() {
        var _this = this;

        var invoice = this.modelFor('invoicing.show');

        invoice.download().then(function (response) {
          var store = _this.store;
          var timeBoundDownload = store.push(store.normalize('time-bound-download', response.data));

          window.open(timeBoundDownload.get('url'), '_blank');
        });
      }
    }
  });
});