define('tedian/services/session', ['exports', 'ember', 'npm:js-cookie'], function (exports, _ember, _npmJsCookie) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service('store'),

    sessionUrl: null,
    signInUrl: null,
    signOutUrl: null,
    authenticationToken: null,
    setupPromise: null,
    isAuthenticated: null,
    attemptedTransition: null,
    currentUser: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('setupPromise', this.setup());
    },

    setup: function setup() {
      var _this = this;

      var url = this.get('store').adapterFor('session').buildURL('session');
      this.set('sessionUrl', url);
      this.set('signInUrl', url);
      this.set('signOutUrl', url);

      this.loadAuthenticationToken();

      return new _ember['default'].RSVP.Promise(function (resolve) {
        if (_ember['default'].isBlank(_this.get('authenticationToken'))) {
          _this.set('isAuthenticated', false);
          resolve();
        } else {
          _this.loadCurrentUser().then(function () {
            _this.set('isAuthenticated', true);
            resolve();
          }, function () {
            _this.set('isAuthenticated', false);
            resolve();
          });
        }
      });
    },

    signIn: function signIn(email, password) {
      var _this2 = this;

      return _ember['default'].$.ajax({
        url: this.get('signInUrl'),
        type: 'POST',
        data: {
          email: email,
          password: password
        }
      }).then(function (response) {
        _this2.storeAuthenticationToken(response['authentication-token']);
        _this2.loadAuthenticationToken();
        _this2.loadCurrentUser();
        _this2.set('isAuthenticated', true);
      });
    },

    signOut: function signOut() {
      var _this3 = this;

      return _ember['default'].$.ajax({
        url: this.get('signOutUrl'),
        type: 'DELETE'
      }).always(function () {
        _this3.storeAuthenticationToken(null);
        _this3.loadAuthenticationToken();
        _this3.set('isAuthenticated', false);
      });
    },

    storeAuthenticationToken: function storeAuthenticationToken(token) {
      if (token) {
        _npmJsCookie['default'].set('authentication_token', token, { path: '/' });
      } else {
        _npmJsCookie['default'].remove('authentication_token');
      }
    },

    loadAuthenticationToken: function loadAuthenticationToken() {
      this.set('authenticationToken', _npmJsCookie['default'].get('authentication_token') || null);
    },

    loadCurrentUser: function loadCurrentUser() {
      var _this4 = this;

      var store = this.get('store');

      return store.adapterFor('user').me().then(function (response) {
        var record = store.push(store.normalize('user', response.data));

        _this4.set('currentUser', record);
      });
    },

    waitOnSetup: function waitOnSetup() {
      return this.get('setupPromise');
    }
  });
});