define("tedian/highcharts-configs/application", ["exports"], function (exports) {
  exports["default"] = function (defaultOptions) {
    // These configuration options have been added for the daily-timeline chart
    // Starting with Highcharts 6.0.5 those options are deprecated on 'global'
    // and can be set per chart as time.useUTC and time.timezoneOffset
    defaultOptions.global.useUTC = false;
    defaultOptions.global.timezoneOffset = new Date().getTimezoneOffset();

    return defaultOptions;
  };
});