define('tedian/controllers/time-tracking/tags/edit', ['exports', 'ember', 'tedian/commands/time-tracking/rename-tag-command'], function (exports, _ember, _tedianCommandsTimeTrackingRenameTagCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),
    formModel: undefined,

    actions: {
      save: function save(model) {
        var _this = this;

        var store = this.get('commandStore');
        var tagId = model.get('tagId');
        var name = model.get('name');
        var slug = model.get('slug');
        var command = new _tedianCommandsTimeTrackingRenameTagCommand['default']({
          tagId: tagId,
          name: name,
          slug: slug
        });

        store.submit(command).then(function () {
          _this.transitionToRoute('time-tracking.tags');
        });
      }
    }
  });
});