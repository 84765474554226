define('tedian/controllers/controlling/labor-time/index', ['exports', 'ember', 'moment', 'tedian/value-objects/date-scope', 'tedian/value-objects/target-actual-report', 'underscore'], function (exports, _ember, _moment, _tedianValueObjectsDateScope, _tedianValueObjectsTargetActualReport, _underscore) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var notesTypeLabelMap = {
    publicHoliday: 'Feiertag',
    paidLeaveDay: 'Urlaub',
    sickLeaveDay: 'Krankenstand',
    compensationDay: 'Zeitausgleich'
  };

  exports['default'] = _ember['default'].Controller.extend({
    dutyRoster: _ember['default'].inject.service('duty-roster'),
    queryParams: { year: 'year', filter: 'filter', aggregate: 'aggregate' },

    year: new Date().getFullYear(),
    filter: undefined,
    aggregate: 'month',

    scopes: _ember['default'].computed(function () {
      var scopes = [];
      var maximumYear = new Date().getFullYear();
      var minimumYear = 2017;
      var year = minimumYear;

      while (year <= maximumYear) {
        scopes.push(_tedianValueObjectsDateScope['default'].fromDate(new Date(year, 0, 1), 'year'));
        year++;
      }

      return scopes.reverse();
    }),

    targetActualReport: _ember['default'].computed('model.scheduleByAggregate', 'model.attendanceByAggregateReport', function () {
      var schedule = this.get('model.scheduleByAggregate');
      var attendanceReportItems = this.get('model.attendanceByAggregateReport.items');
      var year = parseInt(this.get('year'), 10);
      var yearMoment = (0, _moment['default'])(new Date(year, 0, 1));
      var aggregate = this.get('aggregate');
      var nameFormat = aggregate === 'month' ? 'MMMM' : '[KW] WW';
      var users = this.get('model.users');
      var publicHolidays = this.get('dutyRoster').getPublicHolidaysWithin(yearMoment.startOf('year').toDate(), yearMoment.endOf('year').toDate());

      var numberOfDataPoints = aggregate === 'month' ? 12 : (0, _moment['default'])(new Date(year, 0, 1)).isoWeeksInYear();
      var data = [].concat(_toConsumableArray(Array(numberOfDataPoints).keys())).map(function (index) {
        if (aggregate === 'month') {
          return (0, _moment['default'])(new Date(year, 0, 31)).month(index).format('YYYY-MM');
        } else {
          return (0, _moment['default'])(new Date(year, 0, 31)).isoWeek(index + 1).format('GGGG-[W]WW');
        }
      }).uniq().map(function (from) {
        var scheduleItems = schedule.filter(function (item) {
          return (0, _moment['default'])(item.get('from')).isSame(from, aggregate);
        });
        var reportItems = attendanceReportItems.filter(function (item) {
          return (0, _moment['default'])(item.get('meta.aggregationValue')).isSame(from, aggregate);
        });
        var actual = reportItems.reduce(function (previous, current) {
          return previous + current.get('value');
        }, 0);
        var target = scheduleItems.reduce(function (previous, current) {
          return previous + current.get('target');
        }, 0);
        var workingDays = _tedianValueObjectsDateScope['default'].fromDate((0, _moment['default'])(from).toDate(), aggregate).get('workingDays');
        var workingDaysDateStrings = workingDays.map(function (day) {
          return day.toUTCString();
        });
        var publicHolidaysCount = publicHolidays.filter(function (day) {
          return workingDaysDateStrings.includes((0, _moment['default'])(day.get('on')).toDate().toUTCString());
        }).reduce(function (previous, current) {
          return previous + current.get('percentage');
        }, 0);

        var notes = scheduleItems.map(function (item) {
          var username = users.findBy('id', item.get('userId')).get('name');
          var relevantDetails = item.get('details').reject(function (detail) {
            return detail.get('type') === 'publicHoliday';
          });
          var detailsByType = _underscore['default'].groupBy(relevantDetails, function (detail) {
            return detail.get('type');
          });
          var summary = Object.keys(detailsByType).map(function (type) {
            var days = detailsByType[type].reduce(function (previous, current) {
              return previous + current.get('percentage');
            }, 0);
            return days + ' Tag(e) ' + notesTypeLabelMap[type];
          });

          return username + ': ' + (summary.length > 0 ? summary.join(', ') : '–');
        });

        return {
          name: (0, _moment['default'])(from).format(nameFormat),
          actual: actual,
          target: target,
          meta: {
            date: from,
            notes: [workingDays.length - publicHolidaysCount + ' Werktage, ' + publicHolidaysCount + ' Feiertag(e)'].concat(notes)
          }
        };
      });

      return _tedianValueObjectsTargetActualReport['default'].load(data, aggregate);
    }),

    selectedFilter: _ember['default'].computed('model.users', 'filter', function () {
      return this.get('optionsFilter').findBy('value', this.get('filter'));
    }),

    optionsFilter: _ember['default'].computed('model.users', 'model.scheduleByAggregate.[]', function () {
      return [_ember['default'].Object.create({
        name: 'Alle',
        value: undefined
      })].concat(this.get('model.users').map(function (user) {
        return _ember['default'].Object.create({
          name: user.get('name'),
          value: 'user:' + user.get('id')
        });
      }));
    }),

    selectedAggregation: _ember['default'].computed('aggregate', 'optionsAggregation.[]', function () {
      return this.get('optionsAggregation').findBy('value', this.get('aggregate'));
    }),

    optionsAggregation: [_ember['default'].Object.create({
      name: 'Monat',
      value: 'month'
    }), _ember['default'].Object.create({
      name: 'Woche',
      value: 'week'
    })],

    actions: {
      selectFilter: function selectFilter(filter) {
        this.set('filter', filter.get('value'));
      },

      selectAggregation: function selectAggregation(aggregation) {
        this.set('aggregate', aggregation.get('value'));
      }
    }
  });
});