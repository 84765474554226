define('tedian/components/time-tag-picker', ['exports', 'ember', 'underscore', 'tedian/models/time-tracking-tag'], function (exports, _ember, _underscore, _tedianModelsTimeTrackingTag) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['time-tag-picker'],
    query: '',
    groupSortPriority: ['client', 'contract', 'contract-handling', 'contract-item', 'internal', 'user', 'attendance'],

    onRemove: _ember['default'].K,
    onAdd: _ember['default'].K,

    filteredTags: _ember['default'].computed('availableTags', 'query', function () {
      var queryRegex = this.buildFuzzyRegex(this.get('query'));
      var availableTags = this.get('availableTags');

      return availableTags.filter(function (tag) {
        return (tag.get('typeLabel') + ' ' + tag.get('name')).match(queryRegex) !== null;
      });
    }),

    groupedOptions: _ember['default'].computed('filteredTags.[]', function () {
      var options = [];
      var sortPriority = this.get('groupSortPriority');
      var groupedTagsByType = _underscore['default'].groupBy(this.get('filteredTags'), function (tag) {
        return tag.get('type');
      });

      for (var type in groupedTagsByType) {
        options.pushObject({
          id: type,
          name: (0, _tedianModelsTimeTrackingTag.getLabelForTagType)(type) || 'Ohne Typ',
          options: groupedTagsByType[type].sortBy('name')
        });
      }

      return options.sort(function (a, b) {
        return sortPriority.indexOf(a.id) - sortPriority.indexOf(b.id);
      });
    }),

    // Inspired by https://j11y.io/javascript/fuzzy-scoring-regex-mayhem/
    buildFuzzyRegex: function buildFuzzyRegex(input) {
      if (_ember['default'].isBlank(input)) {
        return (/^.*?$/
        );
      }

      var chars = input.replace(/\W/g, '').split('');

      return new RegExp('^.*?' + chars.join('.*?') + '.*?$', 'i');
    },

    actions: {
      remove: function remove(tag) {
        this.get('onRemove')(tag);
      },

      add: function add(tag) {
        this.get('onAdd')(tag);
        this.set('isMenuExpanded', false);
        this.$('input').trigger('focus');
      },

      inputInput: function inputInput() {
        this.set('isMenuExpanded', true);
      },

      focusInput: function focusInput() {
        this.set('isMenuExpanded', true);
        this.$('input')[0].setSelectionRange(0, 9999);
      },

      blurInput: function blurInput() {
        var _this = this;

        var $input = this.$('input');
        var $menu = this.$('input + *');

        _ember['default'].run.later(function () {
          if (_this.get('isDestroyed') || _this.get('isDestroying')) {
            return;
          }
          if ($input.is(':focus') || $menu.find('*:focus').length > 0) {
            return;
          }
          _this.set('isMenuExpanded', false);
        }, 200);
      },

      keyupInput: function keyupInput(_, event) {
        var ESCAPE_KEY = 27;

        if (event.keyCode === ESCAPE_KEY) {
          this.set('isMenuExpanded', false);
          this.set('query', '');
        }
      }
    }
  });
});