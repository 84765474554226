define('tedian/controllers/procurement/enter-contract', ['exports', 'ember', 'tedian/commands/procurement/enter-contract-command'], function (exports, _ember, _tedianCommandsProcurementEnterContractCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    actions: {
      selectOrganisation: function selectOrganisation(organisation) {
        this.set('organisation', organisation);
      },

      submit: function submit() {
        var _this = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementEnterContractCommand['default']({
          contractId: this.get('model.contract.id'),
          enteredOn: this.get('contractEnteredOn'),
          organisationId: this.get('organisation.id')
        });

        store.submit(command).then(function () {
          _this.transitionToRoute('procurement.contracts');
        });
      }
    }
  });
});