define('tedian/routes/human-resources/absences/edit', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return _ember['default'].RSVP.hash({
        paidLeave: this.store.findRecord('human-resources-paid-leave', parameters.id, {
          include: 'user'
        }),
        employees: this.store.query('human-resources-work-schedule', {
          include: 'user',
          filter: { 'in-effect-on': (0, _moment['default'])().format('YYYY-MM-DD') }
        }).then(function (schedules) {
          return schedules.map(function (schedule) {
            return schedule.get('user');
          });
        })
      }).then(function (hash) {
        var employees = hash.employees;

        if (!employees.mapBy('id').includes(hash.paidLeave.get('user.id'))) {
          employees.unshift(hash.paidLeave.get('user'));
        }

        return {
          paidLeave: hash.paidLeave,
          employees: employees
        };
      });
    },

    actions: {
      savePaidLeave: function savePaidLeave() {
        var _this = this;

        var model = this.modelFor('human-resources.absences.edit').paidLeave;

        model.save().then(function () {
          _this.transitionTo('human-resources.absences.index');
        }, function (error) {
          if (model.get('errors.length') === 0) {
            alert('Bei der Kommunikation mit der Schnittstelle trat ein unerwarteter Fehler auf:\n' + error.message.split("\n")[0]);
          }
        });
      },

      deletePaidLeave: function deletePaidLeave() {
        var _this2 = this;

        var model = this.modelFor('human-resources.absences.edit').paidLeave;

        model.destroyRecord().then(function () {
          _this2.transitionTo('human-resources.absences.index');
        }, function (error) {
          if (model.get('errors.length') === 0) {
            alert('Bei der Kommunikation mit der Schnittstelle trat ein unerwarteter Fehler auf:\n' + error.message.split("\n")[0]);
          }
        });
      },

      willTransition: function willTransition() {
        var model = this.modelFor('human-resources.absences.edit').paidLeave;

        model.rollbackAttributes();

        return true;
      }
    }
  });
});