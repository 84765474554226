define('tedian/routes/project-management/project/new-ticket', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    parentRoute: 'project-management.project',
    ticketRoute: 'project-management.project.ticket',

    model: function model() {
      return new _ember['default'].RSVP.hash({
        project: this.currentProject(),
        tickets: this.modelFor(this.get('parentRoute')).tickets,
        projects: this.store.findAll('project-management-project')
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('selectedProject', model.project);
      controller.set('parentRoute', this.get('parentRoute'));
      controller.set('ticketRoute', this.get('ticketRoute'));
    },

    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.set('selectedProject', undefined);
      controller.set('category', undefined);
      controller.set('description', undefined);
    },

    currentProject: function currentProject() {
      return this.modelFor(this.get('parentRoute')).project;
    }
  });
});