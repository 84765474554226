define('tedian/components/charts/time-target-actual-comparison', ['exports', 'ember', 'moment', 'ember-highcharts/components/high-charts'], function (exports, _ember, _moment, _emberHighchartsComponentsHighCharts) {
  exports['default'] = _emberHighchartsComponentsHighCharts['default'].extend({
    chartColors: ['#263238', '#455A64', '#607D8B', '#90A4AE', '#B0BEC5', '#CFD8DC', '#ECEFF1'],
    model: undefined,

    contentChanged: _ember['default'].observer('content.[]', function () {
      var chart = this.get('chart');
      var chartOptions = this.get('chartOptions');

      if (chart) {
        chart.update({
          xAxis: chartOptions.xAxis,
          yAxis: chartOptions.yAxis,
          tooltip: chartOptions.tooltip
        });

        chart.series.forEach(function (item) {
          switch (item.type) {
            case 'column':
              item.update(chartOptions.plotOptions.column);
              break;
            case 'line':
              item.update(chartOptions.plotOptions.line);
              break;
          }
        });
      }
    }),

    content: _ember['default'].computed('model.items.[]', function () {
      var items = this.get('model.items');
      var aggregatedBy = this.get('model.aggregatedBy');
      var offsetInHours = undefined;

      switch (aggregatedBy) {
        case 'day':
          offsetInHours = 1 / 2 * 24 + 0.5;
          break;
        case 'week':
          offsetInHours = 7 / 2 * 24 + 0.5;
          break;
        case 'month':
          offsetInHours = 30 / 2 * 24 + 0.5;
          break;
      }

      var actualSeries = {
        name: 'Ist',
        type: 'column',
        data: items.map(function (item) {
          return [_moment['default'].utc(item.get('meta.date')).valueOf(), item.get('actual')];
        })
      };

      var targetSeries = {
        name: 'Soll',
        type: 'line',
        data: items.map(function (item) {
          return [_moment['default'].utc(item.get('meta.date')).valueOf(), item.get('target')];
        })
      };
      targetSeries.data.unshiftObject([_moment['default'].utc(targetSeries.data[0][0]).subtract(offsetInHours, 'hours').valueOf(), targetSeries.data[0][1]]);
      targetSeries.data.pushObject([_moment['default'].utc(targetSeries.data[targetSeries.data.length - 1][0]).add(offsetInHours, 'hours').valueOf(), targetSeries.data[targetSeries.data.length - 1][1]]);

      return [actualSeries, targetSeries];
    }),

    chartOptions: _ember['default'].computed('model.items.[]', function () {
      var items = this.get('model.items');
      var colors = this.get('chartColors');
      var aggregatedBy = this.get('model.aggregatedBy');
      var xAxisLabelFormat = undefined,
          tooltipLabelFormat = undefined,
          yAxisMax = undefined,
          yAxisTickInterval = undefined;

      switch (aggregatedBy) {
        case 'day':
          xAxisLabelFormat = 'DD.MM.';
          tooltipLabelFormat = 'dddd, DD.MM.YYYY';
          yAxisMax = 12;
          yAxisTickInterval = 1;
          break;
        case 'week':
          xAxisLabelFormat = '[KW]WW';
          tooltipLabelFormat = '[KW]WW / GGGG';
          break;
        case 'month':
          xAxisLabelFormat = 'MMM';
          tooltipLabelFormat = 'MMMM YYYY';
          break;
      }

      return {
        chart: {
          style: {
            fontFamily: 'inherit',
            fontSize: 'inherit'
          },
          backgroundColor: 'transparent',
          spacing: [20, 0, 20, 0]
        },
        title: { text: null },
        legend: { enabled: false },
        colors: [colors[0], colors[3]],
        xAxis: {
          type: 'datetime',
          minTickInterval: _moment['default'].duration(1, aggregatedBy).asMilliseconds(),
          min: _moment['default'].utc(items[0].get('meta.date')).valueOf(),
          max: _moment['default'].utc(items[items.length - 1].get('meta.date')).valueOf(),
          labels: {
            formatter: function formatter() {
              return (0, _moment['default'])(this.value).format(xAxisLabelFormat);
            }
          }
        },
        yAxis: {
          title: { text: null },
          labels: { format: '{value}h' },
          tickInterval: yAxisTickInterval,
          min: 0,
          max: yAxisMax
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          borderWidth: 1,
          formatter: function formatter() {
            var pointLines = this.points.reverse().map(function (point) {
              var value = Math.round(point.y * Math.pow(10, 2)) / Math.pow(10, 2);
              return '<span style="color:' + point.color + '">●</span> ' + point.series.name + ': <strong>' + value.toFixed(2) + 'h</strong>';
            });

            return ['<span>' + (0, _moment['default'])(this.x).format(tooltipLabelFormat) + '</span>'].concat(pointLines).join('<br>');
          }
        },
        plotOptions: {
          series: {
            shadow: false,
            borderWidth: 0,
            states: {
              hover: { lineWidthPlus: 0 }
            }
          },
          line: {
            step: 'center',
            clip: false,
            lineWidth: 2,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 4
            }
          },
          column: {
            grouping: false,
            pointRange: _moment['default'].duration(1, aggregatedBy).asMilliseconds(),
            pointPadding: 0.04,
            groupPadding: 0.02
          }
        }
      };
    })
  });
});