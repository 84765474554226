define('tedian/routes/human-resources/absences/index', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      year: { refreshModel: true }
    },

    model: function model(parameters) {
      var year = (0, _moment['default'])([parameters.year, 0, 1]);
      var interval = [year.clone().startOf('year').format('YYYY-MM-DD'), year.clone().endOf('year').format('YYYY-MM-DD')].join('--');

      return _ember['default'].RSVP.hash({
        absences: this.store.query('human-resources-paid-leave', {
          include: 'user',
          filter: { on: interval }
        }),
        publicHolidays: this.store.query('human-resources-public-holiday', {
          filter: { on: interval }
        }),
        schedules: this.store.query('human-resources-work-schedule', {
          include: 'user',
          filter: { 'in-effect-on': interval }
        })
      });
    }
  });
});