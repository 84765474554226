define('tedian/routes/procurement/contracts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return new _ember['default'].RSVP.hash({
        awardedContracts: this.store.query('procurement-quote', {
          include: 'items',
          sort: '-number',
          filter: { state: 'contract-awarded' }
        }),
        enteredContracts: this.store.query('procurement-quote', {
          include: 'items',
          sort: '-number',
          filter: { state: 'contract-entered,contract-completed,contract-invoiced' }
        })
      });
    }
  });
});