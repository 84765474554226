define('tedian/commands/command', ['exports', 'tedian/errors/unknown-command-attribute', 'tedian/errors/missing-command-attribute', 'ember-uuid'], function (exports, _tedianErrorsUnknownCommandAttribute, _tedianErrorsMissingCommandAttribute, _emberUuid) {
  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var Command = function Command(attributes) {
    var _this = this;

    _classCallCheck(this, Command);

    var defaults = this.constructor.attributes();

    defaults['expectedAggregateVersion'] = null;

    var requiredKeys = Object.keys(defaults);
    var passedKeys = Object.keys(attributes);
    var unknownKeys = passedKeys.filter(function (key) {
      return requiredKeys.indexOf(key) === -1;
    });
    var missingKeys = requiredKeys.filter(function (key) {
      return passedKeys.indexOf(key) === -1 && defaults[key] === undefined;
    });
    var errorKey = undefined;

    if (unknownKeys.length > 0) {
      errorKey = unknownKeys.pop();
      throw new _tedianErrorsUnknownCommandAttribute['default'](errorKey, this.constructor.name);
    }

    if (missingKeys.length > 0) {
      errorKey = missingKeys.pop();
      throw new _tedianErrorsMissingCommandAttribute['default'](errorKey, this.constructor.name);
    }

    requiredKeys.forEach(function (key) {
      var value = attributes[key] || defaults[key];
      _this[key] = value;
    });

    this.commandId = (0, _emberUuid.v4)();
    this.commandName = this.constructor.name;
  };

  exports['default'] = Command;
});