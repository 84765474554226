define('tedian/helpers/render-markdown', ['exports', 'ember', 'npm:markdown-it'], function (exports, _ember, _npmMarkdownIt) {
  exports.renderMarkdown = renderMarkdown;

  function renderMarkdown(params /*, hash*/) {
    return _ember['default'].String.htmlSafe(new _npmMarkdownIt['default']({
      breaks: true,
      linkify: true
    }).render(params[0]));
  }

  exports['default'] = _ember['default'].Helper.helper(renderMarkdown);
});