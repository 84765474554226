define('tedian/routes/project-management/tickets/new-ticket', ['exports', 'tedian/routes/project-management/project/new-ticket'], function (exports, _tedianRoutesProjectManagementProjectNewTicket) {
  exports['default'] = _tedianRoutesProjectManagementProjectNewTicket['default'].extend({
    parentRoute: 'project-management.tickets',
    ticketRoute: 'project-management.tickets.ticket',
    templateName: 'project-management/project/new-ticket',

    currentProject: function currentProject() {
      return null;
    }
  });
});