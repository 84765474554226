define('tedian/routes/procurement/invoice-contract', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return new _ember['default'].RSVP.hash({
        quote: this.store.findRecord('procurement-quote', parameters.id, { include: 'items' })
      });
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('contractInvoicedOn', (0, _moment['default'])().format('YYYY-MM-DD'));
    }
  });
});