define('tedian/routes/procurement/award-contract', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return new _ember['default'].RSVP.hash({
        quote: this.store.findRecord('procurement-quote', parameters.id, { include: 'items' })
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('contractAwardedOn', (0, _moment['default'])().format('YYYY-MM-DD'));
      controller.set('items', model.quote.get('items').map(function (item) {
        return _ember['default'].ObjectProxy.create({
          content: item,
          isAwarded: item.get('isWithinScope')
        });
      }));
    }
  });
});