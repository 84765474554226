define('tedian/controllers/human-resources/absences/index', ['exports', 'ember', 'tedian/value-objects/date-scope', 'underscore', 'tedian/models/human-resources-paid-leave'], function (exports, _ember, _tedianValueObjectsDateScope, _underscore, _tedianModelsHumanResourcesPaidLeave) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: { year: 'year', filter: 'filter', aggregate: 'aggregate' },

    year: new Date().getFullYear(),
    filter: undefined,
    aggregate: 'employee',

    isAggregatedByLeaveType: _ember['default'].computed.equal('aggregate', 'leave-type'),
    isAggregatedByEmployee: _ember['default'].computed.equal('aggregate', 'employee'),

    sorting: ['on:desc'],

    sortedPublicHolidays: _ember['default'].computed.sort('model.publicHolidays', 'sorting'),
    sortedAbsenceDays: _ember['default'].computed.sort('filteredAbsenceDays', 'sorting'),

    filteredAbsenceDays: _ember['default'].computed('model.absences.[]', 'filter', function () {
      var filter = this.get('filter');

      if (filter === undefined) {
        return this.get('model.absences');
      } else {
        return this.get('model.absences').filter(function (day) {
          return filter === 'user:' + day.get('user.id');
        });
      }
    }),

    absencesByEmployee: _ember['default'].computed('sortedAbsenceDays.[]', 'relevantEmployees.[]', function () {
      var absencesByUserId = _underscore['default'].groupBy(this.get('sortedAbsenceDays'), function (day) {
        return day.get('user.id');
      });
      var filter = this.get('filter');

      return this.get('relevantEmployees').filter(function (employee) {
        return filter === undefined || filter === 'user:' + employee.get('id');
      }).map(function (employee) {
        return _ember['default'].Object.create({
          id: employee.get('id'),
          name: employee.get('name'),
          absences: absencesByUserId[employee.get('id')]
        });
      }).sortBy('name');
    }),

    absencesByLeaveType: _ember['default'].computed('sortedAbsenceDays.[]', function () {
      var absencesByLeaveType = _underscore['default'].groupBy(this.get('sortedAbsenceDays'), function (day) {
        return day.get('leaveType');
      });

      return _tedianModelsHumanResourcesPaidLeave.leaveTypes.map(function (leaveType) {
        return _ember['default'].Object.create({
          id: leaveType.get('id'),
          label: leaveType.get('label'),
          absences: absencesByLeaveType[leaveType.get('id')]
        });
      });
    }),

    scopes: _ember['default'].computed(function () {
      var scopes = [];
      var maximumYear = new Date().getFullYear();
      var minimumYear = 2017;
      var year = minimumYear;

      while (year <= maximumYear) {
        scopes.push(_tedianValueObjectsDateScope['default'].fromDate(new Date(year, 0, 1), 'year'));
        year++;
      }

      return scopes.reverse();
    }),

    relevantEmployees: _ember['default'].computed('model.schedules.[]', function () {
      return this.get('model.schedules').reduce(function (collection, schedule) {
        var isEmployeeMissing = collection.find(function (employee) {
          return employee.get('id') === schedule.get('user.id');
        }) === undefined;

        if (isEmployeeMissing) {
          collection.push(schedule.get('user'));
        }

        return collection;
      }, []);
    }),

    selectedFilter: _ember['default'].computed('optionsFilter.[]', 'filter', function () {
      return this.get('optionsFilter').findBy('value', this.get('filter'));
    }),

    optionsFilter: _ember['default'].computed('relevantEmployees.[]', 'year', function () {
      return [_ember['default'].Object.create({
        name: 'Alle',
        value: undefined
      })].concat(this.get('relevantEmployees').map(function (employee) {
        return _ember['default'].Object.create({
          name: employee.get('name'),
          value: 'user:' + employee.get('id')
        });
      }));
    }),

    selectedAggregation: _ember['default'].computed('aggregate', 'optionsAggregation.[]', function () {
      return this.get('optionsAggregation').findBy('value', this.get('aggregate'));
    }),

    optionsAggregation: [_ember['default'].Object.create({
      name: 'Mitarbeiter/in',
      value: 'employee'
    }), _ember['default'].Object.create({
      name: 'Art der Abwesenheit',
      value: 'leave-type'
    })],

    actions: {
      selectFilter: function selectFilter(filter) {
        this.set('filter', filter.get('value'));
      },

      selectAggregation: function selectAggregation(aggregation) {
        this.set('aggregate', aggregation.get('value'));
      }
    }
  });
});