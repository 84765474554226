define('tedian/value-objects/simple-report', ['exports', 'ember', 'tedian/value-objects/report'], function (exports, _ember, _tedianValueObjectsReport) {

  var SimpleReport = _tedianValueObjectsReport['default'].extend({
    sortedItems: _ember['default'].computed('items.@each.name', 'items.@each.value', 'sortOrder', 'sortProperty', function () {
      var sortProperty = this.get('sortProperty');
      var sortOrder = this.get('sortOrder');
      var items = this.get('items');

      if (sortProperty) {
        items = this.get('items').sortBy(sortProperty);
      }

      if (sortOrder === 'DESC') {
        return items.reverse();
      } else {
        return items;
      }
    }),

    itemsValueChanged: _ember['default'].observer('items.@each.value', function () {
      this.updateItemPercentages();
    }),

    summary: _ember['default'].computed('items.[]', function () {
      var items = this.get('items');
      var valueSum = items.reduce(function (sum, current) {
        return sum + current.get('value');
      }, 0);

      return _ember['default'].Object.create({
        value: valueSum,
        percentage: 1
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.updateItemPercentages();
    },

    updateItemPercentages: function updateItemPercentages() {
      var items = this.get('items');
      var valueSum = items.reduce(function (sum, current) {
        return sum + current.get('value');
      }, 0);

      items.forEach(function (item) {
        item.set('percentage', item.get('value') / valueSum);
      });
    }
  });

  SimpleReport.reopenClass({
    fromRows: function fromRows(rows) {
      return this.create({
        sortProperty: 'name',
        sortOrder: 'ASC',
        items: rows.map(function (row) {
          return _ember['default'].Object.create({
            name: row.tag.get('name'),
            value: row.seconds / 3600,
            percentage: 0,
            meta: {
              aggregationValue: row.aggregation_value,
              aggregationLevel: row.aggregation_level
            }
          });
        })
      });
    }
  });

  exports['default'] = SimpleReport;
});