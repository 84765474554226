define('tedian/components/charts/cumulative-revenue-goal', ['exports', 'ember', 'underscore', 'moment', 'tedian/helpers/format-number'], function (exports, _ember, _underscore, _moment, _tedianHelpersFormatNumber) {
  exports['default'] = _ember['default'].Component.extend({
    isLoading: undefined,
    payments: undefined,
    year: undefined,
    revenueGoal: undefined,

    store: _ember['default'].inject.service('store'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('isLoading', true);

      this.get('store').query('payment', {
        include: 'invoice',
        sort: '-received-on,-id',
        filter: { 'received-on': this.get('year') + '-01-01--' + this.get('year') + '-12-31' }
      }).then(function (payments) {
        _this.setProperties({
          payments: payments,
          isLoading: false
        });
      });
    },

    chartOptions: _ember['default'].computed('year', function () {
      var year = this.get('year');
      var plotLines = [];

      if (year === (0, _moment['default'])().year()) {
        plotLines.push({
          label: {
            text: 'Heute',
            style: {
              color: '#AAAAAA'
            }
          },
          value: (0, _moment['default'])().valueOf(),
          width: 2,
          color: '#AAAAAA',
          zIndex: 1
        });
      }

      return {
        chart: {
          style: {
            fontFamily: 'inherit',
            fontSize: 'inherit'
          },
          backgroundColor: 'transparent',
          spacing: [20, 0, 20, 0]
        },
        legend: {
          enabled: false
        },
        title: false,
        xAxis: {
          title: {
            enabled: false
          },
          plotLines: plotLines,
          type: 'datetime',
          tickPositions: [(0, _moment['default'])([year, 0, 1]).valueOf(), (0, _moment['default'])([year, 1, 1]).valueOf(), (0, _moment['default'])([year, 2, 1]).valueOf(), (0, _moment['default'])([year, 3, 1]).valueOf(), (0, _moment['default'])([year, 4, 1]).valueOf(), (0, _moment['default'])([year, 5, 1]).valueOf(), (0, _moment['default'])([year, 6, 1]).valueOf(), (0, _moment['default'])([year, 7, 1]).valueOf(), (0, _moment['default'])([year, 8, 1]).valueOf(), (0, _moment['default'])([year, 9, 1]).valueOf(), (0, _moment['default'])([year, 10, 1]).valueOf(), (0, _moment['default'])([year, 11, 1]).valueOf(), (0, _moment['default'])([year, 11, 31]).valueOf()],
          labels: {
            formatter: function formatter() {
              return (0, _moment['default'])(this.value).format('DD.MM.');
            }
          }
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function formatter() {
              return '€ ' + this.axis.defaultLabelFormatter.call(this);
            }
          }
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          borderWidth: 1,
          formatter: function formatter() {
            var pointLines = this.points.map(function (point) {
              return '<span style="color:' + point.color + '">●</span> ' + point.series.name + ': <strong>' + (0, _tedianHelpersFormatNumber.formatNumber)([point.y], { precision: 2 }) + ' €</strong>';
            });

            return ['<span>' + (0, _moment['default'])(this.x).format('DD. MMMM YYYY') + '</span>'].concat(pointLines).join('<br>');
          }
        },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: false
              }
            },
            marker: {
              enabled: false
            }
          }
        }
      };
    }),

    chartData: _ember['default'].computed('year', 'revenueGoal', 'payments.[]', function () {
      var revenueGoal = this.get('revenueGoal');
      var year = this.get('year');

      var payments = this.get('payments').map(function (payment) {
        var amount = payment.get('amount') / 1.2;

        if (payment.get('invoice.reverseCharge')) {
          amount = payment.get('amount');
        }

        return {
          date: payment.get('receivedOn'),
          amount: amount
        };
      });

      var paymentsInYear = _underscore['default'].filter(payments, function (payment) {
        return (0, _moment['default'])(payment.date).year() === year;
      });
      var groupedByDate = _underscore['default'].groupBy(paymentsInYear, function (payment) {
        return payment.date;
      });
      var pairs = _underscore['default'].pairs(groupedByDate);
      var sortedPairs = _underscore['default'].sortBy(pairs, function (pair) {
        return (0, _moment['default'])(pair[0]).unix();
      });

      var summedByDate = _underscore['default'].map(sortedPairs, function (pair) {
        var amounts = _underscore['default'].map(pair[1], function (payment) {
          return payment.amount;
        });

        return {
          date: pair[0],
          amount: _underscore['default'].reduce(amounts, function (sum, amount) {
            return sum + amount;
          }, 0)
        };
      });

      var sum = 0;
      var cumulativeDates = _underscore['default'].map(summedByDate, function (item) {
        sum += item.amount;

        return {
          date: item.date,
          amount: sum
        };
      });

      var firstDayOfYear = (0, _moment['default'])([year, 0, 1]);
      var lastDayOfYear = (0, _moment['default'])([year, 11, 31]);

      var lineSeriesData = this._buildTargetRevenueData(cumulativeDates, firstDayOfYear, lastDayOfYear, revenueGoal);
      var areaSeriesData = this._buildActualRevenueData(cumulativeDates, firstDayOfYear, lastDayOfYear);

      return [{
        type: 'line',
        name: 'Soll',
        color: '#3D9970',
        data: lineSeriesData
      }, {
        type: 'area',
        name: 'Ist',
        color: 'rgba(61, 153, 112, 0.4)',
        fillColor: 'rgba(61, 153, 112, 0.3)',
        lineWidth: 0,
        step: 'center',
        data: areaSeriesData
      }];
    }),

    _buildTargetRevenueData: function _buildTargetRevenueData(cumulativeDates, firstDayOfYear, lastDayOfYear, revenueGoal) {
      var lineSeriesDataForDaysWithRevenue = _underscore['default'].map(cumulativeDates, function (item) {
        var date = (0, _moment['default'])(item.date).startOf('day');
        var dayOfYear = date.dayOfYear();

        return {
          x: date.valueOf(),
          y: dayOfYear / 365 * revenueGoal
        };
      });

      var lineSeriesDataForFirstDaysOfMonth = _underscore['default'].map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], function (month) {
        var firstDayOfMonth = firstDayOfYear.clone().add(month, 'months').startOf('month');
        var dayOfYear = firstDayOfMonth.dayOfYear();

        return {
          x: firstDayOfMonth.valueOf(),
          y: dayOfYear / 365 * revenueGoal
        };
      });

      var lineSeriesDataForLastDayOfYear = [{
        x: lastDayOfYear.valueOf(),
        y: revenueGoal
      }];

      return _underscore['default'].uniq([].concat(lineSeriesDataForDaysWithRevenue).concat(lineSeriesDataForFirstDaysOfMonth).concat(lineSeriesDataForLastDayOfYear)).sort(function (a, b) {
        return (0, _moment['default'])(a.x).isAfter(b.x, 'day') ? 1 : -1;
      });
    },

    _buildActualRevenueData: function _buildActualRevenueData(cumulativeDates, firstDayOfYear, lastDayOfYear) {
      var areaSeriesDataForDaysWithRevenue = _underscore['default'].map(cumulativeDates, function (item) {
        return {
          x: (0, _moment['default'])(item.date).valueOf(),
          y: item.amount
        };
      });

      var areaSeriesDataForFirstDaysOfMonth = _underscore['default'].map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], function (month) {
        var firstDayOfMonth = firstDayOfYear.clone().add(month, 'months').startOf('month');
        var matchingItem = _underscore['default'].find(cumulativeDates, function (item) {
          return (0, _moment['default'])(item.date).isSame(firstDayOfMonth, 'day');
        }) || _underscore['default'].find(cumulativeDates.sort(function (a, b) {
          return (0, _moment['default'])(a.date).isBefore(b.date, 'day') ? 1 : -1;
        }), function (item) {
          return (0, _moment['default'])(item.date).isBefore(firstDayOfMonth, 'day');
        });

        return {
          x: firstDayOfMonth.valueOf(),
          y: matchingItem ? matchingItem.amount : 0
        };
      });

      var areaSeriesDataForLastDayOfYear = [{
        x: lastDayOfYear.valueOf(),
        y: (areaSeriesDataForDaysWithRevenue.slice(-1)[0] || { x: 0, y: 0 }).y
      }];

      return _underscore['default'].uniq([].concat(areaSeriesDataForDaysWithRevenue).concat(areaSeriesDataForFirstDaysOfMonth).concat(areaSeriesDataForLastDayOfYear)).sort(function (a, b) {
        return (0, _moment['default'])(a.x).isAfter(b.x, 'day') ? 1 : -1;
      });
    }
  });
});