define('tedian/routes/protected', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      // redirect to sign in if not authenticated
      if (!this.get('session.isAuthenticated')) {
        this.get('session').set('attemptedTransition', transition);
        this.transitionTo('session.new');
      }
    }
  });
});