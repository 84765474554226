define('tedian/helpers/pad-with-zeros', ['exports', 'ember'], function (exports, _ember) {
  exports.padWithZeros = padWithZeros;

  function padWithZeros(params /*, hash*/) {
    var string = params[0];

    return ('00' + string).slice(-3);
  }

  exports['default'] = _ember['default'].Helper.helper(padWithZeros);
});