define('tedian/helpers/simple-format', ['exports', 'ember'], function (exports, _ember) {
  exports.simpleFormat = simpleFormat;

  function simpleFormat(string /*, hash*/) {
    string = string.replace(/\r\n?/g, '\n');
    string = _ember['default'].$.trim(string);
    string = string.replace(/\n/g, '<br>');

    return new _ember['default'].String.htmlSafe(string);
  }

  exports['default'] = _ember['default'].Helper.helper(simpleFormat);
});