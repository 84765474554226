define('tedian/controllers/clients/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    invoices: _ember['default'].A(),

    organisations: _ember['default'].computed('model.[]', 'invoices.[]', function () {
      var invoices = this.get('invoices');

      var reduceBy = function reduceBy(collection, property, initialValue) {
        return collection.reduce(function (sum, current) {
          return sum + current.get(property);
        }, initialValue);
      };

      return this.get('model').map(function (organisation) {
        var netSales = undefined,
            grossSales = undefined,
            lastInvoiceBilledOn = undefined;

        var relatedInvoices = invoices.filter(function (invoice) {
          return invoice.get('organisation.id') === organisation.get('id');
        });

        if (relatedInvoices.get('length') === 0) {
          netSales = grossSales = lastInvoiceBilledOn = null;
        } else {
          lastInvoiceBilledOn = relatedInvoices.sortBy('billedOn').get('lastObject.billedOn');
          netSales = reduceBy(relatedInvoices, 'subtotal', 0);
          grossSales = reduceBy(relatedInvoices, 'total', 0);
        }

        return _ember['default'].ObjectProxy.create({
          content: organisation,
          lastInvoiceBilledOn: lastInvoiceBilledOn,
          grossSales: grossSales,
          netSales: netSales
        });
      }).sortBy('name');
    })
  });
});