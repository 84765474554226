define('tedian/routes/procurement/inquiries', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.query('procurement-quote', {
        include: 'items',
        sort: '-inquiry-received-on',
        filter: { state: 'inquiry' }
      });
    }
  });
});