define('tedian/controllers/project-management/project/ticket/refine', ['exports', 'ember', 'tedian/commands/project-management/refine-ticket-command'], function (exports, _ember, _tedianCommandsProjectManagementRefineTicketCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    ticket: _ember['default'].computed.alias('model'),
    description: undefined,

    actions: {
      submit: function submit() {
        var _this = this;

        var command = new _tedianCommandsProjectManagementRefineTicketCommand['default']({
          ticketId: this.get('ticket.id'),
          expectedAggregateVersion: this.get('ticket.aggregateVersion'),
          description: this.get('description')
        });

        this.get('commandStore').submit(command).then(function () {
          _this.transitionToRoute(_this.get('ticketRoute'));
        });
      }
    }
  });
});