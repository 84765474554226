define('tedian/components/app-layout-sidebar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),
    store: _ember['default'].inject.service('store'),

    tags: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('store').query('time-tracking-tag', { filter: { 'is-locked': false } }).then(function (tags) {
        _this.set('tags', tags);
      });
    },

    attendanceTags: _ember['default'].computed('tags.[]', function () {
      return (this.get('tags') || []).filter(function (tag) {
        return tag.isOfType('attendance');
      });
    }),

    activityTags: _ember['default'].computed('tags.[]', function () {
      return (this.get('tags') || []).filter(function (tag) {
        return !tag.isOfType('attendance');
      });
    })
  });
});