define('tedian/models/project-management-project', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    aggregateVersion: (0, _emberDataAttr['default'])(),
    name: (0, _emberDataAttr['default'])(),
    shortName: (0, _emberDataAttr['default'])(),
    startedAt: (0, _emberDataAttr['default'])(),
    unplannedTicketCount: (0, _emberDataAttr['default'])(),
    plannedTicketCount: (0, _emberDataAttr['default'])(),
    startedTicketCount: (0, _emberDataAttr['default'])(),
    finishedTicketCount: (0, _emberDataAttr['default'])(),

    members: (0, _emberDataRelationships.hasMany)('project-management-member')
  });
});