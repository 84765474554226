define('tedian/value-objects/target-actual-report', ['exports', 'ember', 'tedian/value-objects/report'], function (exports, _ember, _tedianValueObjectsReport) {

  var TargetActualReportItem = _ember['default'].Object.extend({
    name: undefined,
    target: undefined,
    actual: undefined,
    meta: undefined,

    difference: _ember['default'].computed('target', 'actual', function () {
      return this.get('actual') - this.get('target');
    }),

    isTargetReached: _ember['default'].computed('difference', function () {
      return this.get('difference') >= 0;
    })
  });

  var TargetActualReport = _tedianValueObjectsReport['default'].extend({
    aggregatedBy: 'day',
    sortProperty: undefined,
    sortOrder: undefined,

    summary: _ember['default'].computed('items.[]', function () {
      var items = this.get('items');
      var actualSum = items.reduce(function (sum, current) {
        return sum + current.get('actual');
      }, 0);
      var targetSum = items.reduce(function (sum, current) {
        return sum + current.get('target');
      }, 0);

      return TargetActualReportItem.create({
        actual: actualSum,
        target: targetSum
      });
    })
  });

  TargetActualReport.reopenClass({
    load: function load(items, aggregatedBy, sortProperty, sortOrder) {
      return this.create({
        aggregatedBy: aggregatedBy || 'day',
        sortProperty: sortProperty,
        sortOrder: sortOrder,
        items: items.map(function (data) {
          return TargetActualReportItem.create(data);
        })
      });
    }
  });

  exports['default'] = TargetActualReport;
});