define('tedian/controllers/procurement/edit-inquiry', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      selectOrganisation: function selectOrganisation(organisation) {
        this.set('model.quote.organisation', organisation);
      },

      submit: function submit() {
        var _this = this;

        this.get('model.quote').save().then(function () {
          _this.transitionToRoute('procurement.inquiries');
        });
      }
    }
  });
});