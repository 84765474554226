define('tedian/components/compact-timer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),
    timeTracking: _ember['default'].inject.service('time-tracking'),
    metronome: _ember['default'].inject.service('metronome'),
    classNames: ['compact-timer'],

    tags: null,
    onRemove: _ember['default'].K,
    onAdd: _ember['default'].K,

    selectedTag: null,
    isCommentable: false,
    usesExclusiveTags: false,
    defaultTagSlug: null,

    isTicking: _ember['default'].computed.notEmpty('timer'),

    isTooltipUsable: _ember['default'].computed('isTicking', function () {
      return this.get('isTicking');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('metronome');
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!_ember['default'].isEmpty(this.get('tags')) && this.get('usesExclusiveTags')) {
        this.set('selectedTag', this.get('tags').find(function (tag) {
          return tag.get('slug') === _this.get('defaultTagSlug');
        }));
      }
    },

    timer: _ember['default'].computed('tagTypes.[]', 'timeTracking.timers.[]', function () {
      var timers = this.get('timeTracking.timers');
      var tagTypes = this.get('tagTypes');
      var findByTagTypes = undefined;

      if (_ember['default'].isBlank(timers)) {
        return null;
      }

      if (this.get('usesExclusiveTags')) {
        findByTagTypes = function (timerTags, tagTypes) {
          // Every timer has a tag of type 'user' - that's why 'user' tags can be allowed here.
          // On the other hand, we don't want to match a timer with a 'user' tag only.
          var matchesTagTypes = timerTags.every(function (tag) {
            return tagTypes.includes(tag.get('type')) || tag.isOfType('user');
          });
          var matchesAnyNonUserTagTypes = timerTags.any(function (tag) {
            return !tag.isOfType('user');
          });

          return matchesTagTypes && matchesAnyNonUserTagTypes;
        };
      } else {
        findByTagTypes = function (timerTags, tagTypes) {
          return timerTags.every(function (tag) {
            return tagTypes.includes(tag.get('type')) || tag.isOfType('user');
          });
        };
      }

      return timers.find(function (timer) {
        return findByTagTypes(timer.get('timeTrackingTags'), tagTypes);
      });
    }),

    milliseconds: _ember['default'].computed('timer', 'metronome.beat', function () {
      var timer = this.get('timer');

      if (_ember['default'].isBlank(timer)) {
        return 0;
      }

      return new Date().getTime() - timer.get('startedAt').getTime();
    }),

    pickableTags: _ember['default'].computed.alias('tags'),

    tagTypes: _ember['default'].computed('tags.[]', function () {
      var tags = this.get('tags');

      if (_ember['default'].isBlank(tags)) {
        return [];
      }

      return tags.reduce(function (types, tag) {
        if (!types.includes(tag.get('type'))) {
          types.push(tag.get('type'));
        }

        return types;
      }, []);
    }),

    timerChanged: _ember['default'].observer('timer', 'usesExclusiveTags', 'tagTypes.[]', function () {
      var timer = this.get('timer');
      var tagTypes = this.get('tagTypes');

      // When a timer with exclusive tag usage gets started outside the compact-timer
      // component the `selectedTag` attribute has the wrong value.
      // Therefore whenever a timer with exclusive tag usage gets started we set the
      // selected tag explicitly.
      if (timer && this.get('usesExclusiveTags')) {
        this.set('selectedTag', timer.get('timeTrackingTags').find(function (tag) {
          return tagTypes.includes(tag.get('type'));
        }));
      }
    }),

    actions: {
      start: function start() {
        var tags = [this.get('timeTracking.currentUserTag')];

        if (this.get('usesExclusiveTags')) {
          tags.pushObject(this.get('selectedTag'));
        }

        this.get('timeTracking').startTimer(tags);
      },

      stop: function stop() {
        this.get('timeTracking').stopTimer(this.get('timer'));
      },

      updateComment: function updateComment() {
        this.get('timeTracking').updateComment(this.get('timer'));
      },

      removeTag: function removeTag(tag) {
        this.get('timeTracking').removeTag(this.get('timer'), tag);
      },

      addTag: function addTag(tag) {
        this.get('timeTracking').addTag(this.get('timer'), tag);
      },

      updateSelectedTag: function updateSelectedTag(newTag) {
        if (!this.get('timer')) {
          return;
        }

        this.get('timeTracking').replaceTags(this.get('timer'), [this.get('timeTracking.currentUserTag'), newTag]);
      }
    }
  });
});