define('tedian/components/charts/daily-timeline', ['exports', 'ember', 'moment', 'ember-highcharts/components/high-charts'], function (exports, _ember, _moment, _emberHighchartsComponentsHighCharts) {
  exports['default'] = _emberHighchartsComponentsHighCharts['default'].extend({
    chartColors: ['#263238', '#455A64', '#607D8B', '#90A4AE', '#B0BEC5', '#CFD8DC', '#ECEFF1'],
    model: undefined,

    chartOptions: _ember['default'].computed('chartColors.[]', function () {
      var chartColors = this.get('chartColors');

      return {
        chart: {
          type: 'columnrange',
          inverted: true,
          spacing: [1, 1, 1, 1],
          style: { fontFamily: 'inherit', fontSize: 'inherit' },
          backgroundColor: 'transparent',
          height: '100px'
        },
        legend: { enabled: false },
        title: { text: null },
        colors: chartColors,
        xAxis: {
          visible: false
        },
        yAxis: {
          type: 'datetime',
          title: { text: null }
        },
        plotOptions: {
          columnrange: {
            grouping: false,
            pointPadding: 0.025,
            groupPadding: 0,
            borderWidth: 0,
            pointWidth: 35,
            pointRange: 1
          }
        },
        tooltip: {
          borderWidth: 1,
          formatter: function formatter() {
            var startsAt = (0, _moment['default'])(this.point.low);
            var endsAt = (0, _moment['default'])(this.point.high);
            var duration = _moment['default'].duration(endsAt.diff(startsAt));
            var formattedTimeFrame = startsAt.format('HH:mm') + ' – ' + endsAt.format('HH:mm') + ' Uhr';
            var formattedDuration = duration.hours() + 'h ' + duration.minutes() + 'min';
            var lines = undefined;

            if (this.x === 0) {
              lines = [this.series.name, '<strong>' + this.point.name + '<strong>', formattedTimeFrame + ' (' + formattedDuration + ')'];
            } else {
              lines = [this.series.name, formattedTimeFrame + ' (' + formattedDuration + ')'];
            }

            return lines.join('<br>');
          }
        }
      };
    }),

    content: _ember['default'].computed('model', 'chartColors.[]', function () {
      var chartColors = this.get('chartColors');
      var activityIntervals = this.get('model.activityIntervals');
      var attendanceIntervals = this.get('model.attendanceIntervals');

      function buildDataPoint(interval, index) {
        return {
          low: interval.get('startedAt').getTime(),
          high: interval.get('stoppedAt').getTime(),
          x: index,
          color: chartColors[index]
        };
      }

      var activityTimeSeries = {
        name: 'Leistung',
        data: activityIntervals.map(function (interval) {
          return _ember['default'].assign(buildDataPoint(interval, 0), {
            name: interval.get('timeTrackingTags').filter(function (tag) {
              return !tag.isOfType('user');
            }).map(function (tag) {
              return '[' + tag.get('name') + ']';
            }).join(' ')
          });
        })
      };

      var attendanceTimeSeries = {
        name: 'Anwesenheit',
        data: attendanceIntervals.map(function (interval) {
          return buildDataPoint(interval, 1);
        })
      };

      var series = [].concat(activityTimeSeries).concat(attendanceTimeSeries).filter(function (item) {
        return item.data.length > 0;
      });

      return series;
    })
  });
});