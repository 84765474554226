define('tedian/components/charts/cumulative-schedule', ['exports', 'ember', 'underscore', 'moment'], function (exports, _ember, _underscore, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    schedule: undefined,

    chartOptions: _ember['default'].computed('schedule.[]', function () {
      return {
        chart: {
          type: 'column',
          spacing: [20, 0, 20, 0]
        },
        legend: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          title: {
            enabled: false
          },
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%d.%m.%Y',
            week: '%d.%m.%Y',
            month: '%d.%m.%Y',
            year: '%Y'
          },
          minTickInterval: _moment['default'].duration(1, 'weeks').asMilliseconds()
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: 'Wahrscheinlichkeit'
          },
          labels: {
            formatter: function formatter() {
              return this.value + '%';
            }
          }
        },
        tooltip: {
          borderWidth: 1,
          formatter: function formatter() {
            return 'Fertigstellung bis <b>' + (0, _moment['default'])(this.x).format('DD.MM.YYYY') + '</b> oder früher<br> mit einer Wahrscheinlichkeit von <b>' + this.y.toFixed(0) + '%</b>';
          }
        }
      };
    }),

    chartData: _ember['default'].computed('schedule.[]', function () {
      var data = _underscore['default'].map(this.get('schedule') || [], function (item) {
        return {
          y: item.percentage,
          x: (0, _moment['default'])(item.date, 'YYYY-MM-DD').toDate().getTime() - 1,
          color: item.target ? '#3d9970' : '#b1d6c5'
        };
      });

      return [{
        data: data
      }];
    })
  });
});