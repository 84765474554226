define('tedian/components/ticket-category-label', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':ticket-category-label', 'typeClass'],

    typeClass: _ember['default'].computed('category', function () {
      return 'ticket-category-label--' + this.get('category');
    }),

    category: undefined,

    label: _ember['default'].computed('category', function () {
      switch (this.get('category')) {
        case 'default':
          return 'Standard';
        case 'feature':
          return 'Funktion';
        case 'defect':
          return 'Defekt';
        case 'internal':
          return 'Intern';
      }
    })
  });
});