define('tedian/routes/procurement/close-inquiry', ['exports', 'ember', 'moment', 'tedian/commands/procurement/close-inquiry-command', 'tedian/commands/procurement/convert-inquiry-command'], function (exports, _ember, _moment, _tedianCommandsProcurementCloseInquiryCommand, _tedianCommandsProcurementConvertInquiryCommand) {
  exports['default'] = _ember['default'].Route.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    model: function model(parameters) {
      return this.store.findRecord('procurement-quote', parameters.id);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('date', (0, _moment['default'])().format('YYYY-MM-DD'));
    },

    actions: {
      close: function close(id) {
        var _this = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementCloseInquiryCommand['default']({
          inquiryId: id,
          closedOn: this.controllerFor('procurement.close-inquiry').get('date')
        });

        store.submit(command).then(function () {
          _this.transitionTo('procurement.inquiries');
        });
      },

      convert: function convert(id) {
        var _this2 = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementConvertInquiryCommand['default']({
          inquiryId: id,
          convertedOn: this.controllerFor('procurement.close-inquiry').get('date')
        });

        store.submit(command).then(function () {
          _this2.transitionTo('procurement.quotes');
        });
      }
    }
  });
});