define('tedian/risk-management/risk-management-data-repository', ['exports', 'underscore', 'tedian/risk-management/project-management-observations', 'tedian/risk-management/task-observations'], function (exports, _underscore, _tedianRiskManagementProjectManagementObservations, _tedianRiskManagementTaskObservations) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var RiskManagementDataRepository = (function () {
    function RiskManagementDataRepository() {
      _classCallCheck(this, RiskManagementDataRepository);
    }

    _createClass(RiskManagementDataRepository, [{
      key: 'projectManagementFactors',
      value: function projectManagementFactors() {
        return _underscore['default'].sortBy(convertToFactorSet(buildContractData(_tedianRiskManagementProjectManagementObservations['default'])), function (x) {
          return x;
        });
      }
    }, {
      key: 'estimationFactorsForProjects',
      value: function estimationFactorsForProjects(projects) {
        var taskData = buildTaskData(_tedianRiskManagementTaskObservations['default']);

        return _underscore['default'].sortBy(convertToFactorSet(_underscore['default'].filter(taskData, function (task) {
          return _underscore['default'].contains(projects, task.project);
        })), function (x) {
          return x;
        });
      }
    }, {
      key: 'estimationFactors',
      value: function estimationFactors() {
        var taskData = buildTaskData(_tedianRiskManagementTaskObservations['default']);
        return _underscore['default'].sortBy(convertToFactorSet(taskData), function (x) {
          return x;
        });
      }
    }]);

    return RiskManagementDataRepository;
  })();

  exports['default'] = RiskManagementDataRepository;

  function buildContractData(records) {
    return _underscore['default'].map(records, function (row) {
      return {
        totalMinutes: row[2],
        projectManagementMinutes: row[1],
        factor: Math.round(row[1] / (row[2] - row[1]) * 1000) / 1000
      };
    });
  }

  function buildTaskData(records) {
    return _underscore['default'].map(records, function (row) {
      return {
        project: row[0].split(' - ')[0],
        estimatedMinutes: row[1],
        actualMinutes: row[2],
        factor: Math.round(row[2] / row[1] * 1000) / 1000
      };
    });
  }

  function convertToFactorSet(elements) {
    return _underscore['default'].map(elements, function (item) {
      return item.factor;
    });
  }
});