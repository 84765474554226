define('tedian/components/form-field', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['form-field'],
    classNameBindings: ['isInvalid:form-field--with-errors'],
    model: [],
    attribute: null,

    errors: _ember['default'].computed('model.errors.[]', 'attribute', function () {
      return this.get('model.errors').errorsFor(this.get('attribute')).map(function (error) {
        // API returns error messages in pattern '[API field name] - [message]'
        // We only want the message part, therefore we remove the prefix.
        var message = error.message.slice(error.message.indexOf(' - ') + 3);

        return _ember['default'].Object.create({
          attribute: error.attribute,
          message: message
        });
      });
    }),

    isInvalid: _ember['default'].computed.notEmpty('errors'),

    isValid: _ember['default'].computed('isInvalid', function () {
      return !this.get('isInvalid');
    })
  });
});