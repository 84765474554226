define('tedian/mixins/namespaced-route-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    activate: function activate() {
      this._super.apply(this, arguments);

      var className = this.get('namespaceClassName');

      if (className) {
        _ember['default'].$('body').addClass(className);
      }
    },

    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      var className = this.get('namespaceClassName');

      if (className) {
        _ember['default'].$('body').removeClass(className);
      }
    }
  });
});