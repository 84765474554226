define("tedian/templates/quote", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 9,
              "column": 4
            }
          },
          "moduleName": "tedian/templates/quote.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h1");
          var el2 = dom.createTextNode("Angebot #");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" – ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "flex-row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "flex-column");
          var el3 = dom.createTextNode("Erstellt am ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "flex-column align-right");
          var el3 = dom.createTextNode("Gültig bis ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var element10 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element9, 1, 1);
          morphs[1] = dom.createMorphAt(element9, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
          morphs[3] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "pad-with-zeros", [["get", "model.number", ["loc", [null, [4, 34], [4, 46]]]]], [], ["loc", [null, [4, 17], [4, 48]]]], ["content", "model.title", ["loc", [null, [4, 57], [4, 72]]]], ["inline", "moment-format", [["get", "model.issuedOn", ["loc", [null, [6, 59], [6, 73]]]], "DD.MM.YYYY"], [], ["loc", [null, [6, 43], [6, 88]]]], ["inline", "moment-format", [["get", "model.validUntil", ["loc", [null, [7, 70], [7, 86]]]], "DD.MM.YYYY"], [], ["loc", [null, [7, 54], [7, 101]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 6
              },
              "end": {
                "line": 18,
                "column": 6
              }
            },
            "moduleName": "tedian/templates/quote.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "flex-column align-right");
            var el2 = dom.createTextNode("Angenommen am ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "moment-format", [["get", "model.contractEnteredOn", ["loc", [null, [17, 73], [17, 96]]]], "DD.MM.YYYY"], [], ["loc", [null, [17, 57], [17, 111]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 20,
              "column": 4
            }
          },
          "moduleName": "tedian/templates/quote.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h1");
          var el2 = dom.createTextNode("Auftrag #");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" – ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "flex-row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "flex-column");
          var el3 = dom.createTextNode("Erteilt am ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element7, 1, 1);
          morphs[1] = dom.createMorphAt(element7, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
          morphs[3] = dom.createMorphAt(element8, 3, 3);
          return morphs;
        },
        statements: [["inline", "pad-with-zeros", [["get", "model.number", ["loc", [null, [12, 34], [12, 46]]]]], [], ["loc", [null, [12, 17], [12, 48]]]], ["content", "model.title", ["loc", [null, [12, 57], [12, 72]]]], ["inline", "moment-format", [["get", "model.contractAwardedOn", ["loc", [null, [14, 58], [14, 81]]]], "DD.MM.YYYY"], [], ["loc", [null, [14, 42], [14, 96]]]], ["block", "if", [["get", "model.contractEnteredOn", ["loc", [null, [16, 12], [16, 35]]]]], [], 0, null, ["loc", [null, [16, 6], [18, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 4
            },
            "end": {
              "line": 32,
              "column": 4
            }
          },
          "moduleName": "tedian/templates/quote.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "quote-item__number");
          var el3 = dom.createTextNode("#");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "quote-item__title");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "quote-item__amount");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element6, 'class');
          morphs[1] = dom.createAttrMorph(element6, 'id');
          morphs[2] = dom.createElementMorph(element6);
          morphs[3] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
          morphs[4] = dom.createMorphAt(dom.childAt(element6, [3]), 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element6, [5]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["quote-item quote-item--clickable ", ["subexpr", "unless", [["get", "item.isWithinScope", ["loc", [null, [27, 58], [27, 76]]]], "quote-item--out-of-scope"], [], ["loc", [null, [27, 49], [27, 105]]]], " ", ["subexpr", "if", [["get", "item.canBeAwarded", ["loc", [null, [27, 111], [27, 128]]]], "quote-item--can-be-awarded"], [], ["loc", [null, [27, 106], [27, 159]]]], " ", ["subexpr", "if", [["get", "item.isCanceled", ["loc", [null, [27, 165], [27, 180]]]], "quote-item--is-canceled"], [], ["loc", [null, [27, 160], [27, 208]]]], " ", ["subexpr", "if", [["get", "item.canBeEntered", ["loc", [null, [27, 214], [27, 231]]]], "quote-item--can-be-entered"], [], ["loc", [null, [27, 209], [27, 262]]]]]]], ["attribute", "id", ["concat", ["top-item-", ["get", "item.number", ["loc", [null, [27, 279], [27, 290]]]]]]], ["element", "action", ["jumpDown", ["get", "item.number", ["loc", [null, [27, 314], [27, 325]]]]], [], ["loc", [null, [27, 294], [27, 327]]]], ["inline", "pad-with-zeros", [["get", "item.number", ["loc", [null, [28, 56], [28, 67]]]]], [], ["loc", [null, [28, 39], [28, 69]]]], ["content", "item.title", ["loc", [null, [29, 37], [29, 51]]]], ["inline", "format-money", [["get", "item.amount", ["loc", [null, [30, 53], [30, 64]]]]], [], ["loc", [null, [30, 38], [30, 66]]]]],
        locals: ["item"],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 4
            },
            "end": {
              "line": 46,
              "column": 4
            }
          },
          "moduleName": "tedian/templates/quote.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "quote-item__number");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "quote-item__title");
          var el3 = dom.createTextNode("Rabatt");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "quote-item__amount");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [5]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["quote-item ", ["subexpr", "if", [["get", "model.hasAwardableItems", ["loc", [null, [41, 32], [41, 55]]]], "quote-item--can-be-awarded"], [], ["loc", [null, [41, 27], [41, 86]]]]]]], ["inline", "format-money", [["get", "model.specialStaticDiscount", ["loc", [null, [44, 53], [44, 80]]]]], [], ["loc", [null, [44, 38], [44, 82]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 2
            },
            "end": {
              "line": 66,
              "column": 2
            }
          },
          "moduleName": "tedian/templates/quote.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "quote__section");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          var el3 = dom.createTextNode("Zeitplan");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "flex-row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "flex-column");
          var el4 = dom.createTextNode("Start der Entwicklung: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "flex-column align-right");
          var el4 = dom.createTextNode("Ziel für Fertigstellung: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element3, 3, 3);
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "charts/cumulative-schedule", [], ["schedule", ["subexpr", "@mut", [["get", "model.schedule", ["loc", [null, [59, 42], [59, 56]]]]], [], []]], ["loc", [null, [59, 4], [59, 58]]]], ["inline", "moment-format", [["get", "model.workStartsOn", ["loc", [null, [62, 70], [62, 88]]]], "DD.MM.YYYY"], [], ["loc", [null, [62, 54], [62, 103]]]], ["inline", "moment-format", [["get", "model.targetCompletionDate", ["loc", [null, [63, 84], [63, 110]]]], "DD.MM.YYYY"], [], ["loc", [null, [63, 68], [63, 125]]]]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 4
            },
            "end": {
              "line": 78,
              "column": 4
            }
          },
          "moduleName": "tedian/templates/quote.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createTextNode("Ungewissheit des Zeitplans");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      Wir können nicht sagen zu welchem konkreten Zeitpunkt die Entwicklung abgeschlossen ist da wir nur Schätzungen zur Entwicklungsdauer abgeben können.\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      Basierend auf den Schwankungen vergangener Schätzungen wird ein Zeitplan simuliert der zeigt wie hoch die Wahrscheinlichkeit ist, dass die Entwicklung bis zu einem bestimmten Zeitpunkt abgeschlossen ist.\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 89,
              "column": 4
            },
            "end": {
              "line": 100,
              "column": 4
            }
          },
          "moduleName": "tedian/templates/quote.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "quote-extended-item");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "quote-extended-item__header");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("#");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("strong");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "quote-extended-item__body");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "quote-extended-item__arrow");
          var el3 = dom.createTextNode("↑");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element0, 'id');
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [2]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
          morphs[4] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [["attribute", "id", ["concat", ["bottom-item-", ["get", "item.number", ["loc", [null, [90, 55], [90, 66]]]]]]], ["inline", "pad-with-zeros", [["get", "item.number", ["loc", [null, [92, 32], [92, 43]]]]], [], ["loc", [null, [92, 15], [92, 45]]]], ["content", "item.title", ["loc", [null, [92, 60], [92, 74]]]], ["inline", "simple-format", [["get", "item.description", ["loc", [null, [95, 24], [95, 40]]]]], [], ["loc", [null, [95, 8], [95, 42]]]], ["element", "action", ["jumpUp", ["get", "item.number", ["loc", [null, [98, 62], [98, 73]]]]], [], ["loc", [null, [98, 44], [98, 75]]]]],
        locals: ["item"],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 109,
            "column": 0
          }
        },
        "moduleName": "tedian/templates/quote.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "quote");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "quote__section");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "quote__section");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        var el4 = dom.createTextNode("Posten");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "quote-item__number");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "quote-item__title");
        var el5 = dom.createTextNode("Projekt Management");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "quote-item__amount");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "quote-item__number");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "quote-item__title");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "quote-item__amount");
        var el5 = dom.createTextNode("Gesamt Netto ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "quote__section");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        var el4 = dom.createTextNode("Risiken");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("strong");
        var el5 = dom.createTextNode("Ungewissheit zusätzlicher Anforderungen");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      Während der Entwicklung sammeln wir kontinuierlich neues Wissen über das Projekt und bisher unbekannte Aspekte und Anforderungen kommen zutage. Zusätzliche Anforderungen beeinflussen den Zeitplan sowie das Budget.\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "quote__section");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        var el4 = dom.createTextNode("Anforderungen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "quote__section");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" · ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element11 = dom.childAt(fragment, [0]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element11, [3]);
        var element14 = dom.childAt(element13, [5]);
        var element15 = dom.childAt(element13, [9]);
        var element16 = dom.childAt(element11, [11, 1]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(element12, 1, 1);
        morphs[1] = dom.createMorphAt(element12, 3, 3);
        morphs[2] = dom.createMorphAt(element13, 3, 3);
        morphs[3] = dom.createAttrMorph(element14, 'class');
        morphs[4] = dom.createMorphAt(dom.childAt(element14, [5]), 0, 0);
        morphs[5] = dom.createMorphAt(element13, 7, 7);
        morphs[6] = dom.createAttrMorph(element15, 'class');
        morphs[7] = dom.createMorphAt(dom.childAt(element15, [5]), 1, 1);
        morphs[8] = dom.createMorphAt(element11, 5, 5);
        morphs[9] = dom.createMorphAt(dom.childAt(element11, [7]), 3, 3);
        morphs[10] = dom.createMorphAt(dom.childAt(element11, [9]), 3, 3);
        morphs[11] = dom.createMorphAt(element16, 1, 1);
        morphs[12] = dom.createMorphAt(element16, 3, 3);
        return morphs;
      },
      statements: [["block", "if", [["get", "model.isQuote", ["loc", [null, [3, 10], [3, 23]]]]], [], 0, null, ["loc", [null, [3, 4], [9, 11]]]], ["block", "if", [["get", "model.isContract", ["loc", [null, [11, 10], [11, 26]]]]], [], 1, null, ["loc", [null, [11, 4], [20, 11]]]], ["block", "each", [["get", "visibleItems", ["loc", [null, [26, 12], [26, 24]]]]], [], 2, null, ["loc", [null, [26, 4], [32, 13]]]], ["attribute", "class", ["concat", ["quote-item ", ["subexpr", "if", [["get", "model.hasAwardableItems", ["loc", [null, [34, 32], [34, 55]]]], "quote-item--can-be-awarded"], [], ["loc", [null, [34, 27], [34, 86]]]]]]], ["inline", "format-money", [["get", "model.projectManagementAmount", ["loc", [null, [37, 53], [37, 82]]]]], [], ["loc", [null, [37, 38], [37, 84]]]], ["block", "if", [["get", "model.specialStaticDiscount", ["loc", [null, [40, 10], [40, 37]]]]], [], 3, null, ["loc", [null, [40, 4], [46, 11]]]], ["attribute", "class", ["concat", ["quote-item quote-item--last ", ["subexpr", "if", [["get", "model.hasAwardableItems", ["loc", [null, [48, 49], [48, 72]]]], "quote-item--can-be-awarded"], [], ["loc", [null, [48, 44], [48, 103]]]]]]], ["inline", "format-money", [["get", "totalAmount", ["loc", [null, [51, 66], [51, 77]]]]], [], ["loc", [null, [51, 51], [51, 79]]]], ["block", "if", [["get", "model.workStartsOn", ["loc", [null, [55, 8], [55, 26]]]]], [], 4, null, ["loc", [null, [55, 2], [66, 9]]]], ["block", "if", [["get", "model.workStartsOn", ["loc", [null, [71, 10], [71, 28]]]]], [], 5, null, ["loc", [null, [71, 4], [78, 11]]]], ["block", "each", [["get", "visibleItems", ["loc", [null, [89, 12], [89, 24]]]]], [], 6, null, ["loc", [null, [89, 4], [100, 13]]]], ["inline", "link-to", ["Impressum", "legal.imprint"], [], ["loc", [null, [105, 6], [105, 45]]]], ["inline", "link-to", ["Datenschutzerklärung", "legal.privacy-policy"], [], ["loc", [null, [105, 55], [105, 112]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});