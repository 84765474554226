define('tedian/components/charts/activity-breakdown', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    chartColors: ['#263238', '#455A64', '#607D8B', '#90A4AE', '#B0BEC5', '#CFD8DC', '#ECEFF1'],
    model: undefined,

    content: _ember['default'].computed('model.items.[]', function () {
      var items = this.get('model.items');

      return [{
        name: 'Erfasste Zeit',
        type: 'pie',
        colorByPoint: true,
        data: items.map(function (item) {
          return {
            name: item.get('name'),
            y: item.get('value')
          };
        })
      }];
    }),

    chartOptions: _ember['default'].computed('chartColors', function () {
      return {
        chart: {
          style: { fontFamily: 'inherit', fontSize: 'inherit' },
          backgroundColor: 'transparent',
          spacing: [0, 0, 0, 0],
          height: 200
        },
        title: { text: null },
        legend: { enabled: false },
        colors: this.get('chartColors'),
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}%</b> / {point.y:.2f}h'
        },
        plotOptions: {
          pie: {
            dataLabels: { enabled: false }
          }
        }
      };
    })
  });
});