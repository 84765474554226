define('tedian/routes/project-management/project', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    parentRoute: 'project-management.project',
    newTicketRoute: 'project-management.project.new-ticket',

    model: function model(parameter) {
      return new _ember['default'].RSVP.hash({
        project: this.store.findRecord('project-management-project', parameter.project_id, { include: 'members' }),
        tickets: this.store.query('project-management-ticket', { include: 'assignees', filter: { 'project-id': parameter.project_id } })
      });
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('parentRoute', this.get('parentRoute'));
      controller.set('newTicketRoute', this.get('newTicketRoute'));
      controller.set('ticketColumnHeadline', this.ticketColumnHeadline());
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    },

    ticketColumnHeadline: function ticketColumnHeadline() {
      return this.modelFor(this.get('parentRoute')).project.get('name');
    }
  });
});