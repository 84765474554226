define('tedian/components/app-layout', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['app-layout'],
    classNameBindings: ['isSidebarExpanded:app-layout--sidebar-expanded:app-layout--sidebar-collapsed'],
    session: _ember['default'].inject.service('session'),
    timeTracking: _ember['default'].inject.service('time-tracking'),
    isSidebarExpanded: false,

    init: function init() {
      this._super.apply(this, arguments);

      // Get timers to make sure observer is triggered
      // https://guides.emberjs.com/release/object-model/observers/#toc_unconsumed-computed-properties-do-not-trigger-observers
      this.get('timeTracking.timers');
    },

    timersChanged: _ember['default'].observer('timeTracking.timers', function () {
      var timers = this.get('timeTracking.timers');
      var state = undefined;

      if (_ember['default'].isBlank(timers)) {
        state = 'default';
      } else {
        state = 'active';
      }

      if (state in window.favicons) {
        document.querySelector('link[rel="icon"]').setAttribute('href', window.favicons[state]);
      }
    }),

    actions: {
      toggleSidebar: function toggleSidebar() {
        this.set('isSidebarExpanded', !this.get('isSidebarExpanded'));
      }
    }
  });
});