define('tedian/controllers/time-tracking/interval-new', ['exports', 'ember', 'moment', 'tedian/commands/time-tracking/record-interval-command', 'ember-uuid', 'tedian/helpers/date-to-iso8601'], function (exports, _ember, _moment, _tedianCommandsTimeTrackingRecordIntervalCommand, _emberUuid, _tedianHelpersDateToIso8601) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['tags'],
    commandStore: _ember['default'].inject.service('command-store'),
    formModel: null,

    actions: {
      save: function save(formModel) {
        var _this = this;

        var store = this.get('commandStore');
        var intervalId = (0, _emberUuid.v4)();
        var comment = formModel.get('comment');
        var tagIds = formModel.get('selectedTags').mapBy('id');
        var startedAt = formModel.get('startedAt');
        var stoppedAt = formModel.get('stoppedAt');
        var command = new _tedianCommandsTimeTrackingRecordIntervalCommand['default']({
          intervalId: intervalId,
          comment: comment,
          tagIds: tagIds,
          startedAt: (0, _tedianHelpersDateToIso8601['default'])(startedAt),
          stoppedAt: (0, _tedianHelpersDateToIso8601['default'])(stoppedAt)
        });

        store.submit(command).then(function () {
          _this.transitionToRoute('time-tracking.archive.date', (0, _moment['default'])(startedAt).format('YYYY-MM-DD'));
        });
      }
    }
  });
});