define('tedian/routes/invoicing/payments/new', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var invoice = this.modelFor('invoicing.payments');

      return invoice.get('invoiceItems').then(function () {
        return _this.store.createRecord('payment', {
          invoice: invoice,
          amount: invoice.get('outstandingBalance'),
          receivedOn: (0, _moment['default'])().format('YYYY-MM-DD')
        });
      });
    },

    actions: {
      savePayment: function savePayment() {
        var _this2 = this;

        var payment = this.modelFor('invoicing.payments.new');

        payment.save().then(function () {
          _this2.transitionTo('invoicing.pending.index');
        });
      },

      willTransition: function willTransition() {
        var payment = this.modelFor('invoicing.payments.new');

        if (payment.get('isNew')) {
          payment.deleteRecord();
        }

        return true;
      }
    }
  });
});