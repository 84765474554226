define('tedian/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      if (key === 'human-resources-paid-leaves') {
        return 'human-resources-paid-leave';
      } else {
        return this._super(key);
      }
    }
  });
});