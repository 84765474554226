define('tedian/controllers/time-tracking/archive/date', ['exports', 'ember', 'moment', 'tedian/value-objects/date-scope', 'tedian/value-objects/target-actual-report'], function (exports, _ember, _moment, _tedianValueObjectsDateScope, _tedianValueObjectsTargetActualReport) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),

    scopes: _ember['default'].computed('model.date', function () {
      var date = (0, _moment['default'])(this.get('model.date'));

      return _ember['default'].Object.create({
        current: _tedianValueObjectsDateScope['default'].fromDate(date.toDate(), 'day'),
        previous: _tedianValueObjectsDateScope['default'].fromDate(date.clone().subtract(1, 'day').toDate(), 'day'),
        next: _tedianValueObjectsDateScope['default'].fromDate(date.clone().add(1, 'day').toDate(), 'day')
      });
    }),

    attendanceIntervals: _ember['default'].computed('model.intervals.[]', function () {
      return this.get('model.intervals').filter(function (interval) {
        return interval.get('timeTrackingTags').any(function (tag) {
          return tag.isOfType('attendance');
        });
      });
    }),

    activityIntervals: _ember['default'].computed('model.intervals.[]', function () {
      return this.get('model.intervals').filter(function (interval) {
        return interval.get('timeTrackingTags').every(function (tag) {
          return !tag.isOfType('attendance');
        });
      });
    }),

    groupedIntervals: _ember['default'].computed('attendanceIntervals.[]', 'activityIntervals.[]', function () {
      return [_ember['default'].Object.create({
        name: 'Anwesenheit',
        intervals: this.get('attendanceIntervals')
      }), _ember['default'].Object.create({
        name: 'Leistungen',
        intervals: this.get('activityIntervals')
      })];
    }),

    singleDaySchedule: _ember['default'].computed('model.date', 'model.schedule', function () {
      var date = (0, _moment['default'])(this.get('model.date')).startOf('day');

      return this.get('model.schedule').find(function (day) {
        return (0, _moment['default'])(day.get('from')).isSame(date, 'day');
      });
    }),

    dayReport: _ember['default'].computed('model.date', 'model.schedule', 'model.attendanceReport', function () {
      var date = (0, _moment['default'])(this.get('model.date')).startOf('day').toDate();
      var schedule = this.get('model.schedule');
      var attendanceReportItems = this.get('model.attendanceReport.items');

      var days = [date].map(function (_date) {
        var day = schedule.find(function (_day) {
          return (0, _moment['default'])(_day.get('from')).isSame(_date, 'day');
        }) || _ember['default'].Object.create({
          from: _date,
          target: 0,
          details: []
        });
        var reportItems = attendanceReportItems.filter(function (item) {
          return (0, _moment['default'])(item.get('meta.aggregationValue')).isSame(day.get('from'), 'day');
        });
        var actual = reportItems.reduce(function (previous, current) {
          return previous + current.get('value');
        }, 0);

        return {
          name: (0, _moment['default'])(day.get('from')).format('dd, DD.MM.YYYY'),
          actual: actual,
          target: day.get('target'),
          meta: {
            date: day.get('from'),
            notes: day.get('details').mapBy('name')
          }
        };
      });

      return _tedianValueObjectsTargetActualReport['default'].load(days, 'day');
    })
  });
});