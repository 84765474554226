define('tedian/routes/time-tracking/tags/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('formModel', _ember['default'].Object.create({
        tagId: undefined,
        name: '',
        slug: ''
      }));
    }
  });
});