define('tedian/routes/time-tracking/archive/date', ['exports', 'ember', 'tedian/value-objects/simple-report'], function (exports, _ember, _tedianValueObjectsSimpleReport) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    dutyRoster: _ember['default'].inject.service('duty-roster'),

    model: function model(parameters) {
      var currentUserId = this.get('session.currentUser.id');
      var reportFilter = 'user:' + currentUserId;
      var reportInterval = [parameters.date, parameters.date].join('--');

      return _ember['default'].RSVP.hash({
        date: parameters.date,
        intervals: this.store.query('time-tracking-interval', {
          filter: { user_tag: 'user:' + currentUserId, date: parameters.date },
          sort: '-started-at',
          include: 'time-tracking-tags'
        }),
        attendanceReport: this.requestReport('attendance', reportFilter, reportInterval, 'day'),
        schedule: this.get('dutyRoster').query({
          interval: reportInterval,
          userId: currentUserId,
          aggregate: 'day'
        })
      });
    },

    requestReport: function requestReport(dimension, filter, interval, aggregate) {
      var _this = this;

      return _ember['default'].$.ajax({
        url: this.store.adapterFor('application').buildURL('time-tracking-report'),
        type: 'GET',
        data: {
          dimension: dimension,
          filter: filter,
          interval: interval,
          aggregate: aggregate
        }
      }).then(function (response) {
        response.forEach(function (row) {
          row.tag = _this.store.peekRecord('time-tracking-tag', row.dimension_tag_id);
        });

        return _tedianValueObjectsSimpleReport['default'].fromRows(response);
      });
    }
  });
});