define('tedian/routes/human-resources/index', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      year: { refreshModel: true }
    },

    dutyRoster: _ember['default'].inject.service('duty-roster'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return _ember['default'].RSVP.hash({
        dutyRoster: this.get('dutyRoster').load(),
        tags: this.store.findAll('time-tracking-tag')
      });
    },

    model: function model(parameters) {
      var year = undefined,
          interval = undefined;

      if (parameters.year) {
        year = (0, _moment['default'])([parameters.year, 0, 1]);
        interval = [year.clone().startOf('year').format('YYYY-MM-DD'), year.clone().endOf('year').format('YYYY-MM-DD')].join('--');
      } else {
        var yesterday = (0, _moment['default'])().subtract(1, 'day');
        year = (0, _moment['default'])([new Date().getFullYear(), 0, 1]);
        interval = [year.clone().startOf('year').format('YYYY-MM-DD'), yesterday.format('YYYY-MM-DD')].join('--');
      }

      var attendanceReportParameters = {
        dimension: 'user',
        aggregate: 'total',
        interval: interval
      };
      var scheduleParameters = {
        interval: interval,
        aggregate: 'year'
      };

      return _ember['default'].RSVP.hash({
        scheduleByYear: this.get('dutyRoster').query(scheduleParameters),
        schedulesInEffect: this.store.query('human-resources-work-schedule', {
          include: 'user',
          filter: { 'in-effect-on': interval }
        }),
        attendanceByUserReports: _ember['default'].RSVP.all([this.requestReport(Object.assign({}, attendanceReportParameters, { filter: 'attendance:office' })), this.requestReport(Object.assign({}, attendanceReportParameters, { filter: 'attendance:home-office' })), this.requestReport(Object.assign({}, attendanceReportParameters, { filter: 'attendance:field' }))])
      });
    },

    requestReport: function requestReport(data) {
      var _this = this;

      return _ember['default'].$.ajax({
        url: this.store.adapterFor('application').buildURL('time-tracking-report'),
        type: 'GET',
        data: data
      }).then(function (response) {
        response.forEach(function (row) {
          row.tag = _this.store.peekRecord('time-tracking-tag', row.dimension_tag_id);
        });

        return response;
      });
    }
  });
});