define('tedian/initializers/setup-pikaday-i18n', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports.initialize = initialize;

  function initialize(application) {
    var i18n = _ember['default'].Object.extend({
      previousMonth: 'Vorheriger Monat',
      nextMonth: 'Nächster Monat',
      months: _moment['default'].months(),
      weekdays: _moment['default'].weekdays(),
      weekdaysShort: _moment['default'].weekdaysShort()
    });

    application.register('pikaday-i18n:main', i18n, { singleton: true });
    application.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
  }

  exports['default'] = {
    name: 'setup-pikaday-i18n',
    initialize: initialize
  };
});