define('tedian/components/datetime-picker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['datetime-picker'],
    mode: 'datetime', // options: 'date', 'datetime'
    dateFormat: 'DD.MM.YYYY',
    timeFormat: 'HH:mm',

    isDatetimeMode: _ember['default'].computed.equal('mode', 'datetime'),
    isDateMode: _ember['default'].computed.equal('mode', 'date'),

    formattedDate: _ember['default'].computed('value', 'dateFormat', function () {
      var date = (0, _moment['default'])(this.get('value'));

      if (date.isValid()) {
        return date.format(this.get('dateFormat'));
      } else {
        return null;
      }
    }),

    formattedTime: _ember['default'].computed('value', 'timeFormat', function () {
      var time = (0, _moment['default'])(this.get('value'));

      if (time.isValid() && time.hours() !== 0 && time.minutes !== 0) {
        return time.format(this.get('timeFormat'));
      } else {
        return null;
      }
    }),

    datetime: _ember['default'].computed('formattedDate', 'formattedTime', 'dateFormat', 'timeFormat', function () {
      var formattedDate = this.get('formattedDate');
      var formattedTime = this.get('formattedTime') || (0, _moment['default'])().startOf('day').format(this.get('timeFormat'));

      if (_ember['default'].isNone(formattedDate) || _ember['default'].isNone(formattedTime)) {
        return null;
      } else {
        return (0, _moment['default'])(formattedDate + ' ' + formattedTime, this.get('dateFormat') + ' ' + this.get('timeFormat')).toDate();
      }
    }),

    actions: {
      updateDate: function updateDate(value) {
        var format = this.get('dateFormat');

        if (!_ember['default'].isBlank(value)) {
          this.set('formattedDate', (0, _moment['default'])(value).format(format));
        } else {
          this.set('formattedDate', null);
        }

        this.get('onChange')(this.get('datetime'));
      },

      updateTime: function updateTime(value) {
        var format = this.get('timeFormat');

        if (!_ember['default'].isBlank(value)) {
          this.set('formattedTime', (0, _moment['default'])(value, format).format(format));
        } else {
          this.set('formattedTime', null);
        }

        this.get('onChange')(this.get('datetime'));
      }
    }
  });
});