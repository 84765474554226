define('tedian/controllers/human-resources/index', ['exports', 'ember', 'tedian/value-objects/date-scope'], function (exports, _ember, _tedianValueObjectsDateScope) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: { year: 'year' },

    year: null,

    summaryByEmployee: _ember['default'].computed('model.scheduleByYear.[]', 'relevantEmployees.[]', function () {
      var _this = this;

      var yearScheduleByUserId = this.get('model.scheduleByYear').reduce(function (map, current) {
        map[current.get('userId')] = current;
        return map;
      }, {});

      return this.get('relevantEmployees').map(function (employee) {
        var attendanceTarget = yearScheduleByUserId[employee.get('id')].get('target');
        var attendanceActual = _this.get('model.attendanceByUserReports').reduce(function (sum, current) {
          var row = current.find(function (row) {
            return row.tag.get('slug') === 'user:' + employee.get('id');
          });
          return sum + (row ? row.seconds : 0);
        }, 0) / (60 * 60);
        var holidaysTarget = yearScheduleByUserId[employee.get('id')].get('numberOfHolidays');
        var holidaysActual = yearScheduleByUserId[employee.get('id')].get('details').filter(function (day) {
          return day.get('type') === 'paidLeaveDay';
        }).reduce(function (sum, current) {
          return sum + current.get('percentage');
        }, 0);
        var compensationTarget = Math.max(0, attendanceActual - attendanceTarget);
        var compensationActual = yearScheduleByUserId[employee.get('id')].get('details').filter(function (day) {
          return day.get('type') === 'compensationDay';
        }).reduce(function (sum, current) {
          return sum + current.get('hours');
        }, 0);

        return _ember['default'].Object.create({
          name: employee.get('name'),
          attendance: {
            target: attendanceTarget,
            actual: attendanceActual,
            left: attendanceActual - attendanceTarget
          },
          holidays: {
            target: holidaysTarget,
            actual: holidaysActual,
            left: holidaysActual - holidaysTarget
          },
          compensations: {
            target: compensationTarget,
            actual: compensationActual,
            left: compensationActual - compensationTarget
          }
        });
      }).sortBy('name');
    }),

    scopes: _ember['default'].computed(function () {
      var scopes = [];
      var maximumYear = new Date().getFullYear();
      var minimumYear = 2017;
      var year = minimumYear;

      while (year <= maximumYear) {
        scopes.push(_tedianValueObjectsDateScope['default'].fromDate(new Date(year, 0, 1), 'year'));
        year++;
      }

      return scopes.reverse();
    }),

    relevantEmployees: _ember['default'].computed('model.schedulesInEffect.[]', function () {
      return this.get('model.schedulesInEffect').reduce(function (collection, schedule) {
        var isEmployeeMissing = collection.find(function (employee) {
          return employee.get('id') === schedule.get('user.id');
        }) === undefined;

        if (isEmployeeMissing) {
          collection.push(schedule.get('user'));
        }

        return collection;
      }, []);
    })
  });
});