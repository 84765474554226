define('tedian/routes/time-tracking/archive', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    dutyRoster: _ember['default'].inject.service('duty-roster'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return _ember['default'].RSVP.hash({
        dutyRoster: this.get('dutyRoster').load(),
        tags: this.store.findAll('time-tracking-tag')
      });
    }
  });
});