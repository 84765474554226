define('tedian/controllers/controlling/contracts/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortAttribute: null,
    sortDirection: 'ASC',

    sortedContracts: _ember['default'].computed('model.contracts.@each', 'sortAttribute', 'sortDirection', function () {
      var sortAttribute = this.get('sortAttribute');
      var sortDirection = this.get('sortDirection');

      if (sortAttribute && sortDirection === 'ASC') {
        return this.get('model.contracts').sortBy(sortAttribute);
      } else if (sortAttribute && sortDirection === 'DESC') {
        return this.get('model.contracts').sortBy(this.get('sortAttribute')).reverse();
      } else {
        return this.get('model.contracts');
      }
    }),

    actions: {
      sortBy: function sortBy(attribute) {
        var _this = this;

        var direction = undefined;

        if (this.get('sortAttribute') === attribute) {
          direction = ['ASC', 'DESC'].find(function (direction) {
            return direction !== _this.get('sortDirection');
          });
        } else {
          direction = 'ASC';
        }

        this.setProperties({
          sortAttribute: attribute,
          sortDirection: direction
        });
      }
    }
  });
});