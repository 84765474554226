define('tedian/initializers/ajax-prefiller', ['exports', 'ember', 'npm:js-cookie'], function (exports, _ember, _npmJsCookie) {
  exports.initialize = initialize;

  function initialize() {
    _ember['default'].$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
      jqXHR.setRequestHeader('X-AUTHENTICATION-TOKEN', _npmJsCookie['default'].get('authentication_token'));
    });
  }

  exports['default'] = {
    name: 'ajax-prefiller',
    initialize: initialize
  };
});