define('tedian/routes/procurement/cancel-contract', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return new _ember['default'].RSVP.hash({
        contract: this.store.findRecord('procurement-quote', parameters.id, { include: 'items' })
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('contractCanceledOn', (0, _moment['default'])().format('YYYY-MM-DD'));
      controller.set('reason', '');
      controller.set('items', model.contract.get('items').filter(function (item) {
        return ['item-entered', 'item-awarded'].includes(item.get('state'));
      }).map(function (item) {
        return _ember['default'].ObjectProxy.create({
          content: item,
          isCanceled: true
        });
      }));
    }
  });
});