define('tedian/routes/project-management/project/ticket/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    parentRoute: 'project-management.project',
    ticketRoute: 'project-management.project.ticket',

    model: function model() {
      return this.modelFor(this.get('ticketRoute')).ticket;
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('ticketRoute', this.get('ticketRoute'));
      controller.set('parentRoute', this.get('parentRoute'));
    }
  });
});