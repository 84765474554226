define('tedian/adapters/invoice', ['exports', 'tedian/adapters/application'], function (exports, _tedianAdaptersApplication) {
  exports['default'] = _tedianAdaptersApplication['default'].extend({
    download: function download(id) {
      return this.ajax(this.urlForDownloadAction(id), 'POST', { data: {} });
    },

    urlForDownloadAction: function urlForDownloadAction(id) {
      return this.buildURL('invoice', id) + '/download';
    }
  });
});