define('tedian/routes/time-tracking/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),

    model: function model() {
      return new _ember['default'].RSVP.hash({
        templateTimers: this.store.findAll('time-tracking-template-timer'),
        tags: this.store.query('time-tracking-tag', { filter: { 'is-locked': false } })
      });
    }
  });
});