define('tedian/helpers/format-duration', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports.formatDuration = formatDuration;

  function formatDuration(params, hash) {
    var duration = params[0];
    var format = hash.format || 'HH:mm:ss';
    var unit = hash.unit || 'milliseconds';
    var momentDuration = _moment['default'].duration(Math.abs(duration), unit);

    return momentDuration.format(format, { trim: false });
  }

  exports['default'] = _ember['default'].Helper.helper(formatDuration);
});