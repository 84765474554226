define('tedian/controllers/quote', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortOptions: ['displayIndex:asc'],
    sortedItems: _ember['default'].computed.sort('model.items', 'sortOptions'),

    visibleItems: _ember['default'].computed('sortedItems.[]', function () {
      if (['quote-issued', 'contract-awarded', 'contract-entered', 'contract-canceled'].includes(this.get('model.state'))) {
        return this.get('sortedItems').filter(function (item) {
          return ['item-issued', 'item-awarded', 'item-entered', 'item-canceled'].includes(item.get('state'));
        });
      } else {
        return this.get('sortedItems');
      }
    }),

    totalAmount: _ember['default'].computed('model.totalAmount', 'model.specialStaticDiscount', function () {
      return this.get('model.totalAmount') + (this.get('model.specialStaticDiscount') || 0);
    }),

    actions: {
      jumpDown: function jumpDown(number) {
        window.location.hash = '#bottom-item-' + number;
      },

      jumpUp: function jumpUp(number) {
        window.location.hash = '#top-item-' + number;
      }
    }
  });
});