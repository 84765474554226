define('tedian/controllers/time-tracking/reports', ['exports', 'ember', 'moment', 'tedian/models/time-tracking-tag'], function (exports, _ember, _moment, _tedianModelsTimeTrackingTag) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['dimension', 'filter', 'interval', 'aggregate'],

    dimension: undefined,
    filter: undefined,
    interval: undefined,
    aggregate: 'total',
    intervalStart: undefined,
    intervalEnd: undefined,

    selectedDimension: _ember['default'].computed('dimension', 'optionsDimension.[]', function () {
      return this.get('optionsDimension').findBy('value', this.get('dimension'));
    }),

    selectedFilter: _ember['default'].computed('filter', 'optionsFilter.[]', function () {
      return this.get('optionsFilter').findBy('value', this.get('filter'));
    }),

    selectedAggregation: _ember['default'].computed('aggregate', 'optionsAggregation.[]', function () {
      return this.get('optionsAggregation').findBy('value', this.get('aggregate'));
    }),

    onIntervalChanges: _ember['default'].observer('intervalStart', 'intervalEnd', function () {
      var intervalStart = this.get('intervalStart');
      var intervalEnd = this.get('intervalEnd');

      if (_ember['default'].isPresent(intervalStart) && _ember['default'].isPresent(intervalEnd)) {
        this.set('interval', intervalStart + '--' + intervalEnd);
      } else {
        this.set('interval', undefined);
      }
    }),

    selectedIntervalStart: _ember['default'].computed('intervalStart', function () {
      return (0, _moment['default'])(this.get('intervalStart'), 'YYYY-MM-DD').toDate();
    }),

    selectedIntervalEnd: _ember['default'].computed('intervalEnd', function () {
      return (0, _moment['default'])(this.get('intervalEnd'), 'YYYY-MM-DD').toDate();
    }),

    optionsAggregation: [_ember['default'].Object.create({
      name: 'Total',
      value: 'total'
    }), _ember['default'].Object.create({
      name: 'Jahr',
      value: 'year'
    }), _ember['default'].Object.create({
      name: 'Monat',
      value: 'month'
    }), _ember['default'].Object.create({
      name: 'Woche',
      value: 'week'
    }), _ember['default'].Object.create({
      name: 'Tag',
      value: 'day'
    })],

    optionsDimension: _ember['default'].computed('tagTypeOptions.[]', function () {
      return this.get('tagTypeOptions');
    }),

    optionsFilter: _ember['default'].computed('tagOptions.[]', function () {
      return this.get('tagOptions');
    }),

    tagOptions: _ember['default'].computed('model.tags.[]', function () {
      var tags = this.get('model.tags');

      return tags.map(function (tag) {
        return _ember['default'].Object.create({
          name: (tag.get('typeLabel') || 'Ohne Typ') + ' - ' + tag.get('name'),
          value: tag.get('slug')
        });
      }).sortBy('name');
    }),

    tagTypeOptions: _ember['default'].computed('model.tags.[]', function () {
      var types = this.get('model.tags').mapBy('type').uniq().compact();

      return types.map(function (type) {
        return _ember['default'].Object.create({
          name: (0, _tedianModelsTimeTrackingTag.getLabelForTagType)(type),
          value: type
        });
      }).concat([_ember['default'].Object.create({
        name: 'Ohne Typ',
        value: 'without-type'
      })]).sortBy('name');
    }),

    actions: {
      selectDimension: function selectDimension(dimension) {
        this.set('dimension', dimension.get('value'));
      },

      selectFilter: function selectFilter(filter) {
        this.set('filter', filter.get('value'));
      },

      selectAggregation: function selectAggregation(aggregation) {
        this.set('aggregate', aggregation.get('value'));
      },

      updateInterval: function updateInterval(attribute, value) {
        if (_ember['default'].isBlank(value)) {
          this.set(attribute, undefined);
        } else {
          this.set(attribute, (0, _moment['default'])(value).format('YYYY-MM-DD'));
        }
      },

      resetField: function resetField(name) {
        this.set(name, undefined);
      }
    }
  });
});