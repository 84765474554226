define('tedian/components/login-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'form',
    classNames: ['login-form'],
    attributeBindings: ['novalidate'],
    novalidate: null,

    session: _ember['default'].inject.service('session'),

    email: null,
    password: null,

    isInvalid: false,

    onSuccess: _ember['default'].K,
    onFail: _ember['default'].K,

    clear: function clear() {
      this.set('isInvalid', false);
      this.set('email', '');
      this.set('password', '');
    },

    actions: {
      submit: function submit() {
        var _this = this;

        var email = this.get('email');
        var password = this.get('password');

        this.get('session').signIn(email, password).then(function () {
          _this.clear();
          _this.get('onSuccess')();
        }, function () {
          _this.set('isInvalid', true);
          _this.get('onFail')();
        });

        return false;
      }
    }
  });
});