define('tedian/routes/clients/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.query('organisation', { sort: 'name', include: 'contact-person' });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('invoices', this.store.findAll('invoice'));
    }
  });
});