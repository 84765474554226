define('tedian/routes/procurement/quote-form', ['exports', 'ember', 'tedian/risk-management/risk-management-data-repository'], function (exports, _ember, _tedianRiskManagementRiskManagementDataRepository) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return new _ember['default'].RSVP.hash({
        quote: this.store.findRecord('procurement-quote', parameters.id, { include: 'items' })
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (_ember['default'].isEmpty(model.quote.get('projectManagementDataPoints'))) {
        var repository = new _tedianRiskManagementRiskManagementDataRepository['default']();
        model.quote.set('projectManagementDataPoints', repository.projectManagementFactors());
      }
    }
  });
});