define('tedian/adapters/invoice-draft', ['exports', 'tedian/adapters/application'], function (exports, _tedianAdaptersApplication) {
  exports['default'] = _tedianAdaptersApplication['default'].extend({
    issue: function issue(id) {
      return this.ajax(this.urlForIssueAction(id), 'POST', { data: {} });
    },

    urlForIssueAction: function urlForIssueAction(id) {
      return this.buildURL('invoice-draft', id) + '/issue';
    }
  });
});