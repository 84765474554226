define('tedian/controllers/project-management', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    menuVisible: false,

    actions: {
      openMenu: function openMenu() {
        this.set('menuVisible', true);
      },
      closeMenu: function closeMenu() {
        this.set('menuVisible', false);
      }
    }
  });
});