define('tedian/controllers/time-tracking/archive/month', ['exports', 'ember', 'moment', 'tedian/models/time-tracking-tag', 'tedian/value-objects/date-scope', 'tedian/value-objects/target-actual-report', 'tedian/value-objects/simple-report'], function (exports, _ember, _moment, _tedianModelsTimeTrackingTag, _tedianValueObjectsDateScope, _tedianValueObjectsTargetActualReport, _tedianValueObjectsSimpleReport) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Controller.extend({
    chartColors: ['#263238', '#455A64', '#607D8B', '#90A4AE', '#B0BEC5', '#CFD8DC', '#ECEFF1'],

    scopes: _ember['default'].computed('model.month', function () {
      var current = (0, _moment['default'])(this.get('model.month'));

      return _ember['default'].Object.create({
        current: _tedianValueObjectsDateScope['default'].fromDate(current.toDate(), 'month'),
        previous: _tedianValueObjectsDateScope['default'].fromDate(current.clone().subtract(1, 'month').toDate(), 'month'),
        next: _tedianValueObjectsDateScope['default'].fromDate(current.clone().add(1, 'month').toDate(), 'month')
      });
    }),

    daysReport: _ember['default'].computed('model.month', 'model.scheduleByDay', 'model.attendanceByDayReport', function () {
      var month = this.get('model.month');
      var schedule = this.get('model.scheduleByDay');
      var attendanceReportItems = this.get('model.attendanceByDayReport.items');

      var numberOfDataPoints = (0, _moment['default'])(month).daysInMonth();
      var days = [].concat(_toConsumableArray(Array(numberOfDataPoints).keys())).map(function (index) {
        return (0, _moment['default'])(month).date(index + 1).format('YYYY-MM-DD');
      }).uniq().map(function (from) {
        var scheduleForDay = schedule.find(function (item) {
          return (0, _moment['default'])(item.get('from')).isSame(from, 'day');
        });
        var reportForDay = attendanceReportItems.filter(function (item) {
          return (0, _moment['default'])(item.get('meta.aggregationValue')).isSame(from, 'day');
        });
        var actual = reportForDay.reduce(function (previous, current) {
          return previous + current.get('value');
        }, 0);

        return {
          name: (0, _moment['default'])(from).startOf('day').format('dd, DD.MM.YYYY'),
          actual: actual,
          target: scheduleForDay ? scheduleForDay.get('target') : 0,
          meta: {
            date: (0, _moment['default'])(from).startOf('day').toDate(),
            notes: scheduleForDay ? scheduleForDay.get('details').mapBy('name') : []
          }
        };
      });

      return _tedianValueObjectsTargetActualReport['default'].load(days, 'day');
    }),

    tagTypeReports: _ember['default'].computed('model.internalTagsReport', 'model.contractTagsReport', 'model.contractHandlingTagsReport', function () {
      var internalTagsReport = this.get('model.internalTagsReport');
      var contractTagsReport = this.get('model.contractTagsReport');
      var contractHandlingTagsReport = this.get('model.contractHandlingTagsReport');

      return [{ title: (0, _tedianModelsTimeTrackingTag.getLabelForTagType)('contract'), report: contractTagsReport }, { title: (0, _tedianModelsTimeTrackingTag.getLabelForTagType)('contract-handling'), report: contractHandlingTagsReport }, { title: (0, _tedianModelsTimeTrackingTag.getLabelForTagType)('internal'), report: internalTagsReport }].map(function (data) {
        data.report.set('title', data.title);

        return data.report;
      });
    }),

    activityReport: _ember['default'].computed('daysReport', 'model.internalTagsReport', 'model.contractTagsReport', 'model.contractHandlingTagsReport', function () {
      var chartColors = this.get('chartColors');
      var internalTagsReport = this.get('model.internalTagsReport');
      var contractTagsReport = this.get('model.contractTagsReport');
      var contractHandlingTagsReport = this.get('model.contractHandlingTagsReport');
      var attendanceActual = this.get('daysReport.summary.actual');
      var taggedActivityActual = [internalTagsReport, contractTagsReport, contractHandlingTagsReport].reduce(function (sum, current) {
        return sum += current.get('summary.value');
      }, 0);

      var miscellaneousActual = (function () {
        if (attendanceActual > 0) {
          return Math.max(0, attendanceActual - taggedActivityActual);
        } else {
          return 0;
        }
      })();

      var items = [{ name: 'Aufträge', actual: contractTagsReport.get('summary.value') }, { name: 'Auftragsabwicklung', actual: contractHandlingTagsReport.get('summary.value') }, { name: 'Intern', actual: internalTagsReport.get('summary.value') }, { name: 'Sonstiges', actual: miscellaneousActual }];

      if (items.reduce(function (sum, current) {
        return sum += current.actual;
      }, 0) === 0) {
        items = [];
      }

      return _tedianValueObjectsSimpleReport['default'].create({
        items: items.map(function (row, index) {
          return _ember['default'].Object.create({
            color: chartColors[index],
            name: row.name,
            value: row.actual,
            percentage: 0
          });
        })
      });
    })
  });
});