define('tedian/transforms/payment-schedule', ['exports', 'ember-data/transform', 'tedian/value-objects/payment-schedule'], function (exports, _emberDataTransform, _tedianValueObjectsPaymentSchedule) {
  exports['default'] = _emberDataTransform['default'].extend({
    deserialize: function deserialize(serialized) {
      return _tedianValueObjectsPaymentSchedule['default'].load(serialized);
    },

    serialize: function serialize(deserialized) {
      return _tedianValueObjectsPaymentSchedule['default'].dump(deserialized);
    }
  });
});