define('tedian/routes/procurement/quotes', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return new _ember['default'].RSVP.hash({
        requestedQuotes: this.store.query('procurement-quote', {
          include: 'items',
          sort: '-number',
          filter: { state: 'quote' }
        }),
        issuedQuotes: this.store.query('procurement-quote', {
          include: 'items',
          sort: '-number',
          filter: { state: 'quote-issued' }
        })
      });
    },

    actions: {
      accept: function accept(quote) {
        if (!window.confirm('Angebot wurde wirklich beauftragt?')) {
          return;
        }

        quote.accept();
        quote.save();
      }
    }
  });
});