define('tedian/controllers/project-management/projects/new-project', ['exports', 'ember', 'tedian/commands/project-management/start-project-command'], function (exports, _ember, _tedianCommandsProjectManagementStartProjectCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    members: _ember['default'].computed.readOnly('model.members'),

    shortName: '',
    name: '',
    assignedMembers: [],

    membersByAvailability: _ember['default'].computed('members.[]', 'assignedMembers.[]', function () {
      var _this = this;

      return this.get('members').map(function (member) {
        return _ember['default'].ObjectProxy.create({
          content: member,
          isAssigned: _this.get('assignedMembers').contains(member)
        });
      });
    }),

    actions: {
      submit: function submit() {
        var _this2 = this;

        var command = new _tedianCommandsProjectManagementStartProjectCommand['default']({
          shortName: this.get('shortName'),
          name: this.get('name'),
          assignments: this.get('assignedMembers').map(function (member) {
            return { memberId: member.get('id') };
          })
        });

        this.get('commandStore').submit(command).then(function () {
          _this2.transitionToRoute('project-management.projects');
        });
      }
    }
  });
});