define('tedian/components/time-tag', ['exports', 'ember', 'tedian/models/time-tracking-tag'], function (exports, _ember, _tedianModelsTimeTrackingTag) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['time-tracking-tag'],
    classNameBindings: ['tagTypeClassName'],
    model: null,
    actionLabel: '',
    onAction: null,

    tagTypeClassName: _ember['default'].computed('model.type', function () {
      var type = this.get('model.type');
      var modifier = undefined;

      if (type && type.match(/^attendance$/)) {
        modifier = 'attendance';
      } else if (type && type.match(/^user$/)) {
        modifier = 'user';
      } else if (type && type.match(/^internal$/)) {
        modifier = 'internal';
      } else if (type && type.match(/^client$/)) {
        modifier = 'client';
      } else if (type && type.match(/^(contract|contract-handling|contract-item)$/)) {
        modifier = 'contract';
      } else {
        modifier = 'default';
      }

      return 'time-tracking-tag--' + modifier;
    }),

    tagTypeLabel: _ember['default'].computed('model.type', function () {
      return (0, _tedianModelsTimeTrackingTag.getLabelForTagType)(this.get('model.type'));
    }),

    actions: {
      triggerAction: function triggerAction() {
        if (this.get('onAction')) {
          this.get('onAction')();
        }
      }
    }
  });
});