define('tedian/controllers/procurement/quote-form', ['exports', 'ember', 'tedian/risk-management/risk-management-data-repository', 'tedian/commands/procurement/issue-item-command', 'tedian/commands/procurement/award-item-command', 'tedian/commands/procurement/enter-item-command', 'tedian/commands/procurement/cancel-item-command', 'moment'], function (exports, _ember, _tedianRiskManagementRiskManagementDataRepository, _tedianCommandsProcurementIssueItemCommand, _tedianCommandsProcurementAwardItemCommand, _tedianCommandsProcurementEnterItemCommand, _tedianCommandsProcurementCancelItemCommand, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    store: _ember['default'].inject.service('store'),
    quote: _ember['default'].computed.alias('model.quote'),
    commandStore: _ember['default'].inject.service('command-store'),

    sortOptions: ['displayIndex:asc'],
    sortedItems: _ember['default'].computed.sort('quote.items', 'sortOptions'),

    projectManagementConfidence: _ember['default'].computed('quote.projectManagementConfidence', function () {
      var value = this.get('quote.projectManagementConfidence') || 0;
      return value * 100;
    }),

    estimationConfidence: _ember['default'].computed('quote.estimationConfidence', function () {
      var value = this.get('quote.estimationConfidence') || 0;
      return value * 100;
    }),

    scheduleConfidence: _ember['default'].computed('quote.scheduleConfidence', function () {
      var value = this.get('quote.scheduleConfidence') || 0;
      return value * 100;
    }),

    estimationDataSet: _ember['default'].computed('quote.estimationDataLabel', 'estimationDataSets', function () {
      return this.get('estimationDataSets').findBy('name', this.get('quote.estimationDataLabel'));
    }),

    estimationDataSets: _ember['default'].computed(function () {
      var repository = new _tedianRiskManagementRiskManagementDataRepository['default']();

      return [_ember['default'].Object.create({
        name: 'Alle Projekte',
        dataPoints: repository.estimationFactors()
      }), _ember['default'].Object.create({
        name: 'JAK',
        dataPoints: repository.estimationFactorsForProjects(['JAK'])
      }), _ember['default'].Object.create({
        name: 'APL',
        dataPoints: repository.estimationFactorsForProjects(['APL'])
      }), _ember['default'].Object.create({
        name: 'JAK & APL',
        dataPoints: repository.estimationFactorsForProjects(['JAK', 'APL'])
      }), _ember['default'].Object.create({
        name: 'DW',
        dataPoints: repository.estimationFactorsForProjects(['DW'])
      }), _ember['default'].Object.create({
        name: 'GAL',
        dataPoints: repository.estimationFactorsForProjects(['GAL'])
      }), _ember['default'].Object.create({
        name: 'COA',
        dataPoints: repository.estimationFactorsForProjects(['COA'])
      }), _ember['default'].Object.create({
        name: 'MCD',
        dataPoints: repository.estimationFactorsForProjects(['MCD'])
      }), _ember['default'].Object.create({
        name: 'MOZ',
        dataPoints: repository.estimationFactorsForProjects(['MOZ'])
      }), _ember['default'].Object.create({
        name: 'NES',
        dataPoints: repository.estimationFactorsForProjects(['NES'])
      }), _ember['default'].Object.create({
        name: 'ZF',
        dataPoints: repository.estimationFactorsForProjects(['ZF'])
      }), _ember['default'].Object.create({
        name: '',
        dataPoints: repository.estimationFactorsForProjects([''])
      })];
    }),

    actions: {
      onProjectManagementConfidenceChange: function onProjectManagementConfidenceChange(event) {
        this.get('quote').setProjectManagementConfidence(parseInt(event.target.value));
      },

      onEstimationConfidenceChange: function onEstimationConfidenceChange(event) {
        this.get('quote').setEstimationConfidence(parseInt(event.target.value));
      },

      onScheduleConfidenceChange: function onScheduleConfidenceChange(event) {
        this.get('quote').setScheduleConfidence(parseInt(event.target.value));
      },

      onWorkStartsOnChange: function onWorkStartsOnChange(event) {
        this.get('quote').setWorkStartsOn(event.target.value);
      },

      onHourlyCostsChange: function onHourlyCostsChange(event) {
        this.get('quote').setHourlyCosts(parseFloat(event.target.value));
      },

      onHoursPerDayChange: function onHoursPerDayChange(event) {
        this.get('quote').setHoursPerDay(parseFloat(event.target.value));
      },

      selectDataSet: function selectDataSet(dataSet) {
        this.get('quote').setEstimationData(dataSet.get('name'), dataSet.get('dataPoints'));
      },

      addItem: function addItem() {
        if (!this.get('quote.canItemBeAdded')) {
          return;
        }

        var item = this.get('store').createRecord('procurement-item', {
          quote: this.get('quote'),
          isWithinScope: true,
          displayIndex: this.get('quote.items.length'),
          state: 'item'
        });

        item.save().then(function (item) {
          // Ruby on Rails doesn't return default attributes on creation when
          // database defaults are used.
          item.reload();
        });
      },

      onEstimatedHoursChange: function onEstimatedHoursChange(item, event) {
        this.get('quote').estimateItem(item, parseFloat(event.target.value));
      },

      onDisplayIndexChange: function onDisplayIndexChange(item, event) {
        item.set('displayIndex', parseInt(event.target.value));
      },

      onScopeChange: function onScopeChange(item, event) {
        if (event.target.checked) {
          this.get('quote').addItemToScope(item);
        } else {
          this.get('quote').removeItemFromScope(item);
        }
      },

      save: function save() {
        this.get('quote').save().then(function (quote) {
          var saveItemsRecursive = function saveItemsRecursive(items) {
            var item = items.findBy('hasDirtyAttributes', true);

            if (item) {
              item.save().then(function () {
                saveItemsRecursive(items);
              });
            }
          };

          saveItemsRecursive(quote.get('items'));
        });
      },

      issueItem: function issueItem(item) {
        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementIssueItemCommand['default']({
          itemId: item.get('id'),
          issuedOn: window.prompt('Angebotsposten gestellt am', (0, _moment['default'])().format('YYYY-MM-DD'))
        });

        store.submit(command).then(function () {
          item.reload();
        });
      },

      awardItem: function awardItem(item) {
        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementAwardItemCommand['default']({
          itemId: item.get('id'),
          awardedOn: window.prompt('Angebotsposten erteilt am', (0, _moment['default'])().format('YYYY-MM-DD'))
        });

        store.submit(command).then(function () {
          item.reload();
        });
      },

      enterItem: function enterItem(item) {
        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementEnterItemCommand['default']({
          itemId: item.get('id'),
          enteredOn: window.prompt('Angebotsposten angenommen am', (0, _moment['default'])().format('YYYY-MM-DD'))
        });

        store.submit(command).then(function () {
          item.reload();
        });
      },

      cancelItem: function cancelItem(item) {
        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementCancelItemCommand['default']({
          itemId: item.get('id'),
          canceledOn: window.prompt('Angebotsposten storniert am', (0, _moment['default'])().format('YYYY-MM-DD')),
          reason: window.prompt('Begründung')
        });

        store.submit(command).then(function () {
          item.reload();
        });
      }
    }
  });
});