define('tedian/controllers/project-management/project/ticket/assignments', ['exports', 'ember', 'tedian/commands/project-management/assign-ticket-to-member-command', 'tedian/commands/project-management/detract-ticket-from-member-command'], function (exports, _ember, _tedianCommandsProjectManagementAssignTicketToMemberCommand, _tedianCommandsProjectManagementDetractTicketFromMemberCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    ticket: _ember['default'].computed.alias('model.ticket'),
    members: _ember['default'].computed.alias('model.members'),

    membersByAvailability: _ember['default'].computed('members.[]', 'ticket.assignees.[]', function () {
      var _this = this;

      return this.get('members').map(function (member) {
        return _ember['default'].ObjectProxy.create({
          content: member,
          isAssigned: _this.get('ticket.assignees').contains(member)
        });
      });
    }),

    actions: {
      assignMember: function assignMember(member) {
        var command = new _tedianCommandsProjectManagementAssignTicketToMemberCommand['default']({
          ticketId: this.get('ticket.id'),
          expectedAggregateVersion: this.get('ticket.aggregateVersion'),
          memberId: member.get('id')
        });

        this.get('commandStore').submit(command);
      },
      detractMember: function detractMember(member) {
        var command = new _tedianCommandsProjectManagementDetractTicketFromMemberCommand['default']({
          ticketId: this.get('ticket.id'),
          expectedAggregateVersion: this.get('ticket.aggregateVersion'),
          memberId: member.get('id')
        });

        this.get('commandStore').submit(command);
      }
    }
  });
});