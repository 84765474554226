define('tedian/initializers/moment-duration-formatting', ['exports', 'moment', 'npm:moment-duration-format'], function (exports, _moment, _npmMomentDurationFormat) {
  exports.initialize = initialize;

  function initialize() {
    var locale = _moment['default'].locale();

    // Extends `moment.duration` with `.format(…)`
    (0, _npmMomentDurationFormat['default'])(_moment['default']);

    // Reset locale as plugin seems to set it to 'en'
    _moment['default'].locale(locale);
  }

  exports['default'] = {
    name: 'moment-duration-formatting',
    initialize: initialize
  };
});