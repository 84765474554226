define('tedian/controllers/project-management/project', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    unplannedTickets: _ember['default'].computed.filterBy('model.tickets', 'state', 'unplanned'),
    plannedTickets: _ember['default'].computed.filterBy('model.tickets', 'state', 'planned'),
    startedTickets: _ember['default'].computed.filterBy('model.tickets', 'state', 'started'),
    finishedTickets: _ember['default'].computed.filterBy('model.tickets', 'state', 'finished'),
    router: _ember['default'].inject.service('-routing'),

    isChildRouteActive: _ember['default'].computed('router.currentRouteName', 'parentRoute', function () {
      return ![this.get('parentRoute'), this.get('parentRoute') + '.index'].contains(this.get('router.currentRouteName'));
    })
  });
});