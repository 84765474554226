define('tedian/controllers/invoicing/show', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    invoiceItemsSorting: ['orderIndex:asc'],
    sortedInvoiceItems: _ember['default'].computed.sort('model.invoiceItems', 'invoiceItemsSorting'),
    paymentsSorting: ['receivedOn:asc'],
    sortedPayments: _ember['default'].computed.sort('model.payments', 'paymentsSorting'),

    scheduledPayments: _ember['default'].computed('model.outstandingBalance', 'model.total', 'model.paymentSchedule', function () {
      var schedule = this.get('model.paymentSchedule');
      var paidAmount = this.get('model.total') - this.get('model.outstandingBalance');
      var settledPayments = schedule.settledPayments(paidAmount);
      var unsettledPayments = schedule.unsettledPayments(paidAmount);
      var payments = settledPayments.concat(unsettledPayments);
      var isSinglePayment = payments.get('length') === 1;

      return payments.map(function (payment, index) {
        return _ember['default'].ObjectProxy.create({
          content: payment,
          isOverdue: (0, _moment['default'])(payment.get('dueOn')) < (0, _moment['default'])() && unsettledPayments.includes(payment),
          description: isSinglePayment ? 'Gesamtbetrag' : index + 1 + '. Teilzahlung',
          amount: parseFloat(payment.get('amount'))
        });
      });
    })
  });
});