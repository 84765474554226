define('tedian/controllers/time-tracking/tags/index', ['exports', 'ember', 'tedian/models/time-tracking-tag', 'tedian/commands/time-tracking/lock-tag-command', 'tedian/commands/time-tracking/unlock-tag-command'], function (exports, _ember, _tedianModelsTimeTrackingTag, _tedianCommandsTimeTrackingLockTagCommand, _tedianCommandsTimeTrackingUnlockTagCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),
    queryParams: ['locked', 'type'],

    locked: false,
    type: undefined,
    typeSortPriority: ['client', 'contract', 'contract-handling', 'contract-item', 'internal', 'user', 'attendance'],

    usageScopes: _ember['default'].computed(function () {
      return [_ember['default'].Object.create({ name: 'In Verwendung', parameters: { locked: false } }), _ember['default'].Object.create({ name: 'Gesperrt', parameters: { locked: true } })];
    }),

    typeScopes: _ember['default'].computed('model.tags.@each.type', function () {
      var types = this.get('model.tags').mapBy('type').uniq();
      var typeSortPriority = this.get('typeSortPriority');
      var options = types.map(function (type) {
        return _ember['default'].Object.create({
          id: type,
          name: (0, _tedianModelsTimeTrackingTag.getLabelForTagType)(type) || 'Ohne Typ',
          parameters: { type: type || '' }
        });
      }).sort(function (a, b) {
        return typeSortPriority.indexOf(a.get('id')) - typeSortPriority.indexOf(b.get('id'));
      });

      return [_ember['default'].Object.create({
        id: 'all',
        name: 'Alle',
        parameters: { type: undefined }
      })].concat(options);
    }),

    tags: _ember['default'].computed('model.tags.[]', 'type', function () {
      var type = this.get('type');

      var tags = this.get('model.tags').filter(function (tag) {
        if (_ember['default'].isNone(type)) {
          return true;
        } else if (type === '') {
          return tag.get('type') === null;
        } else {
          return tag.get('type') === type;
        }
      }).map(function (tag) {
        return _ember['default'].ObjectProxy.create({
          content: tag,
          isEditable: [null, 'internal'].includes(tag.get('type')),
          isLockable: !['attendance'].includes(tag.get('type')) && !tag.get('isLocked'),
          isUnlockable: !['attendance'].includes(tag.get('type')) && tag.get('isLocked')
        });
      });

      return tags.sort(function (a, b) {
        var aValue = (a.get('type') + ' ' + a.get('name')).toLowerCase();
        var bValue = (b.get('type') + ' ' + b.get('name')).toLowerCase();

        if (aValue > bValue) {
          return 1;
        } else if (aValue < bValue) {
          return -1;
        } else {
          return 0;
        }
      });
    }),

    actions: {
      lockTag: function lockTag(tagId) {
        var _this = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsTimeTrackingLockTagCommand['default']({
          tagId: tagId
        });

        store.submit(command).then(function () {
          _this.send('refreshModel');
        });
      },

      unlockTag: function unlockTag(tagId) {
        var _this2 = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsTimeTrackingUnlockTagCommand['default']({
          tagId: tagId
        });

        store.submit(command).then(function () {
          _this2.send('refreshModel');
        });
      }
    }
  });
});