define('tedian/routes/procurement/new-quote', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return new _ember['default'].RSVP.hash({
        organisations: this.store.findAll('organisation')
      });
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('title', null);
      controller.set('requestedOn', (0, _moment['default'])().format('YYYY-MM-DD'));
      controller.set('organisation', null);
      controller.set('notes', null);
    }
  });
});