define('tedian/routes/clients/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.createRecord('organisation', {
        contactPerson: this.store.createRecord('contact-person')
      });
    },

    actions: {
      createOrganisation: function createOrganisation() {
        var _this = this;

        var model = this.modelFor('clients.new');

        model.save().then(function () {
          return model.get('contactPerson').then(function (contactPerson) {
            return contactPerson.save();
          });
        }).then(function () {
          _this.transitionTo('clients');
        });
      },

      willTransition: function willTransition() {
        var organisation = this.modelFor('clients.new');
        var contactPerson = organisation.get('contactPerson');

        if (organisation.get('isNew')) {
          organisation.deleteRecord();
        }
        if (contactPerson.get('isNew')) {
          contactPerson.then(function (contactPerson) {
            contactPerson.deleteRecord();
          });
        }

        return true;
      }
    }
  });
});