define('tedian/components/time-tracking-archive-header', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    currentLevel: undefined,
    levels: undefined,

    currentScope: _ember['default'].computed('currentLevel.scopes.current', function () {
      return this.get('currentLevel.scopes.current');
    }),

    nextScope: _ember['default'].computed('currentLevel.scopes.next', function () {
      return this.get('currentLevel.scopes.next');
    }),

    previousScope: _ember['default'].computed('currentLevel.scopes.previous', function () {
      return this.get('currentLevel.scopes.previous');
    }),

    currentLevelRoute: _ember['default'].computed('currentLevel.type', 'levels', function () {
      var type = this.get('currentLevel.type');

      return this.get('levels.' + type + '.routeName');
    }),

    currentLevelRouteParameterFormat: _ember['default'].computed('currentLevel.type', 'levels', function () {
      var type = this.get('currentLevel.type');

      return this.get('levels.' + type + '.routeParameterFormat');
    }),

    availableLevels: _ember['default'].computed('levels', function () {
      var levels = this.get('levels') || {};

      return Object.keys(levels).map(function (key) {
        return levels[key];
      });
    })
  });
});