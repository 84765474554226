define('tedian/controllers/time-tracking/interval-edit', ['exports', 'ember', 'moment', 'tedian/commands/time-tracking/change-interval-command', 'tedian/commands/time-tracking/delete-interval-command', 'tedian/helpers/date-to-iso8601'], function (exports, _ember, _moment, _tedianCommandsTimeTrackingChangeIntervalCommand, _tedianCommandsTimeTrackingDeleteIntervalCommand, _tedianHelpersDateToIso8601) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),
    formModel: null,

    actions: {
      save: function save(formModel) {
        var _this = this;

        var store = this.get('commandStore');
        var intervalId = formModel.get('intervalId');
        var comment = formModel.get('comment');
        var tagIds = formModel.get('selectedTags').mapBy('id');
        var startedAt = formModel.get('startedAt');
        var stoppedAt = formModel.get('stoppedAt');
        var command = new _tedianCommandsTimeTrackingChangeIntervalCommand['default']({
          intervalId: intervalId,
          comment: comment,
          tagIds: tagIds,
          startedAt: (0, _tedianHelpersDateToIso8601['default'])(startedAt),
          stoppedAt: (0, _tedianHelpersDateToIso8601['default'])(stoppedAt)
        });

        store.submit(command).then(function () {
          _this.transitionToRoute('time-tracking.archive.date', (0, _moment['default'])(startedAt).format('YYYY-MM-DD'));
        });
      },

      'delete': function _delete(formModel) {
        var _this2 = this;

        var store = this.get('commandStore');
        var intervalId = formModel.get('intervalId');
        var startedAt = formModel.get('startedAt');
        var command = new _tedianCommandsTimeTrackingDeleteIntervalCommand['default']({
          intervalId: intervalId
        });

        store.submit(command).then(function () {
          _this2.transitionToRoute('time-tracking.archive.date', (0, _moment['default'])(startedAt).format('YYYY-MM-DD'));
        });
      }
    }
  });
});