define('tedian/routes/invoicing/drafts/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        draft: this.store.createRecord('invoice-draft'),
        organisations: this.store.query('organisation', { sort: 'name', include: 'contact-person' })
      });
    },

    actions: {
      createInvoiceDraft: function createInvoiceDraft() {
        var _this = this;

        var draft = this.modelFor('invoicing.drafts.new').draft;

        draft.save().then(function () {
          return draft.get('invoiceItemDrafts');
        }).then(function (items) {
          return items.save();
        }).then(function () {
          _this.transitionTo('invoicing.drafts.index');
        });
      },

      willTransition: function willTransition() {
        var draft = this.modelFor('invoicing.drafts.new').draft;

        if (draft.get('isNew')) {
          draft.deleteRecord();
        }

        draft.get('invoiceItemDrafts').then(function (items) {
          items.forEach(function (item) {
            if (item.get('isNew')) {
              item.deleteRecord();
            }
          });
        });

        return true;
      }
    }
  });
});