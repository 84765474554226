define('tedian/components/charts/cumulative-contract-time-goal', ['exports', 'ember', 'underscore', 'moment', 'tedian/helpers/format-number'], function (exports, _ember, _underscore, _moment, _tedianHelpersFormatNumber) {
  exports['default'] = _ember['default'].Component.extend({
    isLoading: undefined,
    reportRows: undefined,
    year: undefined,
    timeGoal: undefined,

    store: _ember['default'].inject.service('store'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('isLoading', true);

      return _ember['default'].$.ajax({
        url: this.get('store').adapterFor('application').buildURL('time-tracking-report'),
        type: 'GET',
        data: {
          dimension: 'contract',
          aggregate: 'day',
          interval: this.get('year') + '-01-01--' + this.get('year') + '-12-31'
        }
      }).then(function (result) {
        _this.setProperties({
          reportRows: result,
          isLoading: false
        });
      });
    },

    chartOptions: _ember['default'].computed('year', function () {
      var year = this.get('year');
      var plotLines = [];

      if (year === (0, _moment['default'])().year()) {
        plotLines.push({
          label: {
            text: 'Heute',
            style: {
              color: '#AAAAAA'
            }
          },
          value: (0, _moment['default'])().valueOf(),
          width: 2,
          color: '#AAAAAA',
          zIndex: 1
        });
      }

      return {
        chart: {
          style: {
            fontFamily: 'inherit',
            fontSize: 'inherit'
          },
          backgroundColor: 'transparent',
          spacing: [20, 0, 20, 0]
        },
        legend: {
          enabled: false
        },
        title: false,
        xAxis: {
          title: {
            enabled: false
          },
          plotLines: plotLines,
          type: 'datetime',
          tickPositions: [(0, _moment['default'])([year, 0, 1]).valueOf(), (0, _moment['default'])([year, 1, 1]).valueOf(), (0, _moment['default'])([year, 2, 1]).valueOf(), (0, _moment['default'])([year, 3, 1]).valueOf(), (0, _moment['default'])([year, 4, 1]).valueOf(), (0, _moment['default'])([year, 5, 1]).valueOf(), (0, _moment['default'])([year, 6, 1]).valueOf(), (0, _moment['default'])([year, 7, 1]).valueOf(), (0, _moment['default'])([year, 8, 1]).valueOf(), (0, _moment['default'])([year, 9, 1]).valueOf(), (0, _moment['default'])([year, 10, 1]).valueOf(), (0, _moment['default'])([year, 11, 1]).valueOf(), (0, _moment['default'])([year, 11, 31]).valueOf()],
          labels: {
            formatter: function formatter() {
              return (0, _moment['default'])(this.value).format('DD.MM.');
            }
          }
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            formatter: function formatter() {
              return this.axis.defaultLabelFormatter.call(this) + ' h';
            }
          }
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          borderWidth: 1,
          formatter: function formatter() {
            var pointLines = this.points.map(function (point) {
              return '<span style="color:' + point.color + '">●</span> ' + point.series.name + ': <strong>' + (0, _tedianHelpersFormatNumber.formatNumber)([point.y], { precision: 2 }) + ' h</strong>';
            });

            return ['<span>' + (0, _moment['default'])(this.x).format('DD. MMMM YYYY') + '</span>'].concat(pointLines).join('<br>');
          }
        },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: false
              }
            },
            marker: {
              enabled: false
            }
          }
        }
      };
    }),

    chartData: _ember['default'].computed('year', 'timeGoal', 'reportRows.[]', function () {
      var timeGoal = this.get('timeGoal');
      var year = this.get('year');
      var itemsPerDate = _underscore['default'].groupBy(this.get('reportRows'), 'aggregation_value');
      var secondsByDate = _underscore['default'].keys(itemsPerDate).map(function (date) {
        return {
          aggregation_value: date,
          seconds: itemsPerDate[date].reduce(function (sum, item) {
            return sum + item.seconds;
          }, 0)
        };
      }).sort(function (a, b) {
        return (0, _moment['default'])(a.aggregation_value).isAfter((0, _moment['default'])(b.aggregation_value), 'day') ? 1 : -1;
      });

      var sum = 0;
      var cumulativeDates = _underscore['default'].map(secondsByDate, function (item) {
        sum += item.seconds;

        return {
          date: (0, _moment['default'])(item.aggregation_value),
          amount: sum
        };
      });

      var firstDayOfYear = (0, _moment['default'])([year, 0, 1]);
      var lastDayOfYear = (0, _moment['default'])([year, 11, 31]);

      var lineSeriesData = this._buildTargetTimeData(cumulativeDates, firstDayOfYear, lastDayOfYear, timeGoal);
      var areaSeriesData = this._buildActualTimeData(cumulativeDates, firstDayOfYear, lastDayOfYear);

      return [{
        type: 'line',
        name: 'Soll',
        color: '#3D9970',
        data: lineSeriesData
      }, {
        type: 'area',
        name: 'Ist',
        color: 'rgba(61, 153, 112, 0.4)',
        fillColor: 'rgba(61, 153, 112, 0.3)',
        lineWidth: 0,
        step: 'center',
        data: areaSeriesData
      }];
    }),

    _buildTargetTimeData: function _buildTargetTimeData(cumulativeDates, firstDayOfYear, lastDayOfYear, timeGoal) {
      var lineSeriesDataForDaysWithTime = _underscore['default'].map(cumulativeDates, function (item) {
        var date = item.date;
        var dayOfYear = date.dayOfYear();

        return {
          x: date.valueOf(),
          y: dayOfYear / 365 * timeGoal
        };
      });

      var lineSeriesDataForFirstDaysOfMonth = _underscore['default'].map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], function (month) {
        var firstDayOfMonth = firstDayOfYear.clone().add(month, 'months').startOf('month');
        var dayOfYear = firstDayOfMonth.dayOfYear();

        return {
          x: firstDayOfMonth.valueOf(),
          y: dayOfYear / 365 * timeGoal
        };
      });

      var lineSeriesDataForLastDayOfYear = [{
        x: lastDayOfYear.valueOf(),
        y: timeGoal
      }];

      return _underscore['default'].uniq([].concat(lineSeriesDataForDaysWithTime).concat(lineSeriesDataForFirstDaysOfMonth).concat(lineSeriesDataForLastDayOfYear)).sort(function (a, b) {
        return (0, _moment['default'])(a.x).isAfter(b.x, 'day') ? 1 : -1;
      });
    },

    _buildActualTimeData: function _buildActualTimeData(cumulativeDates, firstDayOfYear, lastDayOfYear) {
      var areaSeriesDataForDaysWithTime = _underscore['default'].map(cumulativeDates, function (item) {
        return {
          x: item.date.valueOf(),
          y: item.amount / 60 / 60
        };
      });

      var areaSeriesDataForFirstDaysOfMonth = _underscore['default'].map([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], function (month) {
        var firstDayOfMonth = firstDayOfYear.clone().add(month, 'months').startOf('month');
        var matchingItem = _underscore['default'].find(cumulativeDates, function (item) {
          return (0, _moment['default'])(item.date).isSame(firstDayOfMonth, 'day');
        }) || _underscore['default'].find(cumulativeDates.sort(function (a, b) {
          return (0, _moment['default'])(a.date).isBefore(b.date, 'day') ? 1 : -1;
        }), function (item) {
          return (0, _moment['default'])(item.date).isBefore(firstDayOfMonth, 'day');
        });

        return {
          x: firstDayOfMonth.valueOf(),
          y: matchingItem ? matchingItem.amount / 60 / 60 : 0
        };
      });

      var areaSeriesDataForLastDayOfYear = [{
        x: lastDayOfYear.valueOf(),
        y: (areaSeriesDataForDaysWithTime.slice(-1)[0] || { x: 0, y: 0 }).y
      }];

      return _underscore['default'].uniq([].concat(areaSeriesDataForDaysWithTime).concat(areaSeriesDataForFirstDaysOfMonth).concat(areaSeriesDataForLastDayOfYear)).sort(function (a, b) {
        return (0, _moment['default'])(a.x).isAfter(b.x, 'day') ? 1 : -1;
      });
    }
  });
});