define('tedian/controllers/project-management/project/new-ticket', ['exports', 'ember', 'tedian/commands/project-management/file-ticket-command'], function (exports, _ember, _tedianCommandsProjectManagementFileTicketCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    projects: _ember['default'].computed.readOnly('model.projects'),
    tickets: _ember['default'].computed.readOnly('model.tickets'),

    unplannedTickets: _ember['default'].computed.filterBy('tickets', 'state', 'unplanned'),
    plannedTickets: _ember['default'].computed.filterBy('tickets', 'state', 'planned'),
    startedTickets: _ember['default'].computed.filterBy('tickets', 'state', 'started'),
    finishedTickets: _ember['default'].computed.filterBy('tickets', 'state', 'finished'),

    selectedProject: undefined,
    category: undefined,
    description: undefined,

    categoryOptions: [_ember['default'].Object.create({ value: 'default', label: 'Default' }), _ember['default'].Object.create({ value: 'feature', label: 'Funktion' }), _ember['default'].Object.create({ value: 'defect', label: 'Defekt' }), _ember['default'].Object.create({ value: 'internal', label: 'Intern' })],

    actions: {
      selectProject: function selectProject(project) {
        this.set('selectedProject', project);
      },

      submit: function submit() {
        var _this = this;

        var command = new _tedianCommandsProjectManagementFileTicketCommand['default']({
          projectId: this.get('selectedProject.id'),
          category: this.get('category'),
          description: this.get('description')
        });

        this.get('commandStore').submit(command).then(function () {
          _this.send('refreshModel');
          _this.transitionToRoute(_this.get('parentRoute'));
        });
      }
    }
  });
});