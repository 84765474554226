define('tedian/models/time-tracking-interval', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    comment: (0, _emberDataAttr['default'])(),
    startedAt: (0, _emberDataAttr['default'])('date'),
    stoppedAt: (0, _emberDataAttr['default'])('date'),
    timeTrackingTags: (0, _emberDataRelationships.hasMany)('time-tracking-tag'),

    duration: _ember['default'].computed('startedAt', 'stoppedAt', function () {
      return this.get('stoppedAt').getTime() - this.get('startedAt').getTime();
    })
  });
});