define('tedian/routes/time-tracking/archive/month', ['exports', 'ember', 'moment', 'tedian/value-objects/simple-report'], function (exports, _ember, _moment, _tedianValueObjectsSimpleReport) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    dutyRoster: _ember['default'].inject.service('duty-roster'),

    model: function model(parameters) {
      var month = (0, _moment['default'])(parameters.month);
      var currentUserId = this.get('session.currentUser.id');
      var filter = 'user:' + currentUserId;
      var interval = [month.clone().startOf('month').format('YYYY-MM-DD'), month.clone().endOf('month').format('YYYY-MM-DD')].join('--');

      return _ember['default'].RSVP.hash({
        month: parameters.month,
        attendanceByDayReport: this.requestReport('attendance', filter, interval, 'day'),
        internalTagsReport: this.requestReport('internal', filter, interval, 'month'),
        contractTagsReport: this.requestReport('contract', filter, interval, 'month'),
        contractHandlingTagsReport: this.requestReport('contract-handling', filter, interval, 'month'),
        scheduleByDay: this.get('dutyRoster').query({
          interval: interval,
          userId: currentUserId,
          aggregate: 'day'
        })
      });
    },

    requestReport: function requestReport(dimension, filter, interval, aggregate) {
      var _this = this;

      return _ember['default'].$.ajax({
        url: this.store.adapterFor('application').buildURL('time-tracking-report'),
        type: 'GET',
        data: {
          dimension: dimension,
          filter: filter,
          interval: interval,
          aggregate: aggregate
        }
      }).then(function (response) {
        response.forEach(function (row) {
          row.tag = _this.store.peekRecord('time-tracking-tag', row.dimension_tag_id);
        });

        return _tedianValueObjectsSimpleReport['default'].fromRows(response);
      });
    }
  });
});