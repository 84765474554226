define('tedian/routes/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var HttpStatus = {
    Ok: 200,
    Created: 201,
    NoContent: 204,
    BadRequest: 400,
    Unauthorized: 401,
    Forbidden: 403,
    NotFound: 404,
    Conflict: 409,
    InternalServerError: 500,
    Maintenance: 503
  };

  var matchesStatus = function matchesStatus(error, status) {
    return 'errors' in error && parseInt(error.errors[0].status, 10) === status;
  };

  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),

    beforeModel: function beforeModel() {
      return this.get('session').waitOnSetup();
    },

    activate: function activate() {
      var _this = this;

      var onerrorBackup = _ember['default'].onerror;

      // Use Ember.onerror to globally catch all errors
      _ember['default'].onerror = function (error) {
        _this._handleError(error, null, onerrorBackup);
      };
    },

    actions: {
      retryTransitionAfterSignIn: function retryTransitionAfterSignIn() {
        var attemptedTransition = this.get('session').get('attemptedTransition');

        if (attemptedTransition) {
          this.get('session').set('attemptedTransition', null);
          attemptedTransition.retry();
        } else {
          this.transitionTo('time-tracking');
        }
      },

      // Use 'error' action to catch errors from
      // within model hooks (beforeModel, model, afterModel)
      error: function error(_error2, transition) {
        return !this._handleError(_error2, transition);
      },

      renderDefaultError: function renderDefaultError() {
        this.render('error', {
          into: 'application',
          outlet: 'main'
        });
      },

      renderForbiddenError: function renderForbiddenError() {
        this.render('error-forbidden', {
          into: 'application',
          outlet: 'main'
        });
      }
    },

    _handleError: function _handleError(error, transition, callback) {
      var _this2 = this;

      var wasErrorHandled = false;
      var errorPageAction = 'renderDefaultError';

      try {
        if (error instanceof _emberData['default'].AdapterError && matchesStatus(error, HttpStatus.Unauthorized)) {
          this.get('session').set('attemptedTransition', transition);
          this.transitionTo('session.new');
          errorPageAction = null;
          wasErrorHandled = true;
        } else if (error instanceof _emberData['default'].AdapterError && matchesStatus(error, HttpStatus.Forbidden)) {
          errorPageAction = 'renderForbiddenError';
          wasErrorHandled = true;
        } else if (this._isKnownErrorWithNoNeedForErrorPage(error)) {
          errorPageAction = null;
        }
      } catch (_error) {
        _ember['default'].Logger.error('Error while handling errors:', _error.stack);
        wasErrorHandled = false;
      }

      if (wasErrorHandled) {
        _ember['default'].Logger.debug('Handled error:', error);
      } else {
        if (typeof callback === 'function') {
          callback.call(_ember['default'], error);
        } else {
          _ember['default'].Logger.error(error.stack);
        }
      }

      if (errorPageAction) {
        // Render into outlet instead of transitioning to another page
        // to maintain URL which is useful information when remote-logging error
        _ember['default'].run.later(function () {
          return _this2.send(errorPageAction);
        });
      }

      // Allow error to bubble
      return wasErrorHandled;
    },

    _isKnownErrorWithNoNeedForErrorPage: function _isKnownErrorWithNoNeedForErrorPage(error) {
      // Ember 2.X seems to not catch `TransitionAborted` errors caused by
      // regular redirects. We don't want these errors to cause error pages as
      // they have no functional effect on the application.
      //
      // Once this issue https://github.com/emberjs/ember.js/issues/12505 is
      // resolved we can remove this code.
      return 'name' in error && error.name === 'TransitionAborted';
    }
  });
});