define('tedian/components/tag-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'form',
    model: null,

    onSubmit: _ember['default'].K,
    isPersisted: _ember['default'].computed.notEmpty('model.tagId'),

    temporaryTag: _ember['default'].computed('model.name', 'model.slug', function () {
      var typeMatch = this.get('model.slug').match(/(.+):.+/);

      return _ember['default'].Object.create({
        name: this.get('model.name'),
        type: typeMatch ? typeMatch[1] : undefined
      });
    }),

    sanitizedSlug: _ember['default'].computed('model.slug', function () {
      return this.get('model.slug').dasherize();
    }),

    actions: {
      submit: function submit() {
        var model = this.get('model');
        model.set('slug', this.get('sanitizedSlug'));

        this.get('onSubmit')(model);
        return false;
      }
    }
  });
});