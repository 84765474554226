define('tedian/app', ['exports', 'ember', 'tedian/resolver', 'ember-load-initializers', 'tedian/config/environment', 'tedian/sentry'], function (exports, _ember, _tedianResolver, _emberLoadInitializers, _tedianConfigEnvironment, _tedianSentry) {

  (0, _tedianSentry.startSentry)();

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _tedianConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _tedianConfigEnvironment['default'].podModulePrefix,
    Resolver: _tedianResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _tedianConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});