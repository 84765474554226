define('tedian/models/human-resources-paid-leave', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _ember) {
  var leaveTypes = [_ember['default'].Object.create({
    id: 'holiday',
    label: 'Urlaub'
  }), _ember['default'].Object.create({
    id: 'sick',
    label: 'Krankenstand'
  }), _ember['default'].Object.create({
    id: 'compensation',
    label: 'Zeitausgleich'
  })];

  exports.leaveTypes = leaveTypes;
  exports['default'] = _emberDataModel['default'].extend({
    user: (0, _emberDataRelationships.belongsTo)('user'),
    leaveType: (0, _emberDataAttr['default'])(),
    on: (0, _emberDataAttr['default'])(),
    hours: (0, _emberDataAttr['default'])(),

    leaveTypeLabel: _ember['default'].computed('leaveType', function () {
      var _this = this;

      var leaveType = leaveTypes.find(function (type) {
        return type.get('id') === _this.get('leaveType');
      });

      if (leaveType) {
        return leaveType.get('label');
      } else {
        throw new Error('Unknown leave type \'' + this.get('leaveType') + '\'.');
      }
    })
  });
});