define('tedian/controllers/procurement/complete-contract', ['exports', 'ember', 'tedian/commands/procurement/complete-contract-command'], function (exports, _ember, _tedianCommandsProcurementCompleteContractCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    actions: {
      submit: function submit() {
        var _this = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementCompleteContractCommand['default']({
          contractId: this.get('model.quote.id'),
          completedOn: this.get('contractCompletedOn')
        });

        store.submit(command).then(function () {
          _this.transitionToRoute('procurement.contracts');
        });
      }
    }
  });
});