define('tedian/routes/time-tracking/tags/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      locked: { refreshModel: true }
    },

    model: function model(parameters) {
      var filter = {};

      if (parameters.hasOwnProperty('locked')) {
        filter['is-locked'] = parameters.locked;
      }

      return _ember['default'].RSVP.hash({
        tags: this.get('store').query('time-tracking-tag', { filter: filter })
      });
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});