define('tedian/services/metronome', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    intervalID: null,
    beat: 0,

    init: function init() {
      this._super.apply(this, arguments);
      this.start();
    },

    start: function start() {
      var _this = this;

      this.set('intervalID', window.setInterval(function () {
        _this.update();
      }, 1000));
    },

    stop: function stop() {
      window.clearInterval(this.get('intervalID'));
    },

    update: function update() {
      this.incrementProperty('beat');
    }
  });
});