define('tedian/initializers/accounting', ['exports', 'accounting/settings'], function (exports, _accountingSettings) {
  exports.initialize = initialize;

  function initialize() {
    _accountingSettings.currency.symbol = '€';
    _accountingSettings.currency.format = '%v %s';
    _accountingSettings.currency.decimal = ',';
    _accountingSettings.currency.thousand = '.';

    _accountingSettings.number.decimal = ',';
    _accountingSettings.number.thousand = '.';
  }

  exports['default'] = {
    name: 'accounting',
    initialize: initialize
  };
});