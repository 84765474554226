define('tedian/value-objects/date-scope', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var DateScope = _ember['default'].Object.extend({
    type: undefined,
    startsAt: undefined,
    endsAt: undefined,

    name: _ember['default'].computed('startsAt', function () {
      var type = this.get('type');

      if (type === 'day') {
        return (0, _moment['default'])(this.get('startsAt')).format('dddd, Do MMMM YYYY');
      } else if (type === 'week') {
        return (0, _moment['default'])(this.get('startsAt')).format('[KW] WW / YYYY');
      } else if (type === 'month') {
        return (0, _moment['default'])(this.get('startsAt')).format('MMMM YYYY');
      } else {
        return undefined;
      }
    }),

    workingDays: _ember['default'].computed('startsAt', 'endsAt', function () {
      var endsAt = (0, _moment['default'])(this.get('endsAt'));
      var startsAt = (0, _moment['default'])(this.get('startsAt'));
      var date = startsAt;
      var days = [];

      while (date <= endsAt) {
        if (![6, 7].includes(date.isoWeekday())) {
          days.push(date.toDate());
        }

        date.add(1, 'day');
      }

      return days;
    })
  });

  exports.DateScope = DateScope;
  DateScope.reopenClass({
    fromDate: function fromDate(date, type) {
      return this.create({
        type: type,
        startsAt: (0, _moment['default'])(date).startOf(type).toDate(),
        endsAt: (0, _moment['default'])(date).endOf(type).toDate()
      });
    }
  });

  exports['default'] = DateScope;
});