define('tedian/routes/invoicing/pending/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.query('invoice', {
        include: 'organisation,invoice-items',
        sort: 'billed-on,number',
        filter: { 'settled-on': 'never' }
      });
    }
  });
});