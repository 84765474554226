define('tedian/services/duty-roster', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var userKeySeparator = ':';

  function buildUserKey(userId, date) {
    if (typeof date === 'string') {
      return '' + userId + userKeySeparator + date;
    } else if (typeof date === 'object') {
      return '' + userId + userKeySeparator + (0, _moment['default'])(date).format('YYYY-MM-DD');
    }
  }

  function sortByDateStringDesc(collection) {
    return collection.sort(function (a, b) {
      return a < b ? 1 : -1;
    });
  }

  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service('store:main'),
    workSchedules: undefined,
    publicHolidays: undefined,
    paidLeaveDays: undefined,
    sickLeaveDays: undefined,
    scheduleByDateMap: {},
    publicHolidaysMap: {},
    paidLeaveDaysMap: {},
    sickLeaveDaysMap: {},
    compensationDaysMap: {},

    load: function load() {
      var _this = this;

      var promise = _ember['default'].RSVP.hash({
        workSchedules: this.get('store').query('human-resources-work-schedule', {}),
        publicHolidays: this.get('store').query('human-resources-public-holiday', {}),
        paidLeaveDays: this.get('store').query('human-resources-paid-leave', { filter: { type: 'holiday' } }),
        sickLeaveDays: this.get('store').query('human-resources-paid-leave', { filter: { type: 'sick' } }),
        compensationDays: this.get('store').query('human-resources-paid-leave', { filter: { type: 'compensation' } })
      }).then(function (hash) {
        _this.setProperties(hash);
        _this.buildAccessMapsByDate();

        return _this;
      });

      return promise;
    },

    // Following parameters are accepted with the corresponding syntax
    // interval: '2018-11-01--2018-11-30'
    // userId: '2'
    // aggregate: 'day', 'week', 'month', 'year'
    query: function query(parameters) {
      var _this2 = this;

      var userId = parameters.userId;
      delete parameters.userId;

      var aggregate = parameters.aggregate || 'day';

      var _parameters$interval$split = parameters.interval.split('--');

      var _parameters$interval$split2 = _slicedToArray(_parameters$interval$split, 2);

      var intervalStart = _parameters$interval$split2[0];
      var intervalEnd = _parameters$interval$split2[1];

      var from = _moment['default'].utc(intervalStart, 'YYYY-MM-DD');
      var until = _moment['default'].utc(intervalEnd, 'YYYY-MM-DD');
      var inUseSchedules = undefined,
          userIds = undefined,
          results = undefined;

      if (userId) {
        results = [this.queryForSinglePerson(userId, parameters)];
      } else {
        inUseSchedules = this.getWorkSchedulesWithin(from.toDate(), until.toDate());
        userIds = inUseSchedules.map(function (schedule) {
          return schedule.belongsTo('user').id();
        }).uniq();
        results = userIds.map(function (userId) {
          return _this2.queryForSinglePerson(userId, parameters);
        });
      }

      if (aggregate !== 'day') {
        results = results.map(function (group) {
          var firstGroupItem = group[0];
          var summary = group.reduce(function (collection, current) {
            var from = (0, _moment['default'])(current.get('from'));
            var latestCollectionItem = collection[collection.length - 1];

            if (!latestCollectionItem || !from.isSame(latestCollectionItem.get('from'), aggregate)) {
              collection.pushObject(_ember['default'].Object.create({
                userId: firstGroupItem.get('userId'),
                aggregate: aggregate,
                from: from.clone().startOf(aggregate).toDate(),
                until: from.clone().endOf(aggregate).toDate(),
                target: current.get('target'),
                numberOfHolidays: current.get('numberOfHolidays'),
                details: current.get('details')
              }));
            } else {
              latestCollectionItem.setProperties({
                target: latestCollectionItem.get('target') + current.get('target'),
                numberOfHolidays: latestCollectionItem.get('numberOfHolidays') + current.get('numberOfHolidays'),
                details: latestCollectionItem.get('details').concat(current.get('details'))
              });
            }

            return collection;
          }, []);

          // Make sure number of yearly holidays are rounded up
          // to the next whole number (integer) as there are no partial holidays
          summary.forEach(function (result) {
            if (result.get('aggregate') === 'year') {
              result.set('numberOfHolidays', Math.ceil(Math.round(result.get('numberOfHolidays') * 100) / 100));
            }
          });

          return summary;
        });
      }

      return results.reduce(function (collection, current) {
        return collection.concat(current);
      }, []);
    },

    queryForSinglePerson: function queryForSinglePerson(userId, parameters) {
      var _parameters$interval$split3 = parameters.interval.split('--');

      var _parameters$interval$split32 = _slicedToArray(_parameters$interval$split3, 2);

      var intervalStart = _parameters$interval$split32[0];
      var intervalEnd = _parameters$interval$split32[1];

      var from = _moment['default'].utc(intervalStart, 'YYYY-MM-DD');
      var until = _moment['default'].utc(intervalEnd, 'YYYY-MM-DD');
      var current = from.clone();
      var results = [];

      this.buildScheduleByDateMap(userId, [from, until]);

      function getHoursOffDuty(details, target) {
        // When there are no hours specified for an off duty day
        // use the target hours as fallback to cover the hole day
        var hours = details.reduce(function (sum, current) {
          return sum + (current.get('hours') === null ? target : current.get('hours'));
        }, 0);

        return Math.min(target, hours);
      }

      while (current.isSameOrBefore(until, 'day')) {
        var target = this.getTarget(current.toDate(), userId);
        var details = this.getDetails(current.toDate(), userId);
        var numberOfHolidays = this.getNumberOfHolidays(current.toDate(), userId);
        var hoursOffDuty = getHoursOffDuty(details, target);

        if (target !== null) {
          results.pushObject(_ember['default'].Object.create({
            userId: userId,
            aggregate: 'day',
            from: current.clone().startOf('day').toDate(),
            until: current.clone().endOf('day').toDate(),
            target: Math.max(0, target - hoursOffDuty),
            numberOfHolidays: numberOfHolidays,
            details: details
          }));
        }

        current.add(1, 'day');
      }

      return results;
    },

    getDetails: function getDetails(date, userId) {
      var userKey = buildUserKey(userId, date);
      var dateKey = (0, _moment['default'])(date).format('YYYY-MM-DD');
      var publicHoliday = this.get('publicHolidaysMap')[dateKey];
      var paidLeaveDay = this.get('paidLeaveDaysMap')[userKey];
      var sickLeaveDay = this.get('sickLeaveDaysMap')[userKey];
      var compensationDay = this.get('compensationDaysMap')[userKey];
      var target = this.getTarget(date, userId);
      var details = [];
      var percentage = 0;
      var hours = target;

      function getProportionateDuration(hours, target) {
        return hours ? Math.min(1, hours / target) : 1;
      }

      function formatDuration(days) {
        return days < 1 ? days.toFixed(1) + ' Tage' : 'ganztägig';
      }

      if (!target) {
        return details;
      }

      if (publicHoliday !== undefined) {
        percentage = publicHoliday.get('percentage') || 1;
        hours = percentage * target;
        details.pushObject(_ember['default'].Object.create({
          type: 'publicHoliday',
          date: date,
          name: 'Feiertag: ' + publicHoliday.get('name') + ' (' + formatDuration(percentage) + ')',
          hours: hours,
          percentage: percentage
        }));
      }

      if (paidLeaveDay !== undefined) {
        hours = paidLeaveDay.get('hours') === null ? target : paidLeaveDay.get('hours');
        percentage = getProportionateDuration(hours, target);
        details.pushObject(_ember['default'].Object.create({
          type: 'paidLeaveDay',
          date: date,
          name: 'Urlaub (' + formatDuration(percentage) + ')',
          hours: hours,
          percentage: percentage
        }));
      }

      if (sickLeaveDay !== undefined) {
        hours = sickLeaveDay.get('hours') === null ? target : sickLeaveDay.get('hours');
        percentage = getProportionateDuration(hours, target);
        details.pushObject(_ember['default'].Object.create({
          type: 'sickLeaveDay',
          date: date,
          name: 'Krankenstand (' + formatDuration(percentage) + ')',
          hours: hours,
          percentage: percentage
        }));
      }

      if (compensationDay !== undefined) {
        hours = compensationDay.get('hours') === null ? target : compensationDay.get('hours');
        percentage = getProportionateDuration(hours, target);
        details.pushObject(_ember['default'].Object.create({
          type: 'compensationDay',
          date: date,
          name: 'Zeitausgleich (' + hours.toFixed(2) + 'h)',
          hours: hours,
          percentage: percentage
        }));
      }

      return details;
    },

    getTarget: function getTarget(date, userId) {
      var key = buildUserKey(userId, date);
      var scheduleByDateMap = this.get('scheduleByDateMap');

      if (key in scheduleByDateMap) {
        return scheduleByDateMap[key].target;
      } else {
        return null;
      }
    },

    getNumberOfHolidays: function getNumberOfHolidays(date, userId) {
      var key = buildUserKey(userId, date);
      var scheduleByDateMap = this.get('scheduleByDateMap');

      if (key in scheduleByDateMap) {
        return scheduleByDateMap[key].numberOfHolidays;
      } else {
        return null;
      }
    },

    getWorkSchedulesWithin: function getWorkSchedulesWithin(from, until) {
      return this.get('workSchedules').filter(function (schedule) {
        var takesEffectOn = (0, _moment['default'])(schedule.get('takesEffectOn'));
        var endsOn = (0, _moment['default'])(schedule.get('endsOn'));
        var hasTakenEffect = takesEffectOn.isSameOrBefore(until, 'day');
        var hasExpired = endsOn.isBefore(from, 'day');

        return hasTakenEffect && !hasExpired;
      });
    },

    getPublicHolidaysWithin: function getPublicHolidaysWithin(from, until) {
      return this.get('publicHolidays').filter(function (day) {
        var date = (0, _moment['default'])(day.get('on'));
        return date.isSameOrAfter(from, 'day') && date.isSameOrBefore(until, 'day');
      }).map(function (day) {
        return _ember['default'].Object.create({
          on: day.get('on'),
          name: day.get('name'),
          percentage: day.get('percentage') || 1
        });
      });
    },

    buildScheduleByDateMap: function buildScheduleByDateMap(userId, interval) {
      var from = interval[0];
      var until = interval[1];
      var scheduleByDateMap = this.get('scheduleByDateMap');

      // Assume the map has been built
      // if first and last date of interval exist
      if (buildUserKey(userId, from) in scheduleByDateMap && buildUserKey(userId, until) in scheduleByDateMap) {
        return;
      }

      var schedules = this.get('workSchedules').sortBy('takesEffectOn').filter(function (schedule) {
        if (schedule.belongsTo('user').id() !== userId) {
          return false;
        }

        var takesEffectOn = (0, _moment['default'])(schedule.get('takesEffectOn'));
        var endsOn = (0, _moment['default'])(schedule.get('endsOn'));
        var hasTakenEffect = takesEffectOn.isSameOrBefore(until, 'day');
        var hasExpired = endsOn.isValid() && endsOn.isBefore(from, 'day');

        return hasTakenEffect && !hasExpired;
      });

      var additionalMap = schedules.reduce(function (map, schedule) {
        var hoursByDay = schedule.get('hoursByDay');
        var lowerLimit = sortByDateStringDesc([schedule.get('takesEffectOn'), from.format('YYYY-MM-DD')])[0];
        var upperLimit = schedule.get('endsOn') || until.format('YYYY-MM-DD');
        var numberOfDaysInYearMap = {};
        var current = (0, _moment['default'])(lowerLimit);
        var numberOfDaysInYear = undefined;

        while (current.isBetween(lowerLimit, upperLimit, undefined, '[]')) {
          if (!(current.year() in numberOfDaysInYearMap)) {
            numberOfDaysInYearMap[current.year()] = Math.abs(_moment['default'].duration(current.clone().startOf('year').diff(current.clone().add(1, 'year').startOf('year'))).asDays());
          }
          numberOfDaysInYear = numberOfDaysInYearMap[current.year()];

          map[buildUserKey(userId, current)] = {
            target: hoursByDay[current.weekday()] || 0,
            numberOfHolidays: 1 / numberOfDaysInYear * schedule.get('numberOfHolidaysPerYear')
          };
          current.add(1, 'day');
        }

        return map;
      }, {});

      this.set('scheduleByDateMap', Object.assign(scheduleByDateMap, additionalMap));
    },

    buildAccessMapsByDate: function buildAccessMapsByDate() {
      var _this3 = this;

      this.set('publicHolidaysMap', this.get('publicHolidays').reduce(function (map, day) {
        map[day.get('on')] = day;
        return map;
      }, {}));

      this.set('paidLeaveDaysMap', this.get('paidLeaveDays').reduce(function (map, day) {
        map[buildUserKey(day.belongsTo('user').id(), day.get('on'))] = day;
        return map;
      }, {}));

      this.set('sickLeaveDaysMap', this.get('sickLeaveDays').reduce(function (map, day) {
        map[buildUserKey(day.belongsTo('user').id(), day.get('on'))] = day;
        return map;
      }, {}));

      this.set('compensationDaysMap', this.get('compensationDays').reduce(function (map, day) {
        map[buildUserKey(day.belongsTo('user').id(), day.get('on'))] = day;
        return map;
      }, {}));

      // Prepare daily targets for in-use work schedules
      // for upcoming 12 months for better performance when making predictions
      this.get('workSchedules').filter(function (schedule) {
        return !schedule.get('endsOn');
      }).forEach(function (schedule) {
        _this3.buildScheduleByDateMap(schedule.belongsTo('user').id(), [(0, _moment['default'])(), (0, _moment['default'])().add(12, 'months')]);
      });
    }
  });
});