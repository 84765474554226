define('tedian/risk-management/functions', ['exports', 'underscore'], function (exports, _underscore) {
  exports.removeOutliers = removeOutliers;
  exports.histogram = histogram;

  function removeOutliers(set) {
    if (set.length < 4) {
      return set;
    }

    var sorted = _underscore['default'].sortBy(set, function (x) {
      return x;
    });
    var q1Index = (sorted.length + 1) / 4;
    var q3Index = 3 * (sorted.length + 1) / 4;
    var q1, q3;
    var distance;

    if (q1Index % 1 > 0) {
      distance = sorted[Math.ceil(q1Index) - 1] - sorted[Math.floor(q1Index) - 1];
      q1 = sorted[Math.floor(q1Index) - 1] + distance * (q1Index % 1);
    } else {
      q1 = sorted[q1Index - 1];
    }

    if (q3Index % 1 > 0) {
      distance = sorted[Math.ceil(q3Index) - 1] - sorted[Math.floor(q3Index) - 1];
      q3 = sorted[Math.floor(q3Index) - 1] + distance * (q3Index % 1);
    } else {
      q3 = sorted[q3Index - 1];
    }

    var iqr = q3 - q1;
    var maxValue = q3 + iqr * 1.5;
    var minValue = q1 - iqr * 1.5;

    return _underscore['default'].filter(sorted, function (x) {
      return x >= minValue && x <= maxValue;
    });
  }

  function histogram(set) {
    var min = _underscore['default'].min(set);
    var max = _underscore['default'].max(set);
    var range = max - min;
    var numberOfBins = Math.min(Math.floor(Math.sqrt(set.length) * 2), set.length);
    var binWidth = min === max ? min : range / numberOfBins;
    var bins = [];
    var onePercent = 100 / set.length;
    var i;

    for (i = 0; i < numberOfBins; i++) {
      bins[i] = { value: min + binWidth * i, count: 0 };
    }

    for (i = 0; i < set.length; i++) {
      var element = set[i];
      var index = Math.min(Math.floor((element - min) / binWidth), numberOfBins - 1);

      if (isNaN(index)) {
        index = 0;
      }

      bins[index].count += 1;
    }

    return {
      min: min,
      max: max,
      bins: bins,
      binWidth: binWidth,
      numberOfBins: numberOfBins,
      onePercent: onePercent
    };
  }
});