define('tedian/routes/human-resources/absences/new', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      user: {},
      leaveType: {}
    },

    model: function model(parameters) {
      return _ember['default'].RSVP.hash({
        queryParameters: parameters,
        users: this.store.findAll('user'),
        paidLeave: this.store.createRecord('human-resources-paid-leave'),
        employees: this.store.query('human-resources-work-schedule', {
          include: 'user',
          filter: { 'in-effect-on': (0, _moment['default'])().format('YYYY-MM-DD') }
        }).then(function (schedules) {
          return schedules.map(function (schedule) {
            return schedule.get('user');
          });
        })
      });
    },

    afterModel: function afterModel(model) {
      model.paidLeave.setProperties({
        user: this.store.peekRecord('user', model.queryParameters.user),
        leaveType: model.queryParameters.leaveType
      });

      delete model.queryParameters;
    },

    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.set('user', undefined);
        controller.set('leaveType', undefined);
      }
    },

    actions: {
      createPaidLeave: function createPaidLeave() {
        var _this = this;

        var model = this.modelFor('human-resources.absences.new').paidLeave;

        model.save().then(function () {
          _this.transitionTo('human-resources.absences.index');
        }, function (error) {
          if (model.get('errors.length') === 0) {
            alert('Bei der Kommunikation mit der Schnittstelle trat ein unerwarteter Fehler auf:\n' + error.message.split("\n")[0]);
          }
        });
      },

      willTransition: function willTransition() {
        var model = this.modelFor('human-resources.absences.new').paidLeave;

        if (model.get('isNew')) {
          model.deleteRecord();
        }

        return true;
      }
    }
  });
});