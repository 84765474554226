define("tedian/templates/time-tracking/archive/week", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 6
            },
            "end": {
              "line": 31,
              "column": 6
            }
          },
          "moduleName": "tedian/templates/time-tracking/archive/week.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "time-tracking-target-actual-table", [], ["tagName", "", "model", ["subexpr", "@mut", [["get", "daysReport", ["loc", [null, [26, 16], [26, 26]]]]], [], []], "title", "Tag", "itemRouteName", "time-tracking.archive.date", "itemRouteParameterFormat", "YYYY-MM-DD"], ["loc", [null, [24, 8], [30, 10]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 4
            },
            "end": {
              "line": 48,
              "column": 4
            }
          },
          "moduleName": "tedian/templates/time-tracking/archive/week.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-field form-field--1-of-4");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
          return morphs;
        },
        statements: [["inline", "charts/activity-breakdown", [], ["model", ["subexpr", "@mut", [["get", "activityReport", ["loc", [null, [46, 40], [46, 54]]]]], [], []]], ["loc", [null, [46, 6], [46, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 6
            },
            "end": {
              "line": 53,
              "column": 6
            }
          },
          "moduleName": "tedian/templates/time-tracking/archive/week.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "time-tracking-simple-report-table", [], ["tagName", "", "model", ["subexpr", "@mut", [["get", "activityReport", ["loc", [null, [52, 61], [52, 75]]]]], [], []], "title", "Arbeitszeitaufteilung"], ["loc", [null, [52, 8], [52, 107]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 2
              },
              "end": {
                "line": 64,
                "column": 2
              }
            },
            "moduleName": "tedian/templates/time-tracking/archive/week.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "time-tracking-simple-report-table", [], ["tagName", "", "model", ["subexpr", "@mut", [["get", "report", ["loc", [null, [63, 57], [63, 63]]]]], [], []], "title", ["subexpr", "concat", ["Typ: ", ["get", "report.title", ["loc", [null, [63, 86], [63, 98]]]]], [], ["loc", [null, [63, 70], [63, 99]]]]], ["loc", [null, [63, 4], [63, 101]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 0
            },
            "end": {
              "line": 66,
              "column": 0
            }
          },
          "moduleName": "tedian/templates/time-tracking/archive/week.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "section section--space-1x");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
          return morphs;
        },
        statements: [["block", "responsive-table", [], [], 0, null, ["loc", [null, [62, 2], [64, 23]]]]],
        locals: ["report"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 67,
            "column": 0
          }
        },
        "moduleName": "tedian/templates/time-tracking/archive/week.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.setAttribute(el1, "class", "horizontal-separator");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "section section--space-2x");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.setAttribute(el1, "class", "horizontal-separator");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-field form-field--1-of-2");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-field form-field--1-of-2");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.setAttribute(el1, "class", "horizontal-separator");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "section section--space-1x");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-row");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.setAttribute(el1, "class", "horizontal-separator");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [8]);
        var element1 = dom.childAt(fragment, [12, 1]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[4] = dom.createMorphAt(element1, 1, 1);
        morphs[5] = dom.createAttrMorph(element2, 'class');
        morphs[6] = dom.createMorphAt(element2, 1, 1);
        morphs[7] = dom.createMorphAt(fragment, 16, 16, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "time-tracking-archive-header", [], ["levels", ["subexpr", "hash", [], ["day", ["subexpr", "hash", [], ["label", "Tag", "routeName", "time-tracking.archive.date", "routeParameterFormat", "YYYY-MM-DD"], ["loc", [null, [3, 8], [3, 99]]]], "week", ["subexpr", "hash", [], ["label", "Woche", "routeName", "time-tracking.archive.week", "routeParameterFormat", "GGGG-[W]WW"], ["loc", [null, [4, 9], [4, 102]]]], "month", ["subexpr", "hash", [], ["label", "Monat", "routeName", "time-tracking.archive.month", "routeParameterFormat", "YYYY-MM"], ["loc", [null, [5, 10], [5, 101]]]]], ["loc", [null, [2, 9], [6, 3]]]], "currentLevel", ["subexpr", "hash", [], ["type", ["get", "scopes.current.type", ["loc", [null, [8, 9], [8, 28]]]], "scopes", ["get", "scopes", ["loc", [null, [9, 11], [9, 17]]]]], ["loc", [null, [7, 15], [10, 3]]]]], ["loc", [null, [1, 0], [11, 2]]]], ["inline", "time-tracking-scope-summary", [], ["model", ["subexpr", "@mut", [["get", "daysReport.summary", ["loc", [null, [16, 38], [16, 56]]]]], [], []]], ["loc", [null, [16, 2], [16, 58]]]], ["block", "responsive-table", [], [], 0, null, ["loc", [null, [23, 6], [31, 27]]]], ["inline", "charts/time-target-actual-comparison", [], ["model", ["subexpr", "@mut", [["get", "daysReport", ["loc", [null, [35, 49], [35, 59]]]]], [], []]], ["loc", [null, [35, 4], [35, 61]]]], ["block", "if", [["get", "activityReport.items", ["loc", [null, [43, 10], [43, 30]]]]], [], 1, null, ["loc", [null, [43, 4], [48, 11]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "activityReport.items", ["loc", [null, [50, 21], [50, 41]]]], "form-field form-field--3-of-4"], [], ["loc", [null, [50, 16], [50, 75]]]]]]], ["block", "responsive-table", [], [], 2, null, ["loc", [null, [51, 6], [53, 27]]]], ["block", "each", [["get", "tagTypeReports", ["loc", [null, [60, 8], [60, 22]]]]], [], 3, null, ["loc", [null, [60, 0], [66, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});