define('tedian/routes/time-tracking/reports', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      dimension: { refreshModel: true },
      filter: { refreshModel: true },
      interval: { refreshModel: true },
      aggregate: { refreshModel: true }
    },

    model: function model(parameters) {
      var data = {
        dimension: parameters.dimension,
        filter: parameters.filter,
        interval: parameters.interval,
        aggregate: parameters.aggregate
      };

      return new _ember['default'].RSVP.hash({
        tags: this.store.findAll('time-tracking-tag'),
        interval: parameters.interval,
        response: this.requestReport(data)
      });
    },

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      if (model.interval) {
        var parts = model.interval.split('--');

        controller.setProperties({
          intervalStart: parts[0],
          intervalEnd: parts[1]
        });
      }

      var columns = model.response.map(function (item) {
        return item.aggregation_value;
      }).uniq().sort();
      var rows = model.response.map(function (item) {
        return item.dimension_tag_id;
      }).uniq();

      var data = _ember['default'].Object.create({
        dynamicColumns: columns.map(function (column) {
          return column.split('T')[0];
        }),
        rows: rows.map(function (row) {
          return _ember['default'].Object.create({
            dimensionTagId: row,
            tag: _this.store.peekRecord('time-tracking-tag', row),
            columns: columns.map(function (column) {
              return _ember['default'].Object.create({
                column: column,
                hours: 0
              });
            })
          });
        })
      });

      model.response.forEach(function (reportRow) {
        var row = data.get('rows').findBy('dimensionTagId', reportRow.dimension_tag_id);
        var column = row.get('columns').findBy('column', reportRow.aggregation_value);

        column.set('hours', Math.round(reportRow.seconds / 60 / 60 * 100) / 100);
      });

      controller.set('data', data);
    },

    requestReport: function requestReport(data) {
      if (data.dimension || data.filter) {
        return _ember['default'].$.ajax({
          url: this.store.adapterFor('application').buildURL('time-tracking-report'),
          type: 'GET',
          data: data
        });
      } else {
        var emptyResponse = [];
        return _ember['default'].RSVP.Promise.resolve(emptyResponse);
      }
    }
  });
});