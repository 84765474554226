define("tedian/templates/project-management/project", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "tedian/templates/project-management/project.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("header");
        dom.setAttribute(el1, "class", "app-layout__main-header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("nav");
        dom.setAttribute(el2, "class", "header-navigation");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("main");
        dom.setAttribute(el1, "class", "app-layout__main-body");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("header");
        dom.setAttribute(el2, "class", "block-header block-header--compact block-header--transparent flex-layout flex-layout--valign-middle flex-layout--gap-1x");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3, "class", "block-header__headline");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "button-group button-group--align-left flex-layout-fill");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "project-board");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("aside");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
        morphs[4] = dom.createAttrMorph(element4, 'class');
        morphs[5] = dom.createMorphAt(element4, 1, 1);
        morphs[6] = dom.createMorphAt(element4, 3, 3);
        morphs[7] = dom.createMorphAt(element4, 5, 5);
        morphs[8] = dom.createMorphAt(element4, 7, 7);
        morphs[9] = dom.createAttrMorph(element5, 'class');
        morphs[10] = dom.createMorphAt(element5, 1, 1);
        return morphs;
      },
      statements: [["inline", "link-to", ["Ticket Übersicht", "project-management.tickets"], ["class", "header-navigation__link"], ["loc", [null, [3, 4], [3, 95]]]], ["inline", "link-to", ["Projekte", "project-management.projects"], ["current-when", "project-management.projects project-management.project", "class", "header-navigation__link"], ["loc", [null, [4, 4], [4, 158]]]], ["content", "ticketColumnHeadline", ["loc", [null, [10, 39], [10, 63]]]], ["inline", "link-to", ["Neues Ticket erfassen", ["get", "newTicketRoute", ["loc", [null, [12, 40], [12, 54]]]]], ["class", "button button--small button--outlined"], ["loc", [null, [12, 6], [12, 102]]]], ["attribute", "class", ["concat", ["project-board__tickets ", ["subexpr", "if", [["get", "isChildRouteActive", ["loc", [null, [17, 44], [17, 62]]]], "unfocused"], [], ["loc", [null, [17, 39], [17, 76]]]]]]], ["inline", "ticket-column", [], ["label", "Ungeplant", "tickets", ["subexpr", "@mut", [["get", "unplannedTickets", ["loc", [null, [18, 48], [18, 64]]]]], [], []]], ["loc", [null, [18, 6], [18, 66]]]], ["inline", "ticket-column", [], ["label", "Geplant", "tickets", ["subexpr", "@mut", [["get", "plannedTickets", ["loc", [null, [19, 46], [19, 60]]]]], [], []]], ["loc", [null, [19, 6], [19, 62]]]], ["inline", "ticket-column", [], ["label", "Gestartet", "tickets", ["subexpr", "@mut", [["get", "startedTickets", ["loc", [null, [20, 48], [20, 62]]]]], [], []]], ["loc", [null, [20, 6], [20, 64]]]], ["inline", "ticket-column", [], ["label", "Abgeschlossen", "tickets", ["subexpr", "@mut", [["get", "finishedTickets", ["loc", [null, [21, 52], [21, 67]]]]], [], []]], ["loc", [null, [21, 6], [21, 69]]]], ["attribute", "class", ["concat", ["project-board__aside ", ["subexpr", "if", [["get", "isChildRouteActive", ["loc", [null, [24, 44], [24, 62]]]], "expanded"], [], ["loc", [null, [24, 39], [24, 75]]]]]]], ["content", "outlet", ["loc", [null, [25, 6], [25, 16]]]]],
      locals: [],
      templates: []
    };
  })());
});