define('tedian/helpers/utc', ['exports', 'ember-moment/helpers/utc'], function (exports, _emberMomentHelpersUtc) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMomentHelpersUtc['default'];
    }
  });
  Object.defineProperty(exports, 'utc', {
    enumerable: true,
    get: function get() {
      return _emberMomentHelpersUtc.utc;
    }
  });
});