define('tedian/routes/project-management/projects/new-project', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return new _ember['default'].RSVP.hash({
        members: this.store.findAll('project-management-member')
      });
    },

    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.set('shortName', '');
      controller.set('name', '');
      controller.set('assignedMembers', []);
    },

    actions: {
      assignMember: function assignMember(member) {
        var controller = this.controllerFor('project-management.projects.new-project');
        controller.get('assignedMembers').pushObject(member);
      },

      detractMember: function detractMember(member) {
        var controller = this.controllerFor('project-management.projects.new-project');
        controller.get('assignedMembers').removeObject(member);
      }
    }
  });
});