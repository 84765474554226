define('tedian/services/command-store', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    ajax: _ember['default'].inject.service('ajax'),
    store: _ember['default'].inject.service('store'),

    submit: function submit(command) {
      var payload = JSON.stringify({ command: command });
      return this._makeRequest(payload);
    },

    _makeRequest: function _makeRequest(payload) {
      var _this = this;

      var store = this.get('store');
      var url = store.adapterFor('application').buildURL('command');

      return this.get('ajax').raw(url, {
        method: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: payload
      }).then(function (_ref) {
        var jqXHR = _ref.jqXHR;

        _this.updateEmberDataStore(jqXHR.responseJSON.command);
        return _ember['default'].RSVP.resolve(jqXHR.responseJSON);
      })['catch'](function (_ref2) {
        var jqXHR = _ref2.jqXHR;

        window.alert('\nDavid ist zu faul im Interface Fehler auszugeben.\n\nTrotzdem gehen Dinge schief, wie jetzt gerade:\n\n' + JSON.stringify(jqXHR.responseJSON.error, null, '  ') + '\n      ');

        return _ember['default'].RSVP.reject(new Error('Command ' + JSON.parse(payload).command.commandName + ' failed'));
      });
    },

    updateEmberDataStore: function updateEmberDataStore(command) {
      var store = this.get('store');
      var keys = Object.keys(command);

      keys.forEach(function (key) {
        switch (key) {
          case 'project_id':
            store.findRecord('project-management-project', command[key]);
            break;
          case 'ticket_id':
            store.findRecord('project-management-ticket', command[key], { include: 'assignees' });
            break;
        }
      });
    }
  });
});