define('tedian/value-objects/ticket-title', ['exports'], function (exports) {
  exports['default'] = {
    fromText: function fromText(text) {
      var cleanedLines = this.cleanedLines(text);
      var linesWithText = cleanedLines.filter(function (line) {
        return line.length > 0;
      });

      return linesWithText[0];
    },

    toLines: function toLines(text) {
      var possibleLineEndings = /\r\n|\n\r|\n|\r/g;
      return text.replace(possibleLineEndings, '\n').split('\n');
    },

    cleanedLines: function cleanedLines(text) {
      var lines = this.toLines(text);
      return lines.map(function (line) {
        return line.replace(/^#+/, '');
      }).map(function (line) {
        return line.trim();
      });
    }
  };
});