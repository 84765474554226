define('tedian/value-objects/payment-schedule', ['exports', 'ember', 'tedian/value-objects/scheduled-payment'], function (exports, _ember, _tedianValueObjectsScheduledPayment) {

  var roundCurrency = function roundCurrency(amount, decimals) {
    var factor = Math.pow(10, decimals);

    return Math.round(amount * factor) / factor;
  };

  var PaymentSchedule = _ember['default'].Object.extend({
    payments: undefined,

    addScheduledPayment: function addScheduledPayment(dueOn, amount) {
      this.get('payments').pushObject(_tedianValueObjectsScheduledPayment['default'].create({
        dueOn: dueOn,
        amount: roundCurrency(amount, 2)
      }));
    },

    removeScheduledPayment: function removeScheduledPayment(payment) {
      this.get('payments').removeObject(payment);
    },

    allocateAmount: function allocateAmount(totalAmount) {
      var payments = this.get('payments');
      var roundedShare = Math.floor(totalAmount / payments.get('length'));
      var remainingAmount = totalAmount;

      payments.forEach(function (payment) {
        if (remainingAmount >= roundedShare * 2) {
          remainingAmount -= roundedShare;
          payment.set('amount', roundCurrency(roundedShare, 2));
        } else {
          payment.set('amount', roundCurrency(remainingAmount, 2));
        }
      });
    },

    nextDueDate: function nextDueDate(paidAmount) {
      var payments = this.get('payments');
      var index = this._indexOfFirstUnsettledPayment(paidAmount);

      return payments.objectAt(index).get('dueOn');
    },

    settledPayments: function settledPayments(paidAmount) {
      var payments = this.get('payments');
      var startIndex = 0;
      var endIndex = this._indexOfFirstUnsettledPayment(paidAmount);

      return payments.slice(startIndex, endIndex);
    },

    unsettledPayments: function unsettledPayments(paidAmount) {
      var payments = this.get('payments');
      var startIndex = this._indexOfFirstUnsettledPayment(paidAmount);
      var endIndex = payments.get('length');

      return payments.slice(startIndex, endIndex);
    },

    _indexOfFirstUnsettledPayment: function _indexOfFirstUnsettledPayment(paidAmount) {
      var remainingAmount = roundCurrency(paidAmount, 2);
      var payments = this.get('payments');
      var firstUnsettledPayment = payments.find(function (payment) {
        remainingAmount = roundCurrency(remainingAmount - payment.get('amount'), 2);
        return remainingAmount < 0;
      });

      var index = payments.indexOf(firstUnsettledPayment);
      return index === -1 ? payments.get('length') : index;
    }
  });

  PaymentSchedule.reopenClass({
    load: function load(data) {
      return PaymentSchedule.create({
        payments: (data || []).map(function (row) {
          return _tedianValueObjectsScheduledPayment['default'].create({
            dueOn: row.due_on,
            amount: roundCurrency(parseFloat(row.amount, 10), 2)
          });
        })
      });
    },

    dump: function dump(object) {
      if (_ember['default'].isNone(object)) {
        return [];
      }

      return object.get('payments').map(function (payment) {
        return {
          due_on: payment.get('dueOn'),
          amount: '' + payment.get('amount')
        };
      });
    }
  });

  exports['default'] = PaymentSchedule;
});