define('tedian/sentry', ['exports', 'ember', 'tedian/config/environment', 'npm:@sentry/browser', 'npm:@sentry/integrations'], function (exports, _ember, _tedianConfigEnvironment, _npmSentryBrowser, _npmSentryIntegrations) {
  exports.startSentry = startSentry;

  function startSentry() {
    if (_tedianConfigEnvironment['default'].environment !== 'test' && !_ember['default'].isBlank(_tedianConfigEnvironment['default'].sentry.dsn)) {
      var configuration = Object.assign({}, _tedianConfigEnvironment['default'].sentry, {
        integrations: [new _npmSentryIntegrations['default'].Ember()],
        beforeSend: function beforeSend(event, hint) {
          var error = undefined;

          // The way to get the error depends on the event level ('error' or 'info').
          // For 'info' events (usually caused by `captureMessage`) there is
          // a 'extra' attribute which contains the RSVP error as 'reason'.
          if (event.level === 'error') {
            error = hint.originalException;
          } else {
            error = 'extra' in event ? event.extra.reason : undefined;
          }

          // Ember 2.X seems to not catch `TransitionAborted` errors caused by
          // regular redirects. We don't want these errors to show up in Sentry
          // so we have to filter them ourselfs.
          //
          // Once this issue https://github.com/emberjs/ember.js/issues/12505 is
          // resolved we can remove this code.
          if (error && error.name === 'TransitionAborted') {
            return null;
          }

          return event;
        }
      });

      _npmSentryBrowser['default'].init(configuration);
    }
  }
});