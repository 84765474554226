define('tedian/routes/session/destroy', ['exports', 'ember', 'tedian/routes/protected'], function (exports, _ember, _tedianRoutesProtected) {
  exports['default'] = _tedianRoutesProtected['default'].extend({
    session: _ember['default'].inject.service('session'),

    setupController: function setupController() {
      var _this = this;

      this.get('session').signOut().then(function () {
        _this.transitionTo('session.new');
      });
    }
  });
});