define('tedian/models/invoice-draft', ['exports', 'ember', 'ember-data', 'moment', 'tedian/value-objects/payment-schedule'], function (exports, _ember, _emberData, _moment, _tedianValueObjectsPaymentSchedule) {

  var roundCurrency = function roundCurrency(amount, decimals) {
    var factor = Math.pow(10, decimals);

    return Math.round(amount * factor) / factor;
  };

  exports['default'] = _emberData['default'].Model.extend({
    organisation: _emberData['default'].belongsTo('organisation'),
    invoiceItemDrafts: _emberData['default'].hasMany('invoice-item-draft'),
    address: _emberData['default'].attr('string'),
    salutation: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    periodOfPerformanceStartedOn: _emberData['default'].attr('string'),
    periodOfPerformanceEndedOn: _emberData['default'].attr('string'),
    recipientVatNumber: _emberData['default'].attr('string'),
    reverseCharge: _emberData['default'].attr('boolean', { 'default': false }),
    issuer: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr(),
    updatedAt: _emberData['default'].attr(),
    paymentSchedule: _emberData['default'].attr('payment-schedule'),

    issue: function issue() {
      var modelName = this.constructor.modelName;
      var adapter = this.store.adapterFor(modelName);
      return adapter.issue(this.get('id'));
    },

    isUnsaved: _ember['default'].computed('hasDirtyAttributes', 'invoiceItemDrafts.@each.hasDirtyAttributes', function () {
      return this.get('hasDirtyAttributes') || this.get('invoiceItemDrafts').any(function (item) {
        return item.get('hasDirtyAttributes');
      });
    }),

    subtotal: _ember['default'].computed('invoiceItemDrafts.@each.sum', function () {
      var amount = this.get('invoiceItemDrafts').reduce(function (previousValue, item) {
        return previousValue + item.get('sum');
      }, 0);

      return roundCurrency(amount, 2);
    }),

    valueAddedTax: _ember['default'].computed('subtotal', function () {
      return roundCurrency(this.get('subtotal') * 0.20, 2);
    }),

    total: _ember['default'].computed('subtotal', 'valueAddedTax', 'reverseCharge', function () {
      if (this.get('reverseCharge')) {
        return this.get('subtotal');
      } else {
        return roundCurrency(this.get('subtotal') + this.get('valueAddedTax'), 2);
      }
    }),

    addScheduledPayment: function addScheduledPayment() {
      var dueOn = (0, _moment['default'])().add(30, 'days').format('YYYY-MM-DD');
      var total = this.get('total');

      if (_ember['default'].isNone(this.get('paymentSchedule'))) {
        this.set('paymentSchedule', _tedianValueObjectsPaymentSchedule['default'].load([]));
      }

      this.get('paymentSchedule').addScheduledPayment(dueOn, 0);
      this.get('paymentSchedule').allocateAmount(total);
    },

    removeScheduledPayment: function removeScheduledPayment(payment) {
      var total = this.get('total');

      this.get('paymentSchedule').removeScheduledPayment(payment);
      this.get('paymentSchedule').allocateAmount(total);
    }
  });
});