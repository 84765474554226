define('tedian/components/paid-leave-form', ['exports', 'ember', 'moment', 'tedian/models/human-resources-paid-leave'], function (exports, _ember, _moment, _tedianModelsHumanResourcesPaidLeave) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'form',

    model: null,
    employees: null,
    submitLabel: 'Speichern',
    onSubmit: _ember['default'].K,
    onDelete: _ember['default'].K,

    isPersisted: _ember['default'].computed.notEmpty('model.id'),

    employeeOptions: _ember['default'].computed('employees', function () {
      return this.get('employees');
    }),

    selectedEmployeeOption: _ember['default'].computed('model.user', function () {
      return this.get('model.user');
    }),

    leaveTypeOptions: _tedianModelsHumanResourcesPaidLeave.leaveTypes,

    selectedLeaveTypeOption: _ember['default'].computed('leaveTypeOptions', 'model.leaveType', function () {
      var leaveType = this.get('model.leaveType');
      return this.get('leaveTypeOptions').find(function (option) {
        return option.get('id') === leaveType;
      });
    }),

    actions: {
      submit: function submit() {
        this.onSubmit();
      },

      'delete': function _delete() {
        this.onDelete();
      },

      selectEmployee: function selectEmployee(employee) {
        this.get('model').set('user', employee);
        this.get('model.errors').remove('user');
      },

      selectLeaveType: function selectLeaveType(leaveType) {
        this.get('model').set('leaveType', leaveType.get('id'));
        this.get('model.errors').remove('leaveType');
      },

      setDate: function setDate(date) {
        if (_ember['default'].isBlank(date)) {
          this.get('model').set('on', null);
        } else {
          this.get('model').set('on', (0, _moment['default'])(date).format('YYYY-MM-DD'));
        }
        this.get('model.errors').remove('on');
      }
    }
  });
});