define('tedian/controllers/time-tracking/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    timeTracking: _ember['default'].inject.service('time-tracking'),

    isAttendanceTrackingEnabled: _ember['default'].computed('model.tags', function () {
      return this.get('model.tags').any(function (tag) {
        return tag.isOfType('attendance');
      });
    }),

    attendanceTags: _ember['default'].computed('model.tags', function () {
      return this.get('model.tags').filter(function (tag) {
        return tag.isOfType('attendance');
      });
    }),

    currentUserAttendanceTimer: _ember['default'].computed('timeTracking.timers', function () {
      var timers = this.get('timeTracking.timers');

      if (_ember['default'].isBlank(timers)) {
        return null;
      }

      return timers.find(function (timer) {
        return timer.get('timeTrackingTags').any(function (tag) {
          return tag.isOfType('attendance');
        });
      });
    }),

    activityTags: _ember['default'].computed('model.tags', function () {
      return this.get('model.tags').filter(function (tag) {
        return !tag.isOfType('attendance');
      });
    }),

    currentUserActivityTimer: _ember['default'].computed('timeTracking.timers', function () {
      var timers = this.get('timeTracking.timers');

      if (_ember['default'].isBlank(timers)) {
        return null;
      }

      return timers.find(function (timer) {
        return timer.get('timeTrackingTags').every(function (tag) {
          return !tag.isOfType('attendance');
        });
      });
    }),

    templateTimersByClientTag: _ember['default'].computed('model.templateTimers.[]', function () {
      var groupedTimers = [];

      this.get('model.templateTimers').forEach(function (timer) {
        var clientTag = timer.get('timeTrackingTags').find(function (tag) {
          return tag.isOfType('client');
        });
        var clientGroup = groupedTimers.find(function (group) {
          return group.tag === clientTag;
        });

        if (clientGroup) {
          clientGroup.content.pushObject(timer);
        } else {
          groupedTimers.pushObject(_ember['default'].Object.create({
            tag: clientTag,
            content: [timer]
          }));
        }
      });

      groupedTimers.forEach(function (group) {
        group.set('content', group.get('content').sort(function (a, b) {
          var contractTagA = a.get('timeTrackingTags').find(function (tag) {
            return tag.isOfType('contract');
          });
          var contractTagB = b.get('timeTrackingTags').find(function (tag) {
            return tag.isOfType('contract');
          });
          var contractItemTagA = a.get('timeTrackingTags').find(function (tag) {
            return tag.isOfType('contract-item');
          });
          var contractItemTagB = b.get('timeTrackingTags').find(function (tag) {
            return tag.isOfType('contract-item');
          });

          if (!contractItemTagA) {
            contractItemTagA = _ember['default'].Object.create({ slug: ':0' });
          }

          if (!contractItemTagB) {
            contractItemTagB = _ember['default'].Object.create({ slug: ':0' });
          }

          var contractNumberA = parseInt(contractTagA.get('slug').split(':')[1], 10);
          var contractNumberB = parseInt(contractTagB.get('slug').split(':')[1], 10);
          var contractItemNumberA = parseInt(contractItemTagA.get('slug').split(':')[1], 10);
          var contractItemNumberB = parseInt(contractItemTagB.get('slug').split(':')[1], 10);

          if (contractNumberA === contractNumberB) {
            return contractItemNumberA - contractItemNumberB;
          } else {
            return contractNumberA - contractNumberB;
          }
        }));
      });

      return groupedTimers.sort(function (a, b) {
        var clientTagA = a.get('tag.name');
        var clientTagB = b.get('tag.name');

        if (clientTagA && clientTagB) {
          return clientTagA.toLowerCase() >= clientTagB.toLowerCase() ? 1 : -1;
        } else if (!clientTagA && !clientTagB) {
          return 0;
        } else {
          return !!clientTagA ? 1 : -1;
        }
      });
    }),

    actions: {
      startTimer: function startTimer(tags) {
        this.get('timeTracking').startTimer([this.get('timeTracking.currentUserTag')].concat(tags || []));
      },

      stopTimer: function stopTimer(timer) {
        this.get('timeTracking').stopTimer(timer);
      },

      updateComment: function updateComment(timer) {
        this.get('timeTracking').updateComment(timer);
      },

      removeTag: function removeTag(timer, tag) {
        this.get('timeTracking').removeTag(timer, tag);
      },

      addTag: function addTag(timer, tag) {
        this.get('timeTracking').addTag(timer, tag);
      },

      addIntervalWithTags: function addIntervalWithTags(tagIds) {
        this.transitionToRoute('time-tracking.interval-new', { queryParams: { 'tags': tagIds.join(',') } });
      }
    }
  });
});