define('tedian/routes/controlling/contracts/index', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    dutyRoster: _ember['default'].inject.service('duty-roster'),

    model: function model() {
      var _this = this;

      /*
        Loading order with dependencies and calculation steps
         loadVisibleContracts
        loadDutyRoster
          loadAvailableWorkingHoursOfThePast
          loadUsedWorkingHoursOfThePast
          loadWorkDataOfContracts
      */

      return _ember['default'].RSVP.hash({
        visibleContracts: this._loadVisibleContracts(),
        dutyRoster: this._loadDutyRoster()
      }).then(function (resultOne) {
        console.log('resultOne', resultOne);

        return _ember['default'].RSVP.hash({
          dutyRoster: resultOne.dutyRoster,
          availableWorkingHoursOfThePast: _this._loadAvailableWorkingHoursOfThePast(),
          usedWorkingHoursOfThePast: _this._loadUsedWorkingHoursOfThePast(),
          visibleContracts: _this._loadWorkDataOfContracts(resultOne.visibleContracts)
        }).then(function (resultTwo) {
          console.log('resultTwo', resultTwo);

          return _ember['default'].RSVP.hash({
            visibleContracts: _this._calculateCompletionDates(resultTwo.visibleContracts, resultTwo.usedWorkingHoursOfThePast / resultTwo.availableWorkingHoursOfThePast, resultTwo.availableWorkingHoursOfThePast)
          }).then(function (resultThree) {
            console.log('resultThree', resultThree);

            var totalHours = resultThree.visibleContracts.reduce(function (total, row) {
              return total + row.pendingHours;
            }, 0);

            var lastContractCompletedOn = _this._calculateDateBasedOnData(totalHours, resultTwo.usedWorkingHoursOfThePast / resultTwo.availableWorkingHoursOfThePast);

            return {
              efficiency: resultTwo.usedWorkingHoursOfThePast / resultTwo.availableWorkingHoursOfThePast,
              lastContractCompletedOn: lastContractCompletedOn,
              contracts: resultThree.visibleContracts
            };
          });
        });
      });
    },

    _loadVisibleContracts: function _loadVisibleContracts() {
      return _ember['default'].RSVP.resolve([{
        id: '6790ffb8-75a2-4555-8953-5615a4350f85',
        name: 'MDS Schulungsplattform',
        volume: 25000 + 3000,
        availableHours: 250 + 30
      }, {
        id: 'adb5cac3-03f8-43d6-af6e-c5e4625adb92',
        name: 'FBK Zahlungsdienstleister und Versand-Staffelung',
        volume: 3000 + 1500
      }, {
        id: '9c9b5711-b351-432c-9e6b-26d3ebcc1ee1',
        name: 'ZF Umfragen, Anrede, etc.',
        volume: 200 + 500 + 600 + 600
      }, {
        id: '80121b47-ce2c-4c8b-bcc6-d1c5360a6abd',
        name: 'ZF Website Überarbeitung',
        availableHours: 19.83 + 6.5
      }, {
        id: '594af8e4-00b0-49d4-a404-5d910aeb56bd',
        name: 'ZF Keine Wartelisten-Info bei Kursabsage'
      }]);
    },

    _loadDutyRoster: function _loadDutyRoster() {
      return this.get('dutyRoster').load();
    },

    _loadWorkDataOfContracts: function _loadWorkDataOfContracts(contracts) {
      var _this2 = this;

      return _ember['default'].RSVP.all(contracts.map(function (contract) {
        return _this2._loadWorkDataOfContract(contract);
      }));
    },

    _loadWorkDataOfContract: function _loadWorkDataOfContract(contract) {
      var _this3 = this;

      console.log('contract', contract);

      return this.store.findRecord('procurement-quote', contract.id).then(function (contractRecord) {
        var number = contractRecord.get('number');
        var age = (0, _moment['default'])().diff(contractRecord.get('contractEnteredOn'), 'days');
        var availableHours = contract.availableHours || contractRecord.get('estimatedTotalHours');

        return _ember['default'].RSVP.hash({
          trackedHours: _this3._loadTotalHoursForTag('contract:' + number),
          lastTrackedMoment: _this3._loadLastTrackedMomentForTag('contract:' + number)
        }).then(function (trackingData) {
          console.log('trackingData', trackingData);

          return {
            id: contract.id,
            availableHours: availableHours,
            number: number,
            name: contract.name || contractRecord.get('title'),
            volume: contract.volume || contractRecord.get('totalAmount'),
            age: age,
            lastActivity: toTodayAwareString(trackingData.lastTrackedMoment),
            workedHours: trackingData.trackedHours,
            pendingHours: availableHours - trackingData.trackedHours,
            workedPercentage: trackingData.trackedHours / availableHours
          };
        });
      });
    },

    _loadTotalHoursForTag: function _loadTotalHoursForTag(slug) {
      var _this4 = this;

      return _ember['default'].$.ajax({
        url: this.store.adapterFor('application').buildURL('time-tracking-report'),
        type: 'GET',
        data: {
          dimension: slug.split(':')[0],
          aggregate: 'total',
          filter: slug
        }
      }).then(function (result) {
        if (result.length > 0) {
          var tagIds = result.map(function (item) {
            return item.dimension_tag_id;
          }).uniq();

          return _ember['default'].RSVP.all(tagIds.map(function (id) {
            return _this4.store.findRecord('time-tracking-tag', id);
          })).then(function (tags) {
            var tag = tags.find(function (tag) {
              return tag.get('slug') === slug;
            });
            var relevantRows = result.filter(function (row) {
              return row.dimension_tag_id === tag.get('id');
            });

            return relevantRows.sortBy('seconds').reverse()[0].seconds / 60 / 60;
          });
        } else {
          return _ember['default'].RSVP.resolve(0);
        }
      });
    },

    _loadLastTrackedMomentForTag: function _loadLastTrackedMomentForTag(slug) {
      var _this5 = this;

      return _ember['default'].$.ajax({
        url: this.store.adapterFor('application').buildURL('time-tracking-report'),
        type: 'GET',
        data: {
          dimension: slug.split(':')[0],
          aggregate: 'day',
          filter: slug
        }
      }).then(function (result) {
        if (result.length > 0) {
          var tagIds = result.map(function (item) {
            return item.dimension_tag_id;
          }).uniq();

          return _ember['default'].RSVP.all(tagIds.map(function (id) {
            return _this5.store.findRecord('time-tracking-tag', id);
          })).then(function (tags) {
            var tag = tags.find(function (tag) {
              return tag.get('slug') === slug;
            });
            var relevantRows = result.filter(function (row) {
              return row.dimension_tag_id === tag.get('id');
            });
            var lastActivityAt = (0, _moment['default'])(relevantRows.sortBy('aggregation_value').reverse()[0].aggregation_value);

            return lastActivityAt;
          });
        } else {
          return _ember['default'].RSVP.resolve(null);
        }
      });
    },

    _loadAvailableWorkingHoursOfThePast: function _loadAvailableWorkingHoursOfThePast() {
      var days = this.get('dutyRoster').query({
        interval: (0, _moment['default'])().subtract(6, 'weeks').format('YYYY-MM-DD') + '--' + (0, _moment['default'])().format('YYYY-MM-DD')
      });

      return days.reduce(function (total, day) {
        return total + day.target;
      }, 0);
    },

    _loadUsedWorkingHoursOfThePast: function _loadUsedWorkingHoursOfThePast() {
      return _ember['default'].$.ajax({
        url: this.store.adapterFor('application').buildURL('time-tracking-report'),
        type: 'GET',
        data: {
          dimension: 'contract',
          aggregate: 'total',
          interval: (0, _moment['default'])().subtract(6, 'weeks').format('YYYY-MM-DD') + '--' + (0, _moment['default'])().format('YYYY-MM-DD')
        }
      }).then(function (result) {
        return result.reduce(function (total, row) {
          return total + row.seconds / 60 / 60;
        }, 0);
      });
    },

    _loadUsedWorkingHoursOfThePastForContract: function _loadUsedWorkingHoursOfThePastForContract(slug) {
      return _ember['default'].$.ajax({
        url: this.store.adapterFor('application').buildURL('time-tracking-report'),
        type: 'GET',
        data: {
          dimension: slug.split(':')[0],
          aggregate: 'total',
          interval: (0, _moment['default'])().subtract(6, 'weeks').format('YYYY-MM-DD') + '--' + (0, _moment['default'])().format('YYYY-MM-DD'),
          filter: slug
        }
      }).then(function (result) {
        return result.reduce(function (total, row) {
          return total + row.seconds / 60 / 60;
        }, 0);
      });
    },

    _calculateCompletionDates: function _calculateCompletionDates(contracts, overallEfficiency, availableWorkingHoursOfThePast) {
      var _this6 = this;

      return _ember['default'].RSVP.all(contracts.map(function (contract) {
        return _ember['default'].RSVP.hash({
          usedHours: _this6._loadUsedWorkingHoursOfThePastForContract('contract:' + contract.number),
          contract: contract
        });
      })).then(function (result) {
        return result.map(function (row) {
          row.contract.earliestCompletionDate = _this6._calculateDateBasedOnData(row.contract.pendingHours, overallEfficiency);
          row.contract.currentCompletionDate = _this6._calculateDateBasedOnData(row.contract.pendingHours, row.usedHours / availableWorkingHoursOfThePast);

          return row.contract;
        });
      });
    },

    _calculateDateBasedOnData: function _calculateDateBasedOnData(hours, efficiency) {
      var remainingHours = hours;
      var date = (0, _moment['default'])();

      if (efficiency < 0.005) {
        return 'Unbekannt';
      }

      while (remainingHours > 0) {
        date.add(1, 'day');

        var availableDays = this.get('dutyRoster').query({
          interval: date.format('YYYY-MM-DD') + '--' + date.format('YYYY-MM-DD')
        });

        var availableHours = availableDays.reduce(function (total, day) {
          return total + day.target;
        }, 0);

        var predictedWorkedHours = availableHours * efficiency;

        remainingHours -= predictedWorkedHours;
      }

      return date.format('DD.MM.YYYY');
    }
  });

  function toTodayAwareString(input) {
    if (input === null) {
      return '-';
    }

    var formatted = input.format('YYYY-MM-DD');
    var today = (0, _moment['default'])().format('YYYY-MM-DD');

    if (today === formatted) {
      return 'Heute';
    } else {
      return input.fromNow();
    }
  }
});