define('tedian/models/project-management-ticket', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'tedian/value-objects/ticket-title'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _tedianValueObjectsTicketTitle) {
  exports['default'] = _emberDataModel['default'].extend({
    aggregateVersion: (0, _emberDataAttr['default'])(),
    number: (0, _emberDataAttr['default'])(),
    description: (0, _emberDataAttr['default'])(),
    category: (0, _emberDataAttr['default'])(),
    state: (0, _emberDataAttr['default'])(),
    assignees: (0, _emberDataRelationships.hasMany)('project-management-member'),
    project: (0, _emberDataRelationships.belongsTo)('project-management-project'),

    title: _ember['default'].computed('description', function () {
      return _tedianValueObjectsTicketTitle['default'].fromText(this.get('description'));
    }),

    canBeRefined: _ember['default'].computed('state', function () {
      return ['unplanned', 'planned'].contains(this.get('state'));
    }),

    canBeAssigned: _ember['default'].computed('state', function () {
      return ['unplanned', 'planned'].contains(this.get('state'));
    }),

    canBeDiscarded: _ember['default'].computed('state', function () {
      return ['unplanned', 'planned', 'started'].contains(this.get('state'));
    })
  });
});