define('tedian/risk-management/base-risk-model', ['exports', 'underscore', 'tedian/risk-management/functions'], function (exports, _underscore, _tedianRiskManagementFunctions) {
  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var BaseRiskModel = (function () {
    function BaseRiskModel(factors) {
      _classCallCheck(this, BaseRiskModel);

      this._factors = factors;
      this._histogram = (0, _tedianRiskManagementFunctions.histogram)((0, _tedianRiskManagementFunctions.removeOutliers)(factors));
    }

    _createClass(BaseRiskModel, [{
      key: 'factorForProbability',
      value: function factorForProbability(probability) {
        var _this = this;

        var cumulativeProbability = 0;
        var cumulativeHistogram = _underscore['default'].map(this._histogram.bins, function (bin) {
          return [bin.value, cumulativeProbability += bin.count * _this._histogram.onePercent];
        });

        var bin = _underscore['default'].find(cumulativeHistogram, function (bin) {
          return bin[1] >= probability;
        });

        return bin[0];
      }
    }]);

    return BaseRiskModel;
  })();

  exports['default'] = BaseRiskModel;
});