define('tedian/routes/clients/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return this.store.findRecord('organisation', parameters.id);
    },

    actions: {
      saveOrganisation: function saveOrganisation() {
        var _this = this;

        var model = this.modelFor('clients.edit');

        model.save().then(function () {
          return model.get('contactPerson').then(function (contactPerson) {
            return contactPerson.save();
          });
        }).then(function () {
          _this.transitionTo('clients');
        });
      },

      willTransition: function willTransition() {
        var organisation = this.modelFor('clients.edit');

        organisation.rollbackAttributes();
        organisation.get('contactPerson').then(function (contactPerson) {
          contactPerson.rollbackAttributes();
        });

        return true;
      }
    }
  });
});