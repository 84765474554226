define('tedian/routes/controlling/labor-time/index', ['exports', 'ember', 'moment', 'tedian/value-objects/simple-report'], function (exports, _ember, _moment, _tedianValueObjectsSimpleReport) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),
    dutyRoster: _ember['default'].inject.service('duty-roster'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return _ember['default'].RSVP.hash({
        dutyRoster: this.get('dutyRoster').load(),
        tags: this.store.findAll('time-tracking-tag')
      });
    },

    queryParams: {
      year: { refreshModel: true },
      filter: { refreshModel: true },
      aggregate: { refreshModel: true }
    },

    model: function model(parameters) {
      var year = (0, _moment['default'])([parameters.year, 0, 1]);
      var filter = parameters.filter;
      var aggregate = parameters.aggregate || 'month';
      var interval = [year.clone().startOf('year').format('YYYY-MM-DD'), year.clone().endOf('year').format('YYYY-MM-DD')].join('--');

      var attendanceReportParameters = {
        dimension: 'attendance',
        filter: filter,
        interval: interval,
        aggregate: aggregate
      };

      var scheduleParameters = {
        interval: interval,
        aggregate: aggregate
      };

      if (filter) {
        attendanceReportParameters.filter = filter;
      }

      if (filter && filter.startsWith('user:')) {
        scheduleParameters.userId = filter.match(/user:(.*)/)[1];
      }

      return _ember['default'].RSVP.hash({
        users: this.store.findAll('user'),
        attendanceByAggregateReport: this.requestReport(attendanceReportParameters),
        scheduleByAggregate: this.get('dutyRoster').query(scheduleParameters)
      });
    },

    requestReport: function requestReport(data) {
      var _this = this;

      return _ember['default'].$.ajax({
        url: this.store.adapterFor('application').buildURL('time-tracking-report'),
        type: 'GET',
        data: data
      }).then(function (response) {
        response.forEach(function (row) {
          row.tag = _this.store.peekRecord('time-tracking-tag', row.dimension_tag_id);
        });

        return _tedianValueObjectsSimpleReport['default'].fromRows(response);
      });
    }
  });
});