define('tedian/models/procurement-quote', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment', 'ember', 'npm:d3-array', 'underscore', 'tedian/risk-management/functions'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _moment, _ember, _npmD3Array, _underscore, _tedianRiskManagementFunctions) {

  function round(number, scale) {
    return Math.round(number * Math.pow(10, scale)) / Math.pow(10, scale);
  }

  exports['default'] = _emberDataModel['default'].extend({
    items: (0, _emberDataRelationships.hasMany)('procurement-item'),
    organisation: (0, _emberDataRelationships.belongsTo)('organisation'),
    number: (0, _emberDataAttr['default'])(),
    title: (0, _emberDataAttr['default'])(),
    projectManagementDataPoints: (0, _emberDataAttr['default'])(),
    projectManagementFactor: (0, _emberDataAttr['default'])(),
    projectManagementConfidence: (0, _emberDataAttr['default'])(),
    estimationDataLabel: (0, _emberDataAttr['default'])(),
    estimationDataPoints: (0, _emberDataAttr['default'])(),
    estimationFactor: (0, _emberDataAttr['default'])(),
    estimationConfidence: (0, _emberDataAttr['default'])(),
    hourlyCosts: (0, _emberDataAttr['default'])(),
    scheduleConfidence: (0, _emberDataAttr['default'])(),
    workStartsOn: (0, _emberDataAttr['default'])(),
    hoursPerDay: (0, _emberDataAttr['default'])(),
    schedule: (0, _emberDataAttr['default'])(),
    targetCompletionDate: (0, _emberDataAttr['default'])(),
    estimatedProjectManagementHours: (0, _emberDataAttr['default'])(),
    estimatedItemHours: (0, _emberDataAttr['default'])(),
    estimatedTotalHours: (0, _emberDataAttr['default'])(),
    projectManagementAmount: (0, _emberDataAttr['default'])(),
    itemAmount: (0, _emberDataAttr['default'])(),
    totalAmount: (0, _emberDataAttr['default'])(),
    issuedOn: (0, _emberDataAttr['default'])(),
    validUntil: (0, _emberDataAttr['default'])(),
    acceptedOn: (0, _emberDataAttr['default'])(),
    inquiryReceivedOn: (0, _emberDataAttr['default'])(),
    inquiryClosedOn: (0, _emberDataAttr['default'])(),
    requestedOn: (0, _emberDataAttr['default'])(),
    declinedOn: (0, _emberDataAttr['default'])(),
    internalNotes: (0, _emberDataAttr['default'])(),
    contractAwardedOn: (0, _emberDataAttr['default'])(),
    contractEnteredOn: (0, _emberDataAttr['default'])(),
    contractCompletedOn: (0, _emberDataAttr['default'])(),
    contractInvoicedOn: (0, _emberDataAttr['default'])(),
    contractCanceledOn: (0, _emberDataAttr['default'])(),
    state: (0, _emberDataAttr['default'])(),

    specialStaticDiscount: _ember['default'].computed('id', function () {
      // Quote #63 Zeichenfabrik Re-Design
      if (this.get('id') === 'd700c20c-2056-47e7-a1e0-38a6ae2669b4') {
        return -6438.30;
      } else {
        return undefined;
      }
    }),

    isQuote: _ember['default'].computed('state', function () {
      return ['quote', 'quote-issued'].includes(this.get('state'));
    }),

    isContract: _ember['default'].computed('state', function () {
      return ['contract-awarded', 'contract-entered'].includes(this.get('state'));
    }),

    hasAwardableItems: _ember['default'].computed('items.@each.canBeAwarded', function () {
      return this.get('items').any(function (item) {
        return item.get('canBeAwarded');
      });
    }),

    canBeInvoiced: _ember['default'].computed('state', function () {
      return ['contract-entered', 'contract-completed'].includes(this.get('state'));
    }),

    canBeCompleted: _ember['default'].computed('state', function () {
      return ['contract-entered', 'contract-invoiced'].includes(this.get('state'));
    }),

    canBeCanceled: _ember['default'].computed('state', function () {
      return ['contract-entered'].includes(this.get('state'));
    }),

    canItemBeAdded: _ember['default'].computed('state', function () {
      return !['contract-completed'].includes(this.get('state'));
    }),

    accept: function accept() {
      this.setProperties({
        acceptedOn: (0, _moment['default'])().format('YYYY-MM-DD')
      });
    },

    calculateProjectManagementHours: function calculateProjectManagementHours() {
      var estimatedProjectManagementHours = round(this.get('estimatedItemHours') * this.get('projectManagementFactor'), 2);
      this.set('estimatedProjectManagementHours', estimatedProjectManagementHours);
    },

    calculateTotalHours: function calculateTotalHours() {
      this.set('estimatedTotalHours', this.get('estimatedProjectManagementHours') + this.get('estimatedItemHours'));
    },

    calculateProjectManagementCosts: function calculateProjectManagementCosts() {
      var projectManagementAmount = round(this.get('estimatedProjectManagementHours') * this.get('hourlyCosts'), 2);
      this.set('projectManagementAmount', projectManagementAmount);
    },

    calculateTotalCosts: function calculateTotalCosts() {
      this.set('totalAmount', this.get('projectManagementAmount') + this.get('itemAmount'));
    },

    calculateSchedule: function calculateSchedule() {
      var totalEstimatedHours = this.get('estimatedTotalHours');
      var scheduleConfidence = this.get('scheduleConfidence') || 0.5;
      var workStartsOn = this.get('workStartsOn') || '2017-01-01';
      var hoursPerDay = this.get('hoursPerDay') || 6;
      var estimationSet = (0, _tedianRiskManagementFunctions.removeOutliers)(this.get('estimationDataPoints') || []);
      var simulatedTotalHours = [];
      var cumulativeHours = [];

      for (var i = 0; i < 50000; i++) {
        var simulatedFactor = Math.round(_npmD3Array['default'].quantile(estimationSet, Math.random()) * 1000) / 1000;
        simulatedTotalHours.push(totalEstimatedHours * simulatedFactor);
      }

      var sortedSimulatedTotalHours = _underscore['default'].sortBy(simulatedTotalHours, function (x) {
        return x;
      });

      for (var y = 0; y <= 1; y += 0.01) {
        cumulativeHours.push({
          hours: _npmD3Array['default'].quantile(sortedSimulatedTotalHours, y),
          percentage: Math.round(y * 100)
        });
      }

      var dates = _underscore['default'].map(cumulativeHours, function (item) {
        return {
          date: calculateEndDate(workStartsOn, item.hours, hoursPerDay),
          percentage: item.percentage
        };
      });

      var uniqueDates = _underscore['default'].uniq(dates, function (item) {
        return item.date;
      });
      var targetDate = _underscore['default'].find(uniqueDates, function (item) {
        return item.percentage >= scheduleConfidence * 100;
      }) || {};

      var finalDates = _underscore['default'].map(uniqueDates, function (item) {
        return {
          date: item.date,
          percentage: item.percentage,
          target: item.date === targetDate.date
        };
      });

      this.setProperties({
        targetCompletionDate: targetDate.date,
        schedule: finalDates
      });
    },

    calculateEstimationFactor: function calculateEstimationFactor() {
      var confidence = this.get('estimationConfidence');
      var dataPoints = (0, _tedianRiskManagementFunctions.removeOutliers)(this.get('estimationDataPoints'));
      var factor = round(_npmD3Array['default'].quantile(dataPoints, confidence), 3);

      this.set('estimationFactor', factor);
    },

    calculateItemCosts: function calculateItemCosts() {
      var amount = this.get('items').filterBy('isWithinScope', true).reduce(function (total, item) {
        return total + item.get('amount');
      }, 0);
      this.set('itemAmount', amount);
    },

    calculateItemHours: function calculateItemHours() {
      var hours = this.get('items').filterBy('isWithinScope', true).reduce(function (total, item) {
        return total + item.get('estimatedHours');
      }, 0);
      this.set('estimatedItemHours', hours);
    },

    calculateAmountForAllItems: function calculateAmountForAllItems() {
      var _this = this;

      this.get('items').forEach(function (item) {
        item.calculateAmount(_this.get('hourlyCosts'), _this.get('estimationFactor'));
      });
    },

    setProjectManagementConfidence: function setProjectManagementConfidence(percentage) {
      var confidence = percentage / 100;
      var dataPoints = (0, _tedianRiskManagementFunctions.removeOutliers)(this.get('projectManagementDataPoints'));
      var factor = Math.round(_npmD3Array['default'].quantile(dataPoints, confidence) * 1000) / 1000;

      this.setProperties({
        projectManagementFactor: factor,
        projectManagementConfidence: confidence
      });

      this.calculateProjectManagementHours();
      this.calculateProjectManagementCosts();
      this.calculateTotalHours();
      this.calculateTotalCosts();
      this.calculateSchedule();
    },

    setEstimationData: function setEstimationData(label, points) {
      this.setProperties({
        estimationDataPoints: points,
        estimationDataLabel: label
      });

      this.calculateEstimationFactor();
      this.calculateProjectManagementCosts();
      this.calculateAmountForAllItems();
      this.calculateItemCosts();
      this.calculateTotalCosts();
      this.calculateSchedule();
    },

    setEstimationConfidence: function setEstimationConfidence(percentage) {
      var confidence = percentage / 100;

      this.setProperties({
        estimationConfidence: confidence
      });

      this.calculateEstimationFactor();
      this.calculateProjectManagementCosts();
      this.calculateAmountForAllItems();
      this.calculateItemCosts();
      this.calculateTotalCosts();
    },

    setHourlyCosts: function setHourlyCosts(costs) {
      this.setProperties({
        hourlyCosts: costs
      });

      this.calculateProjectManagementCosts();
      this.calculateAmountForAllItems();
      this.calculateItemCosts();
      this.calculateTotalCosts();
    },

    setScheduleConfidence: function setScheduleConfidence(percentage) {
      var confidence = percentage / 100;

      this.setProperties({
        scheduleConfidence: confidence
      });

      this.calculateSchedule();
    },

    setWorkStartsOn: function setWorkStartsOn(date) {
      this.setProperties({
        workStartsOn: date
      });

      this.calculateSchedule();
    },

    setHoursPerDay: function setHoursPerDay(hours) {
      this.setProperties({
        hoursPerDay: hours
      });

      this.calculateSchedule();
    },

    estimateItem: function estimateItem(item, estimatedHours) {
      item.set('estimatedHours', estimatedHours);
      item.calculateAmount(this.get('hourlyCosts'), this.get('estimationFactor'));

      this.calculateItemHours();
      this.calculateItemCosts();
      this.calculateProjectManagementHours();
      this.calculateProjectManagementCosts();
      this.calculateTotalHours();
      this.calculateTotalCosts();
      this.calculateSchedule();
    },

    removeItemFromScope: function removeItemFromScope(item) {
      item.set('isWithinScope', false);

      this.calculateItemHours();
      this.calculateItemCosts();
      this.calculateProjectManagementHours();
      this.calculateProjectManagementCosts();
      this.calculateTotalHours();
      this.calculateTotalCosts();
      this.calculateSchedule();
    },

    addItemToScope: function addItemToScope(item) {
      item.set('isWithinScope', true);

      this.calculateItemHours();
      this.calculateItemCosts();
      this.calculateProjectManagementHours();
      this.calculateProjectManagementCosts();
      this.calculateTotalHours();
      this.calculateTotalCosts();
      this.calculateSchedule();
    }
  });

  function calculateEndDate(startDate, totalHours, hoursPerDay) {
    var currentDate = (0, _moment['default'])(startDate, 'YYYY-MM-DD');
    var hours = totalHours;

    while (hours > 0) {
      if (currentDate.day() === 0) {
        currentDate.add('days', 1);
        continue;
      }

      if (currentDate.day() === 6) {
        currentDate.add('days', 1);
        continue;
      }

      hours -= hoursPerDay;
      currentDate.add('days', 1);
    }

    return currentDate.format('YYYY-MM-DD');
  }
});