define('tedian/components/invoice-draft-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'form',
    store: _ember['default'].inject.service(),

    filteredInvoiceItemDrafts: _ember['default'].computed.filterBy('model.draft.invoiceItemDrafts', 'isDeleted', false),

    invoiceItemDraftsSorting: ['orderIndex:asc'],
    sortedInvoiceItemDrafts: _ember['default'].computed.sort('filteredInvoiceItemDrafts', 'invoiceItemDraftsSorting'),

    scheduledPayments: _ember['default'].computed.alias('model.draft.paymentSchedule.payments'),

    actions: {
      submit: function submit() {
        this.sendAction();
        return false;
      },

      selectOrganisation: function selectOrganisation(organisation) {
        this.get('model.draft').setProperties({
          organisation: organisation,
          address: organisation.get('billingAddress'),
          salutation: organisation.get('contactPerson.salutation'),
          recipientVatNumber: organisation.get('vatNumber')
        });
      },

      addInvoiceItemDraft: function addInvoiceItemDraft() {
        var items = this.get('model.draft.invoiceItemDrafts');
        var item = this.get('store').createRecord('invoice-item-draft', { orderIndex: items.get('length') });
        items.pushObject(item);
      },

      removeInvoiceItemDraft: function removeInvoiceItemDraft(item) {
        item.destroyRecord();
      },

      addScheduledPayment: function addScheduledPayment() {
        var draft = this.get('model.draft');
        draft.addScheduledPayment();
      },

      removeScheduledPayment: function removeScheduledPayment(payment) {
        var draft = this.get('model.draft');
        draft.removeScheduledPayment(payment);
      }
    }
  });
});