define('tedian/controllers/invoicing/archive/index', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {

  var PaymentScope = _ember['default'].Object.extend({
    startsOn: undefined,
    endsOn: undefined,
    payments: [],

    total: _ember['default'].computed('payments.@each.amount', function () {
      return this.get('payments').reduce(function (previous, current) {
        return previous + current.get('amount');
      }, 0);
    })
  });

  exports['default'] = _ember['default'].Controller.extend({
    queryParams: { selectedYear: 'year' },
    selectedYear: new Date().getFullYear(),

    selectedPaymentScope: _ember['default'].computed('scopedPayments.@each.payments', 'selectedYear', function () {
      var selectedYear = this.get('selectedYear');
      var startOfYear = (0, _moment['default'])([selectedYear]).startOf('year').toDate();
      var endOfYear = (0, _moment['default'])([selectedYear]).endOf('year').toDate();

      return this.get('scopedPayments').find(function (group) {
        return group.get('startsOn').getTime() === startOfYear.getTime() && group.get('endsOn').getTime() === endOfYear.getTime();
      }) || PaymentScope.create();
    }),

    scopedPayments: _ember['default'].computed('model.@each.receivedOn', function () {
      var groupedPayments = [];
      var currentYear = (0, _moment['default'])().year();

      this.get('model').forEach(function (payment) {
        var yearIndex = currentYear - (0, _moment['default'])(payment.get('receivedOn')).year();

        if (groupedPayments[yearIndex] === undefined) {
          groupedPayments[yearIndex] = [payment];
        } else {
          groupedPayments[yearIndex].pushObject(payment);
        }
      });

      return groupedPayments.map(function (payments) {
        var year = (0, _moment['default'])(payments.get('firstObject.receivedOn')).year();

        return PaymentScope.create({
          startsOn: (0, _moment['default'])([year]).startOf('year').toDate(),
          endsOn: (0, _moment['default'])([year]).endOf('year').toDate(),
          payments: payments
        });
      }).compact();
    })
  });
});