define('tedian/components/responsive-table', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['overflowing-content'],
    attributes: {},

    tableAttributes: _ember['default'].computed('attributes', function () {
      var attributes = this.get('attributes');
      var supportedAttributes = ['class'];
      var passedAttributes = Object.keys(attributes);

      passedAttributes.forEach(function (attribute) {
        if (!supportedAttributes.includes(attribute)) {
          throw new Error('Attribute ' + attribute + ' is not supported.');
        }
      });

      return {
        'class': ('table ' + ('class' in attributes ? attributes['class'] : '')).trim()
      };
    })
  });
});