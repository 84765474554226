define('tedian/components/interval-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'form',
    model: null,

    onSubmit: _ember['default'].K,
    onDelete: _ember['default'].K,
    isPersisted: _ember['default'].computed.notEmpty('model.intervalId'),

    actions: {
      submit: function submit() {
        this.get('onSubmit')(this.get('model'));
        return false;
      },

      'delete': function _delete() {
        this.get('onDelete')(this.get('model'));
      },

      updateDateTime: function updateDateTime(name, date) {
        this.set('model.' + name, date);
      },

      addTag: function addTag(tag) {
        this.get('model.selectedTags').pushObject(tag);
      },

      removeTag: function removeTag(tag) {
        this.get('model.selectedTags').removeObject(tag);
      }
    }
  });
});