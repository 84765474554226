define('tedian/components/activity-timer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    metronome: _ember['default'].inject.service('metronome'),

    classNames: ['time-tracking-timer'],
    timer: null,
    availableTags: null,
    manualEntryRoute: null,

    onStart: _ember['default'].K,
    onStop: _ember['default'].K,
    onCommentLostFocus: _ember['default'].K,
    onRemoveTag: _ember['default'].K,
    onAddTag: _ember['default'].K,

    init: function init() {
      this._super.apply(this, arguments);
      this.get('metronome');
    },

    showTimer: _ember['default'].computed('milliseconds', function () {
      return this.get('milliseconds') > 0;
    }),

    milliseconds: _ember['default'].computed('timer', 'metronome.beat', function () {
      var timer = this.get('timer');

      if (_ember['default'].isBlank(timer)) {
        return null;
      }

      return new Date().getTime() - timer.get('startedAt').getTime();
    }),

    actions: {
      start: function start() {
        this.get('onStart')([]);
      },

      stop: function stop() {
        this.get('onStop')(this.get('timer'));
      },

      commentLostFocus: function commentLostFocus() {
        this.get('onCommentLostFocus')(this.get('timer'));
      },

      removeTag: function removeTag(tag) {
        this.get('onRemoveTag')(this.get('timer'), tag);
      },

      addTag: function addTag(tag) {
        this.get('onAddTag')(this.get('timer'), tag);
      }
    }
  });
});