define('tedian/router', ['exports', 'ember', 'tedian/config/environment'], function (exports, _ember, _tedianConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _tedianConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('session.new', { path: 'anmelden' });
    this.route('session.destroy', { path: 'abmelden' });

    this.route('clients', { path: 'kunden' }, function () {
      this.route('new', { path: 'erstellen' });
      this.route('edit', { path: ':id/bearbeiten' });
    });

    this.route('invoicing', { path: 'rechnungen' }, function () {
      this.route('drafts', { path: 'entwuerfe' }, function () {
        this.route('new', { path: 'erstellen' });
        this.route('edit', { path: ':id/bearbeiten' });
      });

      this.route('show', { path: ':id' }, function () {});

      this.route('pending', { path: 'ausstehend' }, function () {});

      this.route('payments', { path: ':id/zahlungen' }, function () {
        this.route('new', { path: 'erfassen' });
      });

      this.route('archive', { path: 'eingegangene-zahlungen' }, function () {});
    });

    this.route('controlling', function () {
      this.route('sales', { path: 'umsaetze' }, function () {});
      this.route('contract-time', { path: 'auftragszeiten' }, function () {});
      this.route('labor-time', { path: 'personalzeiten' }, function () {});
      this.route('contracts', { path: 'auftraege' }, function () {});
    });

    this.route('time-tracking', { path: 'zeiterfassung' }, function () {
      this.route('interval-edit', { path: 'eintrag-bearbeiten/:id' });
      this.route('interval-new', { path: 'zeit-nachtragen' });
      this.route('reports');
      this.route('tags', { path: 'tags' }, function () {
        this.route('new', { path: 'hinzufuegen' });
        this.route('edit', { path: ':id/bearbeiten' });
      });
      this.route('archive', { path: 'archiv' }, function () {
        this.route('date', { path: ':date' });
        this.route('week', { path: 'woche/:week' });
        this.route('month', { path: 'monat/:month' });
      });
    });

    this.route('project-management', { path: 'pm' }, function () {
      this.route('projects', { path: 'projects' }, function () {
        this.route('new-project', function () {
          this.route('members');
        });
      });

      this.route('project', { path: '/project/:project_id' }, function () {
        this.route('ticket', { path: '/ticket/:ticket_id' }, function () {
          this.route('refine');
          this.route('assignments');
        });

        this.route('new-ticket');
      });

      this.route('tickets', function () {
        this.route('ticket', { path: '/:ticket_id' }, function () {
          this.route('refine');
          this.route('assignments');
        });

        this.route('new-ticket');
      });
    });

    this.route('procurement', { path: 'auftragswesen' }, function () {
      this.route('quotes', { path: 'angebote' });
      this.route('quote-form', { path: 'angebote/:id/ausarbeiten' });
      this.route('new-inquiry', { path: 'anfragen/erfassen' });
      this.route('inquiries', { path: 'anfragen' });
      this.route('edit-inquiry', { path: 'anfragen/:id/bearbeiten' });
      this.route('close-inquiry', { path: 'anfragen/:id/schliessen' });
      this.route('new-quote');
      this.route('issue-quote', { path: 'angebote/:id/stellen' });
      this.route('decline-quote', { path: 'angebote/:id/ablehnen' });
      this.route('award-contract', { path: 'auftraege/:id/erteilen' });
      this.route('contracts', { path: 'auftraege' });
      this.route('enter-contract', { path: 'auftraege/:id/annehmen' });
      this.route('cancel-contract', { path: 'auftraege/:id/stornieren' });
      this.route('complete-contract', { path: 'auftraege/:id/abschliessen' });
      this.route('invoice-contract', { path: 'auftraege/:id/verrechnen' });
      this.route('archive', { path: 'archiv' });
    });

    this.route('quote', { path: 'angebot/:id' });

    this.route('legal', { path: 'legal' }, function () {
      this.route('imprint', { path: 'impressum' });
      this.route('privacy-policy', { path: 'datenschutz' });
    });

    this.route('human-resources', { path: 'personalwesen' }, function () {
      this.route('work-schedules', { path: 'arbeitszeitplaene' }, function () {});
      this.route('absences', { path: 'abwesenheiten' }, function () {
        this.route('new', { path: 'eintragen' });
        this.route('edit', { path: ':id/bearbeiten' });
      });
    });
  });

  exports['default'] = Router;
});