define('tedian/routes/time-tracking/interval-new', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service('session'),

    model: function model(parameters) {
      return new _ember['default'].RSVP.hash({
        tags: this.store.query('time-tracking-tag', { filter: { 'is-locked': false } }),
        selectedTagIds: (parameters.tags || '').split(',')
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var selectedTags = [];
      var currentUserTagSlug = 'user:' + this.get('session.currentUser.id');
      selectedTags.push(model.tags.find(function (tag) {
        return currentUserTagSlug === tag.get('slug');
      }));
      selectedTags.push.apply(selectedTags, _toConsumableArray(model.tags.filter(function (tag) {
        return model.selectedTagIds.includes(tag.get('id'));
      })));

      controller.set('formModel', _ember['default'].Object.create({
        intervalId: undefined,
        comment: '',
        selectedTags: selectedTags,
        startedAt: (0, _moment['default'])(new Date()).startOf('day').toDate(),
        stoppedAt: (0, _moment['default'])(new Date()).startOf('day').toDate()
      }));
    }
  });
});