define('tedian/components/charts/cumulative-distribution-function', ['exports', 'ember', 'npm:d3-array', 'underscore', 'tedian/risk-management/functions'], function (exports, _ember, _npmD3Array, _underscore, _tedianRiskManagementFunctions) {
  exports['default'] = _ember['default'].Component.extend({
    dataSet: undefined,

    chartOptions: _ember['default'].computed('dataSet.[]', function () {
      return {
        chart: {
          type: 'column',
          spacing: [20, 0, 20, 0]
        },
        legend: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          title: {
            text: 'Faktor'
          }
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: 'Wahrscheinlichkeit'
          },
          labels: {
            formatter: function formatter() {
              return this.value + '%';
            }
          }
        }
      };
    }),

    chartData: _ember['default'].computed('dataSet.[]', function () {
      var data = (0, _tedianRiskManagementFunctions.removeOutliers)(this.get('dataSet') || []);
      var dataHistogram = (0, _tedianRiskManagementFunctions.histogram)(data);
      var cumulativeProbability = 0;

      var cumulativeDataHistogram = _underscore['default'].map(dataHistogram.bins, function (bin) {
        return [bin.value, cumulativeProbability += bin.count * dataHistogram.onePercent];
      });

      var approximatedDataPoints = [];

      for (var y = 0; y <= 1; y += 0.01) {
        approximatedDataPoints.push({
          x: _npmD3Array['default'].quantile(data, y),
          y: y * 100
        });
      }

      return [{
        data: _underscore['default'].map(cumulativeDataHistogram, function (bin) {
          return { x: bin[0], y: bin[1] };
        }),
        type: 'column'
      }, {
        data: approximatedDataPoints,
        type: 'line'
      }];
    })
  });
});