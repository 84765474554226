define('tedian/components/attendance-timer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    metronome: _ember['default'].inject.service('metronome'),

    classNames: ['time-tracking-timer'],
    timer: null,
    manualEntryRoute: null,
    availableTags: null,
    selectedTag: null,
    defaultTagSlug: null,

    onStart: _ember['default'].K,
    onStop: _ember['default'].K,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('metronome');
      this.set('selectedTag', this.get('availableTags').find(function (tag) {
        return tag.get('slug') === _this.get('defaultTagSlug');
      }));
    },

    showTimer: _ember['default'].computed('milliseconds', function () {
      return this.get('milliseconds') > 0;
    }),

    milliseconds: _ember['default'].computed('timer', 'metronome.beat', function () {
      var timer = this.get('timer');

      if (_ember['default'].isBlank(timer)) {
        return null;
      }

      return new Date().getTime() - timer.get('startedAt').getTime();
    }),

    attendanceTag: _ember['default'].computed('timer.timeTrackingTags.[]', function () {
      return this.get('timer.timeTrackingTags').find(function (tag) {
        return tag.isOfType('attendance');
      });
    }),

    actions: {
      start: function start() {
        this.get('onStart')([this.get('selectedTag')]);
      },

      stop: function stop() {
        this.get('onStop')(this.get('timer'));
      }
    }
  });
});