define('tedian/controllers/controlling/contract-time/index', ['exports', 'ember', 'tedian/value-objects/date-scope'], function (exports, _ember, _tedianValueObjectsDateScope) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: { year: 'year' },

    year: new Date().getFullYear(),

    scopes: _ember['default'].computed(function () {
      var scopes = [];
      var maximumYear = new Date().getFullYear();
      var minimumYear = 2013;
      var year = minimumYear;

      while (year <= maximumYear) {
        scopes.push(_tedianValueObjectsDateScope['default'].fromDate(new Date(year, 0, 1), 'year'));
        year++;
      }

      return scopes.reverse();
    }),

    timeGoal: _ember['default'].computed('year', function () {
      var year = this.get('year');
      var goals = {
        2018: 620 * 2,
        2019: 620 * 2,
        2020: 620 * 2,
        2021: 620 * 2
      };

      return goals[year];
    })
  });
});