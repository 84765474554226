define('tedian/routes/time-tracking/tags/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return new _ember['default'].RSVP.hash({
        tag: this.store.findRecord('time-tracking-tag', parameters.id)
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('formModel', _ember['default'].Object.create({
        tagId: model.tag.get('id'),
        name: model.tag.get('name'),
        slug: model.tag.get('slug')
      }));
    }
  });
});