define('tedian/services/time-tracking', ['exports', 'ember', 'tedian/commands/time-tracking/stop-timer-command', 'tedian/commands/time-tracking/start-timer-command', 'tedian/commands/time-tracking/comment-timer-command', 'tedian/commands/time-tracking/uncomment-timer-command', 'tedian/commands/time-tracking/tag-timer-command', 'tedian/commands/time-tracking/untag-timer-command', 'ember-uuid', 'tedian/helpers/date-to-iso8601'], function (exports, _ember, _tedianCommandsTimeTrackingStopTimerCommand, _tedianCommandsTimeTrackingStartTimerCommand, _tedianCommandsTimeTrackingCommentTimerCommand, _tedianCommandsTimeTrackingUncommentTimerCommand, _tedianCommandsTimeTrackingTagTimerCommand, _tedianCommandsTimeTrackingUntagTimerCommand, _emberUuid, _tedianHelpersDateToIso8601) {
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service('session'),
    store: _ember['default'].inject.service('store'),
    commandStore: _ember['default'].inject.service('command-store'),
    timers: null,
    currentUserTag: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.loadCurrentUserTag();
      this.loadActiveTimers();
    },

    loadCurrentUserTag: function loadCurrentUserTag() {
      var _this = this;

      var currentUserTagSlug = 'user:' + this.get('session.currentUser.id');

      this.get('store').query('time-tracking-tag', { filter: { 'is-locked': false } }).then(function (tags) {
        _this.set('currentUserTag', tags.find(function (tag) {
          return tag.get('slug') === currentUserTagSlug;
        }));
      });
    },

    loadActiveTimers: function loadActiveTimers() {
      var _this2 = this;

      var currentUserTagSlug = 'user:' + this.get('session.currentUser.id');

      this.get('store').query('time-tracking-active-timer', {
        filter: { user_tag: currentUserTagSlug },
        include: 'time-tracking-tags'
      }).then(function (timers) {
        _this2.set('timers', timers);
      });
    },

    startTimer: function startTimer(tags) {
      var _this3 = this;

      var store = this.get('commandStore');

      var command = new _tedianCommandsTimeTrackingStartTimerCommand['default']({
        timerId: (0, _emberUuid.v4)(),
        startedAt: (0, _tedianHelpersDateToIso8601['default'])(new Date()),
        comment: '',
        tagIds: tags.mapBy('id')
      });

      store.submit(command).then(function () {
        _this3.loadActiveTimers();
      });
    },

    stopTimer: function stopTimer(timer) {
      var _this4 = this;

      var store = this.get('commandStore');

      var command = new _tedianCommandsTimeTrackingStopTimerCommand['default']({
        timerId: timer.get('id'),
        stoppedAt: (0, _tedianHelpersDateToIso8601['default'])(new Date())
      });

      store.submit(command).then(function () {
        _this4.loadActiveTimers();
      });
    },

    addTag: function addTag(timer, tag) {
      var store = this.get('commandStore');
      var command = new _tedianCommandsTimeTrackingTagTimerCommand['default']({
        timerId: timer.get('id'),
        tagId: tag.get('id')
      });

      return store.submit(command).then(function () {
        return timer.reload();
      });
    },

    removeTag: function removeTag(timer, tag) {
      var store = this.get('commandStore');
      var command = new _tedianCommandsTimeTrackingUntagTimerCommand['default']({
        timerId: timer.get('id'),
        tagId: tag.get('id')
      });

      return store.submit(command).then(function () {
        return timer.reload();
      });
    },

    replaceTags: function replaceTags(timer, newTags) {
      var _this5 = this;

      var existingTags = timer.get('timeTrackingTags');
      var removableTags = existingTags.filter(function (tag) {
        return !newTags.contains(tag);
      });
      var addableTags = newTags.filter(function (tag) {
        return !existingTags.contains(tag);
      });

      addableTags.reduce(function (currentPromise, nextTag) {
        return currentPromise.then(function () {
          return _this5.addTag(timer, nextTag);
        });
      }, _ember['default'].RSVP.resolve()).then(function () {
        removableTags.reduce(function (currentPromise, nextTag) {
          return currentPromise.then(function () {
            return _this5.removeTag(timer, nextTag);
          });
        }, _ember['default'].RSVP.resolve());
      });
    },

    updateComment: function updateComment(timer) {
      var store = this.get('commandStore');
      var comment = timer.get('comment');
      var command = undefined;

      if (_ember['default'].isEmpty(comment)) {
        command = new _tedianCommandsTimeTrackingUncommentTimerCommand['default']({
          timerId: timer.get('id')
        });
      } else {
        command = new _tedianCommandsTimeTrackingCommentTimerCommand['default']({
          timerId: timer.get('id'),
          comment: comment
        });
      }

      store.submit(command);
    },

    currentUserChanged: _ember['default'].observer('session.currentUser', function () {
      this.loadCurrentUserTag();
      this.loadActiveTimers();
    })
  });
});