define('tedian/models/time-tracking-tag', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  exports.getLabelForTagType = getLabelForTagType;

  var TYPE_LABEL_MAP = {
    'attendance': 'Anwesenheit',
    'client': 'Auftraggeber',
    'contract': 'Auftrag',
    'contract-handling': 'Auftragsabwicklung',
    'contract-item': 'Auftragsposten',
    'internal': 'Intern',
    'user': 'Person'
  };

  function getLabelForTagType(type) {
    if (TYPE_LABEL_MAP.hasOwnProperty(type)) {
      return TYPE_LABEL_MAP[type];
    } else {
      return null;
    }
  }

  exports['default'] = _emberDataModel['default'].extend({
    name: (0, _emberDataAttr['default'])(),
    slug: (0, _emberDataAttr['default'])(),
    isLocked: (0, _emberDataAttr['default'])(),

    type: _ember['default'].computed('slug', function () {
      var slug = this.get('slug');

      if (slug && slug.indexOf(':') > 0) {
        return slug.split(':')[0];
      } else {
        return null;
      }
    }),

    typeLabel: _ember['default'].computed('type', function () {
      return getLabelForTagType(this.get('type'));
    }),

    isOfType: function isOfType(typeOrArrayOfTypes) {
      if (typeOrArrayOfTypes instanceof Array) {
        return typeOrArrayOfTypes.includes(this.get('type'));
      } else {
        return this.get('type') === typeOrArrayOfTypes;
      }
    }
  });
});