define('tedian/routes/procurement/decline-quote', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(parameters) {
      return new _ember['default'].RSVP.hash({
        quote: this.store.findRecord('procurement-quote', parameters.id)
      });
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('declinedOn', (0, _moment['default'])().format('YYYY-MM-DD'));
      controller.set('reason', '');
    }
  });
});