define('tedian/components/ticket-card', ['exports', 'ember', 'tedian/commands/project-management/plan-ticket-command', 'tedian/commands/project-management/start-ticket-command', 'tedian/commands/project-management/finish-ticket-command'], function (exports, _ember, _tedianCommandsProjectManagementPlanTicketCommand, _tedianCommandsProjectManagementStartTicketCommand, _tedianCommandsProjectManagementFinishTicketCommand) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':ticket-card', 'typeClass'],

    session: _ember['default'].inject.service('session'),
    commandStore: _ember['default'].inject.service('command-store'),
    routing: _ember['default'].inject.service('-routing'),

    typeClass: _ember['default'].computed('ticket.category', function () {
      return 'ticket-card--' + this.get('ticket.category');
    }),

    ticketRoute: _ember['default'].computed('isDisplayedOnTicketOverview', function () {
      if (this.get('isDisplayedOnTicketOverview')) {
        return 'project-management.tickets.ticket';
      } else {
        return 'project-management.project.ticket';
      }
    }),

    isDisplayedOnTicketOverview: _ember['default'].computed('routing.router.currentRouteName', function () {
      return (/project-management\.tickets.*/.test(this.get('routing.router.currentRouteName'))
      );
    }),

    workflowButtonLabel: _ember['default'].computed('ticket.state', function () {
      switch (this.get('ticket.state')) {
        case 'unplanned':
          return 'Planen';
        case 'planned':
          return 'Starten';
        case 'started':
          return 'Abschließen';
      }
    }),

    showWorkflowButton: _ember['default'].computed('ticket.state', 'ticket.assignees.[]', function () {
      var state = this.get('ticket.state');
      var inBetween = ['planned', 'started'].contains(state);
      var myAccountId = parseInt(this.get('session').get('currentUser').get('id'), 10);
      var accountIds = this.get('ticket.assignees').mapBy('accountId');

      return state === 'unplanned' || inBetween && accountIds.contains(myAccountId);
    }),

    actions: {
      moveForward: function moveForward() {
        var store = this.get('commandStore');
        var command = undefined;

        switch (this.get('ticket.state')) {
          case 'unplanned':
            command = new _tedianCommandsProjectManagementPlanTicketCommand['default']({ ticketId: this.get('ticket.id'), expectedAggregateVersion: this.get('ticket.aggregateVersion') });
            break;
          case 'planned':
            command = new _tedianCommandsProjectManagementStartTicketCommand['default']({ ticketId: this.get('ticket.id'), expectedAggregateVersion: this.get('ticket.aggregateVersion') });
            break;
          case 'started':
            command = new _tedianCommandsProjectManagementFinishTicketCommand['default']({ ticketId: this.get('ticket.id'), expectedAggregateVersion: this.get('ticket.aggregateVersion') });
            break;
        }

        store.submit(command);
      }
    }
  });
});