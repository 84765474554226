define('tedian/models/invoice-item', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    invoice: _emberData['default'].belongsTo('invoice'),
    description: _emberData['default'].attr('string'),
    units: _emberData['default'].attr('number'),
    pricePerUnit: _emberData['default'].attr('number'),
    orderIndex: _emberData['default'].attr('number'),

    sum: _ember['default'].computed('units', 'pricePerUnit', function () {
      var decimals = 2;
      var factor = Math.pow(10, decimals);

      return Math.round((this.get('units') || 0) * (this.get('pricePerUnit') || 0) * factor) / factor;
    })
  });
});