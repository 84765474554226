define('tedian/controllers/procurement/issue-quote', ['exports', 'ember', 'tedian/commands/procurement/issue-quote-command'], function (exports, _ember, _tedianCommandsProcurementIssueQuoteCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    actions: {
      submit: function submit() {
        var _this = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementIssueQuoteCommand['default']({
          quoteId: this.get('model.quote.id'),
          issuedOn: this.get('issuedOn'),
          validUntil: this.get('validUntil')
        });

        store.submit(command).then(function () {
          _this.transitionToRoute('procurement.quotes');
        });
      }
    }
  });
});