define('tedian/components/stop-watch', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    timeTracking: _ember['default'].inject.service('time-tracking'),

    tags: null,
    canStart: _ember['default'].computed.empty('timer'),
    canAdopt: _ember['default'].computed.notEmpty('timer'),
    canAdd: _ember['default'].computed.notEmpty('onAdd'),

    timer: _ember['default'].computed('timeTracking.timers', 'tags', function () {
      var timers = this.get('timeTracking.timers');
      var containsAttendanceTag = this.get('tags').find(function (tag) {
        return tag.isOfType('attendance');
      });

      if (_ember['default'].isBlank(timers)) {
        return null;
      }

      return timers.find(function (timer) {
        if (containsAttendanceTag) {
          return timer.get('timeTrackingTags').any(function (tag) {
            return tag.isOfType('attendance');
          });
        } else {
          return timer.get('timeTrackingTags').every(function (tag) {
            return !tag.isOfType('attendance');
          });
        }
      });
    }),

    actions: {
      start: function start() {
        var tags = [this.get('timeTracking.currentUserTag')].concat((this.get('tags') || []).toArray());
        this.get('timeTracking').startTimer(tags);
      },

      adopt: function adopt() {
        var timeTracking = this.get('timeTracking');
        var timer = this.get('timer');
        var tags = this.get('tags');

        tags.reduce(function (currentPromise, nextTag) {
          return currentPromise.then(function () {
            return timeTracking.addTag(timer, nextTag);
          });
        }, _ember['default'].RSVP.resolve());
      },

      add: function add() {
        this.get('onAdd')(this.get('tags').mapBy('id'));
      }
    }
  });
});