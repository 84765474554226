define('tedian/adapters/user', ['exports', 'tedian/adapters/application'], function (exports, _tedianAdaptersApplication) {
  exports['default'] = _tedianAdaptersApplication['default'].extend({
    me: function me() {
      return this.ajax(this.urlForMeAction(), 'GET', { data: {} });
    },

    urlForMeAction: function urlForMeAction() {
      return this.buildURL('user') + '/me';
    }
  });
});