define('tedian/routes/project-management/tickets', ['exports', 'ember', 'tedian/routes/project-management/project'], function (exports, _ember, _tedianRoutesProjectManagementProject) {
  exports['default'] = _tedianRoutesProjectManagementProject['default'].extend({
    templateName: 'project-management/project',
    parentRoute: 'project-management.tickets',
    newTicketRoute: 'project-management.tickets.new-ticket',

    model: function model() {
      return new _ember['default'].RSVP.hash({
        // Load all projects so projects are not fetched one after the other.
        // `include: 'assignees,project'` does not work because {jr} breaks on
        // the non 1:1 mapping between resource name and model. It uses `Project`
        // instead of `ReadProject`.
        _: this.store.findAll('project-management-project', { include: 'members' }),
        tickets: this.store.query('project-management-ticket', { include: 'assignees' })
      });
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    },

    ticketColumnHeadline: function ticketColumnHeadline() {
      return 'Ticket Übersicht';
    }
  });
});