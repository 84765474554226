define('tedian/controllers/procurement/new-inquiry', ['exports', 'ember', 'tedian/commands/procurement/receive-inquiry-command', 'ember-uuid'], function (exports, _ember, _tedianCommandsProcurementReceiveInquiryCommand, _emberUuid) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    actions: {
      selectOrganisation: function selectOrganisation(organisation) {
        this.set('organisation', organisation);
      },

      submit: function submit() {
        var _this = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementReceiveInquiryCommand['default']({
          inquiryId: (0, _emberUuid.v4)(),
          title: this.get('title'),
          internalNotes: this.get('notes'),
          receivedOn: this.get('receivedOn'),
          organisationId: this.get('organisation.id')
        });

        store.submit(command).then(function () {
          _this.transitionToRoute('procurement.inquiries');
        });
      }
    }
  });
});