define('tedian/helpers/format-number', ['exports', 'ember'], function (exports, _ember) {
  exports.formatNumber = formatNumber;

  // Inspired by https://blog.tompawlak.org/number-currency-formatting-javascript

  function formatNumber(params, hash) {
    var delimiters = {
      thousands: '.',
      decimal: ','
    };

    var value = params[0];
    var formattedValue = '';
    var prefix = '';
    var suffix = '';

    if (hash['type'] === 'percent') {
      suffix = '%';
      value = value * 100;
    }

    if ('precision' in hash) {
      value = Math.round(value * Math.pow(10, hash['precision'])) / Math.pow(10, hash['precision']);
    }

    if (hash['signed']) {
      prefix = value > 0 ? '+' : value < 0 ? '-' : '±';
      value = Math.abs(value);
    }

    if ('precision' in hash) {
      formattedValue = value.toFixed(hash['precision']);
    } else {
      formattedValue = value.toString();
    }

    if (formattedValue.indexOf('.') !== -1) {
      var fractions = formattedValue.split('.');

      formattedValue = [fractions[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + delimiters.thousands), fractions[1]].join(delimiters.decimal);
    } else {
      formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + delimiters.thousands);
    }

    return '' + prefix + formattedValue + suffix;
  }

  exports['default'] = _ember['default'].Helper.helper(formatNumber);
});