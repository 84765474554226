define('tedian/controllers/procurement/cancel-contract', ['exports', 'ember', 'tedian/commands/procurement/cancel-contract-command'], function (exports, _ember, _tedianCommandsProcurementCancelContractCommand) {
  exports['default'] = _ember['default'].Controller.extend({
    commandStore: _ember['default'].inject.service('command-store'),

    actions: {
      submit: function submit() {
        var _this = this;

        var store = this.get('commandStore');
        var command = new _tedianCommandsProcurementCancelContractCommand['default']({
          contractId: this.get('model.contract.id'),
          canceledOn: this.get('contractCanceledOn'),
          reason: this.get('reason'),
          itemIds: this.get('items').filterBy('isCanceled', true).mapBy('id')
        });

        store.submit(command).then(function () {
          _this.transitionToRoute('procurement.contracts');
        });
      }
    }
  });
});