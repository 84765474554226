define('tedian/models/project-management-member', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  exports['default'] = _emberDataModel['default'].extend({
    name: (0, _emberDataAttr['default'])(),
    accountId: (0, _emberDataAttr['default'])(),

    initials: _ember['default'].computed('name', function () {
      var parts = this.get('name').split(' ');

      return parts.map(function (part) {
        return part[0];
      }).join('').toUpperCase();
    })
  });
});