define('tedian/components/ticket-assignee-bubble', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),

    tagName: 'span',
    attributeBindings: ['fullname:title'],
    classNameBindings: [':ticket-assignee-bubble', 'isMyself:ticket-assignee-bubble--highlighted'],

    assignee: undefined,

    initials: _ember['default'].computed('assignee', function () {
      return this.get('assignee.initials');
    }),

    fullname: _ember['default'].computed('assignee', function () {
      return this.get('assignee.name');
    }),

    isMyself: _ember['default'].computed('assignee', function () {
      return this.get('assignee.accountId') === parseInt(this.get('session').get('currentUser').get('id'), 10);
    })
  });
});